import { useEffect, useRef, useState } from 'react';
import {useLocation, useNavigate} from 'react-router-dom';
import toast from 'react-hot-toast';
import Cookies from 'js-cookie';
import {
    useGetApprovedProductMutation,
    useGetOrderDetailByIdMutation,
    useGetReportsMutation,
    usePostBulkSearchByIdMutation,
    usePostOrderCreationMutation,
} from '../../store/services/api';
import WarningIcon from '../../components/Icon/WarningIcon';
import IconTrash from '../../components/Icon/IconTrash';
import IconStar from '../../components/Icon/IconStart';
import IconStarYellow from '../../components/Icon/IconStarYellow';
import IconSpandrel from '../../components/Icon/IconSpandrel';
import IconGoBack from '../../components/Icon/IconGoBack';
import { SEARCH_SAMPLE_PATH } from '../../routes';
import SpandrelModal from "../Report/SpandrelModal";
import EditFamilyModal from "../Report/EditFamilyModal";
import { useQuery } from '../../hooks/useQuery';
import EditProductDescriptionModal from '../Report/EditProductDescriptionModal';
import ApprovedIcon from '../../components/Icon/ApprovedIcon';
import ShipmentDetailForm from './ShipmentDetailForm';
import OrderIcon from '../../components/Icon/OrderIcon';
import { QUANTITY_CONSTANTS, SAMPLE_SIZE_CONSTANTS } from './FormConstant';

const SampleReport = () => {
    const query = useQuery();
    const extraResult = query.get('extra_result');
    const extraCustomer = query.get('extra_customer');
    const extraProject = query.get('extra_project');
    const orderId = query.get('orderId');
    const navigate = useNavigate();
    const dataFetch = useRef(false);
    const location = useLocation();
    const requestData = location.state?.data;
    const [customerName, setCustomerName] = useState('');
    const [projectName, setProjectName] = useState('');
    const [tableData, setTableData] = useState([]);
    const [pageLoading, setPageLoading] = useState(true);
    const [isError, setIsError] = useState(false);
    const [orderFormData, setOrderFormData] = useState({})

    const [requestFavTrigger] = usePostBulkSearchByIdMutation();
    const [trigger, result] = useGetReportsMutation();
    const [triggerProductId, resultProductId] = useGetApprovedProductMutation();
    const [triggerOrderDetail, resultOrderDetail] = useGetOrderDetailByIdMutation();
    const [triggerCreate] = usePostOrderCreationMutation();

    const [isSpandrelModalOpen, setSpandrelModalOpen] = useState(false);
    const [spandrelIndex, setSpandrelIndex] = useState(-1);
    const [isEditFamilyTypeModalOpen, setEditFamilyTypeModalOpen] = useState(false);
    const [isEditProductDescriptionModal, setEditProductDescriptionModalOpen] = useState(false);
    const [editIndex, setEditIndex] = useState(-1);
    const [editDefaultValue, setEditDefaultValue] = useState('');
    const [isMetric, setIsMetric] = useState(false);
    const [isCreateOrder, setIsCreateOrder] = useState(false);

    async function getFavProduct(payload) {
        const favResult = await requestFavTrigger(payload);
        return favResult;
    }

    useEffect(() => {
        const fetchFavProductData = async () => {
            if (result.data) {
                const savedCookieValue = Cookies.get('SavedResult');

                if (savedCookieValue === undefined) {
                    setTableData(result.data.existData);
                } else {
                    const savedTableData = JSON.parse(savedCookieValue);
                    try {
                        const favResult = await getFavProduct(savedTableData);
                        setTableData(result.data.existData.concat(favResult.data));
                    } catch (error) {
                        console.error('Error fetching favorite products:', error);
                    }
                }

                setPageLoading(false);
            }
        };

        fetchFavProductData();
    }, [result.isSuccess]);

    useEffect(() => {
        if (resultProductId.data) {
            setTableData([resultProductId.data]);
            setPageLoading(false);
        }
    }, [resultProductId.isSuccess]);


    useEffect(() => {
        if(result.status === 'rejected') {
            setTableData(result.error.data.message);
            setIsError(true);
            setPageLoading(false);
        }
    }, [result.isError]);

    useEffect(() => {
        if (!dataFetch.current) {
            if(extraResult !== null) {
                triggerProductId(extraResult)
            } else {
                trigger({requestData});
            }

            if(extraCustomer !== 'N/A') {
                setCustomerName(extraCustomer);
            }

            if(extraProject !== 'N/A') {
                setProjectName(extraProject);
            }
        }

        if(requestData !== undefined) {
            const dataObject = JSON.parse(requestData);
            setCustomerName(dataObject.customerName);
            setProjectName(dataObject.projectName);
        }
        dataFetch.current = true
    }, [])

    useEffect(() => {
        if(orderId !== 'null') {
            triggerOrderDetail(orderId);
        }
    }, [orderId])

    useEffect(() => {
        if(resultOrderDetail.data) {
           setOrderFormData(resultOrderDetail.data);
        }
    }, [resultOrderDetail.isSuccess])

    function deleteTableItem(index) {
        // eslint-disable-next-line no-case-declarations
        const tmpTableItem = [...tableData];

        tmpTableItem.splice(index, 1);
        setTableData(tmpTableItem);
        toast.success('Glazing Configuration Removed!');
    }

    const addCookie = (newData) => {
        const savedCookieValue = Cookies.get('SavedResult');
        const tmpDataTable = savedCookieValue === undefined ? [] : JSON.parse(savedCookieValue);
        tmpDataTable.push(newData.product_id);
        const jsonToSave = JSON.stringify(tmpDataTable);
        const expiration = new Date();
        expiration.setTime(expiration.getTime() + (30 * 60 * 1000));
        Cookies.set('SavedResult', jsonToSave, { expires: expiration });
    }

    const removeCookie = (productIdToRemove) => {
        const savedCookieTable = JSON.parse(Cookies.get('SavedResult'));
        const newCookieTable = savedCookieTable.filter(product => product !== productIdToRemove);
        const jsonToSave = JSON.stringify(newCookieTable);
        const expiration = new Date();
        expiration.setTime(expiration.getTime() + (30 * 60 * 1000));
        Cookies.set('SavedResult', jsonToSave, { expires: expiration });
    }

    const cleanCookie = () => {
        Cookies.remove('SavedResult');
        window.location.reload(false);
    }

    const saveResult = async (index) => {
        const tmpTableData = [...tableData];
        tmpTableData[index] = { ...tmpTableData[index], save: !tmpTableData[index].save };
        setTableData(tmpTableData);
        if( tableData[index].save ) {
            removeCookie(tmpTableData[index].product_id);
        } else {
            addCookie(tmpTableData[index]);
        }
    }

    const handleSelectionCheckbox = (index) => {
        const tmpTableData = [...tableData];
        tmpTableData[index] = { ...tmpTableData[index], isChecked: !tmpTableData[index].isChecked };
        setTableData(tmpTableData);
    }

    const createSpandrel = (index, color) => {
        const newTableData = [...tableData];

        const newSpandrelElement = {
            family: "N/A",
            product: `${tableData[index].product} ; Spandrel - ${color}`,
            description_visible: 'N/A',
            description_solar: 'N/A',
            description_uv: 'N/A',
            reflectance_out: 'N/A',
            reflectance_in: 'N/A',
            reflectance_solar: 'N/A',
            u_value_solar: tableData[index].u_value_solar,
            u_value_solar_metric: tableData[index].u_value_solar_metric,
            u_value_summer: tableData[index].u_value_summer,
            u_value_summer_metric: tableData[index].u_value_summer_metric,
            shading_coefficient: 'N/A',
            solar_heat_gain_coefficient: 'N/A',
            relative_heat_gain: 'N/A',
            relative_heat_gain_metric: 'N/A',
            light_solar_gain_ration: 'N/A',
            exist: true,
            save: false
        }

        newTableData.splice(index+1, 0, newSpandrelElement);
        setTableData(newTableData);
    }

    const editFamilyName = (index, name) => {
        const newElement = {
            family: name,
            product: tableData[index].product,
            description_visible: tableData[index].description_visible,
            description_solar: tableData[index].description_solar,
            description_uv: tableData[index].description_uv,
            reflectance_out: tableData[index].reflectance_out,
            reflectance_in: tableData[index].reflectance_in,
            reflectance_solar: tableData[index].reflectance_solar,
            u_value_solar: tableData[index].u_value_solar,
            u_value_solar_metric: tableData[index].u_value_solar_metric,
            u_value_summer: tableData[index].u_value_summer,
            u_value_summer_metric: tableData[index].u_value_summer_metric,
            shading_coefficient: tableData[index].shading_coefficient,
            solar_heat_gain_coefficient: tableData[index].solar_heat_gain_coefficient,
            relative_heat_gain: tableData[index].relative_heat_gain,
            relative_heat_gain_metric: tableData[index].relative_heat_gain_metric,
            light_solar_gain_ration: tableData[index].light_solar_gain_ration,
            exist: tableData[index].exist,
            save: tableData[index].save
        }

        setTableData(prevObjects => {
            const newObjects = [...prevObjects]; // Create a copy of the array
            newObjects[index] = { ...newObjects[index], ...newElement }; // Update the object at the given index
            return newObjects;
        });
    }

    const editProductDescription = (index, name) => {
        const newElement = {
            family: tableData[index].family,
            product: name,
            description_visible: tableData[index].description_visible,
            description_solar: tableData[index].description_solar,
            description_uv: tableData[index].description_uv,
            reflectance_out: tableData[index].reflectance_out,
            reflectance_in: tableData[index].reflectance_in,
            reflectance_solar: tableData[index].reflectance_solar,
            u_value_solar: tableData[index].u_value_solar,
            u_value_solar_metric: tableData[index].u_value_solar_metric,
            u_value_summer: tableData[index].u_value_summer,
            u_value_summer_metric: tableData[index].u_value_summer_metric,
            shading_coefficient: tableData[index].shading_coefficient,
            solar_heat_gain_coefficient: tableData[index].solar_heat_gain_coefficient,
            relative_heat_gain: tableData[index].relative_heat_gain,
            relative_heat_gain_metric: tableData[index].relative_heat_gain_metric,
            light_solar_gain_ration: tableData[index].light_solar_gain_ration,
            exist: tableData[index].exist,
            save: tableData[index].save
        }

        setTableData(prevObjects => {
            const newObjects = [...prevObjects]; // Create a copy of the array
            newObjects[index] = { ...newObjects[index], ...newElement }; // Update the object at the given index
            return newObjects;
        });
    }

    const spandrelModalSubmitFunction = (index, color) => {
        createSpandrel(index, color);
    }

    const spandrelModalOpenHandler = (index) => {
        setSpandrelModalOpen(true);
        setSpandrelIndex(index);
    }

    const editFamilyTypeModalOpenHandler = (index) => {
        setEditDefaultValue(tableData[index].family);
        setEditIndex(index);
        setEditFamilyTypeModalOpen(true);
    }

    const editProductDescriptionModalOpenHandler = (index) => {
        setEditDefaultValue(tableData[index].product);
        setEditIndex(index);
        setEditProductDescriptionModalOpen(true);
    }

    const conversionTrigger = () => {
        setIsMetric(!isMetric);
    };

    const prepareCreateOrder = () => {
        if(tableData.filter(data => data.isChecked === true).length === 0) {
            toast.error("You didn't select any sample!")
        } else {
            setTableData(tableData.filter(data => data.isChecked === true));
            setIsCreateOrder(true);
        }
    }

    const handleSampleSize = (event, index) => {
       /* console.log('EVENT ---> ', event.target.value);
        console.log('INDEX ---> ', index);
        console.log('TABLE DATA ---> ', tableData); */

        setTableData((prevState) =>
            prevState.map((item, i) =>
                i === index ? { ...item, sampleSize: event.target.value } : item
            )
        );
    }

    const handleQuantitySize = (event, index) => {
        /* console.log(event.target.value);
        console.log(tableData); */

        setTableData((prevState) =>
            prevState.map((item, i) =>
                i === index ? { ...item, quantity: event.target.value } : item
            )
        );
    }

    const createOrderFunction = async (samples, formData) => {
        const error = samples.find(sample => sample.sampleSize === 0 || sample.quantity === 0);
        if (error) {
            toast.error('You need to select sample size and quantity for each sample!');
        }

        if(!error) {
            const requestBody = {
                sampleData: samples,
                formData,
            }
            const createResult = await triggerCreate(requestBody);
            createResult.data ? toast.success('Order Created Successfully!') : toast.error('Order Creation Failed!');
        }
    }

    return (
        <section className="h-full w-full bg-white p-4 rounded shadow">
            {pageLoading &&
                <div className="fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-center">
                    <div className="inline-block animate-spin ease-linear rounded-full border-t-4 border-blue-500 border-solid h-12 w-12" />
                    <p className="mt-2 text-blue-500">Please wait until calculation end..</p>
                </div>}
            {!pageLoading && (tableData.length > 0 ? tableData[0].relative_heat_gain === 'NaN' : false) && (tableData.length > 0 ? tableData[0].solar_heat_gain_coefficient === 'NaN' : false) &&
                <div className="flex flex-col w-full h-full justify-center items-center text-3xl font-semibold tracking-wide">
                    <img src="/images/not_found.png" alt="Not Found" className="w-auto h-1/2" />
                    <h2 className="p-24 text-center font-light">Performance data does not exist, and there is no component file available to generate performance values for that configuration. Please contact the administrator for assistance.</h2>
                </div>
            }
            {!pageLoading && (tableData.length > 0 ? tableData[0].relative_heat_gain !== 'NaN' : true) && (tableData.length > 0 ? tableData[0].solar_heat_gain_coefficient !== 'NaN' : true) &&
                <div className="flex flex-col h-full">
                    <div className="flex flex-row justify-between w-full">
                        <div className="flex flex-col w-full h-full justify-start items-start">
                            <button
                                type="submit"
                                className="w-full"
                                onClick={() => navigate(`${SEARCH_SAMPLE_PATH}`)}
                                aria-label="Return to Sample Configurator"
                            >
                                <div className="flex flex-row">
                                    <IconGoBack />
                                    <h2 className="pl-2">Return to Sample Configurator</h2>
                                </div>
                            </button>
                        </div>

                        <div className="flex flex-col w-full h-full">
                            <div className="flex flex-row w-full justify-end items-end">
                                <button type="submit" onClick={() => cleanCookie()} className="ml-4 bg-[#047bc2] text-white pl-4 pr-4 pt-2 pb-2 rounded">
                                    <div className="flex flex-row">
                                        <IconTrash />
                                        <div className="w-2" />
                                        <span>Clear Favourites</span>
                                    </div>
                                </button>
                                <button type="submit" onClick={() => prepareCreateOrder()} className="ml-4 bg-[#047bc2] text-white pl-4 pr-4 pt-2 pb-2 rounded">
                                    <div className="flex flex-row">
                                        <OrderIcon />
                                        <div className="w-2" />
                                        <span>{orderId !== 'null' ? 'Update Order' : 'Create Order' }</span>
                                    </div>
                                </button>
                            </div>
                        </div>
                    </div>

                    {tableData.length > 0 &&
                        <>
                            <div className="flex flex-row w-full text-white text-sm pt-12">
                                <div className="w-[3%] h-20 flex justify-center items-center bg-[#0d3857] border-solid border-r-2 border-white group relative cursor-pointer">
                                <span className="opacity-0 group-hover:opacity-100 transition-opacity duration-200 absolute bottom-full left-1/2 -translate-x-1/2 bg-gray-800 text-white text-xs py-1 px-2 rounded-md">
                              Tooltip for Checkbox
                            </span>
                                </div>
                                <div className="w-[5%] h-20 flex justify-center items-center bg-[#0d3857] border-solid border-r-2 border-white group relative cursor-pointer">
                                    <h2 className="text-center">Tag</h2>
                                    <span className="opacity-0 group-hover:opacity-100 transition-opacity duration-200 absolute bottom-full left-1/2 -translate-x-1/2 bg-gray-800 text-white text-xs py-1 px-2 rounded-md">
                              Tag
                            </span>
                                </div>
                                <div className="w-[37%] flex justify-center items-center bg-[#0d3857] border-solid border-r-2 border-white group relative cursor-pointer">
                                    <h2 className="text-center">Product</h2>
                                    <span className="opacity-0 group-hover:opacity-100 transition-opacity duration-200 absolute bottom-full left-1/2 -translate-x-1/2 bg-gray-800 text-white text-xs py-1 px-2 rounded-md">
                                      Product
                                    </span>
                                </div>
                                <div className="w-[20%] flex justify-center items-center bg-[#0d3857] border-solid border-r-2 border-white group relative cursor-pointer">
                                    <h2 className="text-center">Sample Size</h2>
                                    <span className="opacity-0 group-hover:opacity-100 transition-opacity duration-200 absolute bottom-full left-1/2 -translate-x-1/2 bg-gray-800 text-white text-xs py-1 px-2 rounded-md">
                                      Sample Size
                                    </span>
                                </div>
                                <div className="w-[20%] flex justify-center items-center bg-[#0d3857] border-solid border-r-2 border-white group relative cursor-pointer">
                                    <h2 className="text-center">Quantity</h2>
                                    <span className="opacity-0 group-hover:opacity-100 transition-opacity duration-200 absolute bottom-full left-1/2 -translate-x-1/2 bg-gray-800 text-white text-xs py-1 px-2 rounded-md">
                                      Quantity
                                    </span>
                                </div>
                                <div className="w-[15%] h-20 flex justify-center items-center bg-[#0d3857]">
                                    <h2 className="text-center">Actions</h2>
                                </div>
                            </div>
                            {isError && <div className="flex flex-row w-full items-center justify-center mt-8"><h2>{tableData}</h2></div>}
                            {!isError && tableData.filter(element => element.relative_heat_gain !== 'NaN').map((item, index) => (
                                <div className="flex flex-row w-full h-auto text-xs">
                                    <div className="w-[3%] flex justify-center items-center bg-[#f3f6f7] border-solid border-r-2 border-b-2 border-white whitespace-pre-line">
                                        <h2 className="text-center">
                                            <input
                                                id="remember-me"
                                                name="selectCheckbox"
                                                type="checkbox"
                                                className="h-4 w-4 text-highlight-primary focus:ring-sky-500 border-gray-300 rounded mr-2"
                                                checked={item.isChecked}
                                                onChange={() => handleSelectionCheckbox(index)}
                                            />
                                        </h2>
                                    </div>
                                    <div className="w-[5%] flex justify-center items-center bg-[#f3f6f7] border-solid border-r-2 border-b-2 border-white">
                                        <h2 className="text-center">
                                            <button
                                                type="submit"
                                                onClick={() => editFamilyTypeModalOpenHandler(index)}
                                            >
                                                {item.family}
                                            </button>
                                        </h2>
                                    </div>
                                    <div className="w-[37%] flex justify-center items-center bg-[#f3f6f7] border-solid border-r-2 border-b-2 border-white whitespace-pre-line">
                                        <h2 className="text-center">
                                            <button
                                                type="submit"
                                                onClick={() => editProductDescriptionModalOpenHandler(index)}
                                            >
                                                {isMetric && String.raw`${item.productMetric.split(';').join(';\n')}`}
                                                {!isMetric && String.raw`${item.product.split(';').join(';\n')}`}
                                            </button>
                                        </h2>
                                    </div>
                                    <div className="w-[20%] flex justify-center items-center bg-[#f3f6f7] border-solid border-r-2 border-b-2 border-white">
                                        <select
                                                className="flex flex-col w-3/4 rounded border border-gray-300 py-2"
                                                onChange={(event) => handleSampleSize(event, index)}
                                        >
                                            <option selected>Select Sample Size</option>
                                            {SAMPLE_SIZE_CONSTANTS.map((sampleSize) => (
                                                <option value={sampleSize.value}>{sampleSize.value}</option>
                                            ))}
                                        </select>
                                    </div>
                                    <div className="w-[20%] flex justify-center items-center bg-[#f3f6f7] border-solid border-r-2 border-b-2 border-white">
                                        <select id="quantitySize"
                                                className="flex flex-col w-3/4 rounded border border-gray-300 py-2"
                                                onChange={(event) => handleQuantitySize(event, index)}
                                        >
                                            <option selected>Select Quantity</option>
                                            {QUANTITY_CONSTANTS.map((quantitySize) => (
                                                <option value={quantitySize.id}>{quantitySize.value}</option>
                                            ))}
                                        </select>
                                    </div>
                                    <div className="w-[15%] flex justify-center items-center bg-[#ebf2f6] border-solid border-r-2 border-b-2 border-white">
                                        <div className="flex flex-row w-full justify-evenly text-sm text-[#0d3857]">
                                            {!item.exist &&
                                                <div className="flex flex-col text-red-500 h=full items-center justify-center">
                                                    <WarningIcon />
                                                </div>
                                            }
                                            {item.exist &&
                                                <div className="flex flex-col text-blue-500 h=full items-center justify-center">
                                                    <ApprovedIcon />
                                                </div>
                                            }
                                            <button
                                                type="submit"
                                                onClick={() => spandrelModalOpenHandler(index)}
                                                className="p-2 group relative cursor-pointer"
                                                aria-label="Spandrel Creation"
                                            >
                                                <IconSpandrel />
                                                <span className="opacity-0 group-hover:opacity-100 transition-opacity duration-100 absolute bottom-full left-1/2 -translate-x-1/2 bg-gray-800 text-white text-xs py-1 px-1 rounded-md">
                                            Spandrel
                                            </span>
                                            </button>
                                            <button
                                                type="submit"
                                                onClick={() => saveResult(index)}
                                                className="p-2 group relative cursor-pointer"
                                                aria-label="Single PDF Export"
                                            >
                                                {item.save ? <IconStarYellow /> : <IconStar />}
                                                <span className="opacity-0 group-hover:opacity-100 transition-opacity duration-100 absolute bottom-full left-1/2 -translate-x-1/2 bg-gray-800 text-white text-xs py-1 px-1 rounded-md">
                                            Favourites
                                            </span>
                                            </button>
                                            <button
                                                type="submit"
                                                onClick={() => deleteTableItem(index)}
                                                className="p-2 group relative cursor-pointer"
                                                aria-label="Delete"
                                            >
                                                <IconTrash />
                                                <span className="opacity-0 group-hover:opacity-100 transition-opacity duration-100 absolute bottom-full left-1/2 -translate-x-1/2 bg-gray-800 text-white text-xs py-1 px-1 rounded-md">
                                            Delete
                                            </span>
                                            </button>
                                        </div>
                                    </div>
                                    {/* Designer Function Part End */}
                                </div>
                            ))}
                        </>
                    }

                    {isCreateOrder &&
                        <div className="w-full h-full">
                            <ShipmentDetailForm
                                data={tableData}
                                submitFunction={createOrderFunction}
                            />
                        </div>
                    }
                </div>
            }

            <SpandrelModal
                open={isSpandrelModalOpen}
                submitFunction ={spandrelModalSubmitFunction}
                spandrelIndex = {spandrelIndex}
                onClose={() => setSpandrelModalOpen(false)}
            />

            <EditFamilyModal
                open={isEditFamilyTypeModalOpen}
                editFunction={editFamilyName}
                editIndex={editIndex}
                defaultValue={editDefaultValue}
                onClose={() => setEditFamilyTypeModalOpen(false)}
            />

            <EditProductDescriptionModal
                open={isEditProductDescriptionModal}
                editFunction={editProductDescription}
                editIndex={editIndex}
                defaultValue={editDefaultValue}
                onClose={() => setEditProductDescriptionModalOpen(false)}
            />
        </section>
    );
}


export default SampleReport;