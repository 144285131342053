import { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import IconSearch from '../../components/Icon/IconSearch';
import IconPlus from '../../components/Icon/IconPlus';
import { useGetComponentMutation, usePostComponentAddMutation, usePostComponentDeleteMutation } from '../../store/services/api';
import IconTrash from '../../components/Icon/IconTrash';

const ComponentDatabase = () => {
    const [searchId, setSearchId] = useState('');
    const [showNoComponentView, setShowNoComponentView] = useState(true);
    const [file, setFile] = useState('');
    const [trigger, result] = useGetComponentMutation();
    const [triggerAdd, triggerAddResult] = usePostComponentAddMutation();
    const [triggerDelete] = usePostComponentDeleteMutation();

    const handleFileChange = (event) => {
        setFile(event.target.files[0]);
    };

    function handleTextFieldChange(event) {
        setSearchId(event.target.value);
    }

    const handleDeleteComponent = async (id) => {
        const deleteResult = await triggerDelete(id);
        deleteResult.data ? toast.success('Delete Successfully!') : toast.error('Delete Failed!');
        window.location.reload(false);
    }

    const searchProductId = async () => {
        if(searchId === '') {
            toast.error('Search field cannot be empty!')
        } else {
            trigger(encodeURIComponent(searchId));
        }
    }

    useEffect(() => {
        if (result.data) {
            setShowNoComponentView(false);
        }
    }, [result.isSuccess]);

    useEffect(() => {
        if (triggerAddResult.data) {
            if(triggerAddResult.data[1] === 200) {
                toast.success('New component successfully uploaded!')
            } else {
                toast.error('Pywincalc test with component failed!')
            }
        }
    }, [triggerAddResult.isSuccess]);

    function addNewComponent() {
        if (file !== '') {
            const formData = new FormData();
            formData.append('file', file);

            triggerAdd(formData);
        } else {
            toast.error('Please select a component file first')
        }
    }

    return (
        <section className="h-[calc(100vh_-_2rem)] w-full bg-transparent p-4 rounded">
            <div className="flex flex-col h-full w-full">
                <div className="flex flex-row basis-2/6 items-center justify-center">
                    <h2 className="font-bold">Search Component: </h2>
                    <div className="w-2/12 h-1/6 pl-4">
                        <input
                            type="string"
                            className="w-full h-full text-center rounded font-bold border-solid border-2 border-black"
                            placeholder="Component Name"
                            onChange={handleTextFieldChange}
                            value={searchId}
                        />
                    </div>
                    <div className="pl-4 w-1/12 h-1/6">
                        <button
                            type="submit"
                            onClick={() => searchProductId()}
                            className="bg-[#047bc2] text-white w-full h-full rounded"
                        >
                            <div className="flex flex-row w-full items-center justify-center">
                                <IconSearch/>
                                <h2 className="pl-2">Search</h2>
                            </div>
                        </button>
                    </div>
                </div>

                <div className="flex flex-col basis-4/6 items-center justify-start">
                    {showNoComponentView &&
                        <h2 className="font-bold">No Component Searched</h2>
                    }

                    {result.isLoading &&
                        <h2 className="font-bold">Loading..........</h2>
                    }

                    {result.isSuccess && result.data !== null && !showNoComponentView &&
                        <div className="flex flex-col h-full w-full">
                            <div className="flex flex-row w-full text-white text-sm pt-12 justify-center items-center">
                                <div className="w-[30%] h-20 flex justify-center items-center bg-[#0d3857] border-solid border-r-2 border-white group relative cursor-pointer">
                                    <h2 className="text-center">Component Name</h2>
                                </div>
                                <div className="w-[30%] h-20 flex justify-center items-center bg-[#3795ce]">
                                    <h2 className="text-center">Actions</h2>
                                </div>
                            </div>
                            <div className="flex flex-row w-full text-white text-sm justify-center items-center">
                                <div className="w-[30%] h-20 flex justify-center items-center bg-[#f3f6f7] border-solid border-r-2 border-white group relative cursor-pointer">
                                    <h2 className="text-center text-black">{result.data.file_name}</h2>
                                </div>
                                <div className="w-[30%] h-20 flex justify-center items-center bg-[#f3f6f7]">
                                    <button type="submit" onClick={() => handleDeleteComponent(result.data.id)} aria-label="Delete" className="bg-[#047bc2] text-white w-1/4 p-2 rounded flex items-center justify-center"><IconTrash /></button>
                                </div>
                            </div>
                        </div>
                    }
                </div>

                <div className="flex flex-row basis-2/6 items-center justify-center">
                    <h2 className="font-bold">Add New Component: </h2>
                    <div className="w-2/12 h-1/6 pl-4">
                        <input
                            type="File"
                            name="file"
                            className="w-full h-full text-center rounded font-bold"
                            onChange={handleFileChange}
                        />
                    </div>
                    <div className="pl-4 w-1/12 h-1/6">
                        <button
                            type="submit"
                            onClick={() => addNewComponent()}
                            className="bg-[#047bc2] text-white w-full h-full rounded"
                        >
                            <div className="flex flex-row w-full items-center justify-center">
                                <IconPlus/>
                                <h2 className="pl-2">ADD</h2>
                            </div>
                        </button>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default ComponentDatabase;