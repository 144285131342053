import PropTypes from 'prop-types';
import { ErrorMessage, Field } from 'formik';

const FormCheckbox = ({ name, label}) => (
    <div className="flex flex-row w-full h-10 text-[#3C3C3C] text-[11px]">
        <label htmlFor={name} className="flex flex-col justify-center w-1/4 pl-1 mt-2">
            <span className="text-black">{label}</span>
            <span className="text-red-600"><ErrorMessage name={name} /></span>
        </label>

        <Field type="checkbox" name={name} className="py-2"/>
    </div>
);

FormCheckbox.propTypes = {
    name: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
};

export default FormCheckbox;
