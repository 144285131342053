import ExcelJS from 'exceljs';
import FileSaver from 'file-saver';

export const exportToExcel = async (data) => {
    const excelData = data[0].sampleData;
    const isMetric = false;
    const fileExtension = '.xlsx';

    const currentDate = new Date().toDateString();
    let lastRowIndex = 4; // Index should start from 4th row since the first 3 is used for headers
    try {
        // Create a workbook and add a worksheet
        const workbook = new ExcelJS.Workbook();
        const worksheet = workbook.addWorksheet('Report');

        // Define keys to skip
        const newLine =['product']
        const keysToSkip = ['product_id', 'productMetric', 'u_value_solar_metric', 'u_value_summer_metric', 'relative_heat_gain_metric', 'save', 'isChecked', 'status', 'exist'];
        const keysToPercentage = ['description_solar', 'description_uv', 'description_visible','reflectance_out','reflectance_in','reflectance_solar'];

        // Create a new array without the undesired keys
        const newArray = excelData.map(item => {
            const newItem = {};
            Object.keys(item).forEach(key => {
                if (!keysToSkip.includes(key)) {
                    if(isMetric) {
                        switch (key) {
                            case 'u_value_solar':
                                newItem[key] = item.u_value_solar_metric;
                                break;
                            case 'u_value_summer':
                                newItem[key] = item.u_value_summer_metric;
                                break;
                            case 'relative_heat_gain':
                                newItem[key] = item.relative_heat_gain_metric;
                                break;
                            default:
                                newItem[key] = item[key];
                        }
                    } else {
                        newItem[key] = item[key];
                    }

                }
                if (keysToPercentage.includes(key)) {
                    newItem[key] = `${item[key]}%`;
                }
                if (newLine.includes(key)) {
                    newItem[key] = isMetric ? `${item.productMetric.split(';').join(';\n')}` : `${item.product.split(';').join(';\n')}`;
                }
            });
            return newItem;
        }).filter(item => item !== null);

        // TODO horizontal might be center as well
        const generalStyle = {
            fill: { type: 'pattern', pattern: 'solid', fgColor: { argb: 'ffdbe4f3' }},
            alignment: { horizontal: 'center', vertical: 'middle', wrapText: true },
            border: {
                top: {style:'medium', color: {argb:'FFFFFFFF'}},
                left: {style:'medium', color: {argb:'FFFFFFFF'}},
                bottom: {style:'medium', color: {argb:'FFFFFFFF'}},
                right: {style:'medium', color: {argb:'FFFFFFFF'}}
            },
            font: { name: 'Calibri', size: 13 }
        }

        for (let i = 0; i < newArray.length; i+= 1) {
            const rowData = newArray[i];
            const row = worksheet.getRow(i + 3); // just to start from the third row
            row.height = 150;
            // Set values for each cell in the row
            Object.keys(rowData).forEach((key, index) => {
                row.getCell(index + 1).value = rowData[key];
                row.getCell(index + 1).style = generalStyle;
            });
            lastRowIndex+=1;
        }

        const row1 = worksheet.getRow(1);
        row1.height = 40;
        const row2 = worksheet.getRow(2);
        row2.height = 15;
        const columnA = worksheet.getColumn('A');
        columnA.width = 15;
        const columnB = worksheet.getColumn('B');
        columnB.width = 40;
        const columnN = worksheet.getColumn('N');
        columnN.width = 20;

        const columnJ =worksheet.getColumn('J');
        columnJ.width = 10;

        const columnK =worksheet.getColumn('K');
        columnK.width = 20;
        const columnL =worksheet.getColumn('L');
        columnL.width = 20;
        const columnM =worksheet.getColumn('M');
        columnM.width = 20;
        const columnO =worksheet.getColumn('O');
        columnO.width = 20;
        const columnP =worksheet.getColumn('P');
        columnP.width = 20;

        // Merge cells for first row
        worksheet.mergeCells('C1:E1');
        worksheet.mergeCells('F1:H1');
        worksheet.mergeCells('I1:J1');

        const b2Style = {
            alignment: { horizontal: 'center', vertical: 'middle', wrapText: true },
            fill: { type: 'pattern', pattern: 'solid', fgColor: { argb: '0A2A41' }},
            font: { name: 'Calibri', size: 13, color: { argb: 'FFFFFFFF' }, bold: true }
        }

        const c2Style = {
            alignment: { horizontal: 'center', vertical: 'middle', wrapText: true },
            fill: { type: 'pattern', pattern: 'solid', fgColor: { argb: '0d3857' }},
            font: { name: 'Calibri', size: 13, color: { argb: 'FFFFFFFF' }, bold: true }
        }

        const f2Style = {
            alignment: { horizontal: 'center', vertical: 'middle', wrapText: true },
            fill: { type: 'pattern', pattern: 'solid', fgColor: { argb: '3b6178' }},
            font: { name: 'Calibri', size: 13, color: { argb: 'FFFFFFFF' }, bold: true }
        }

        const i2Style = {
            alignment: { horizontal: 'center', vertical: 'middle', wrapText: true },
            fill: { type: 'pattern', pattern: 'solid', fgColor: { argb: '1f6e9e' }},
            font: { name: 'Calibri', size: 13, color: { argb: 'FFFFFFFF' }, bold: true }
        }

        const k2Style = {
            alignment: { horizontal: 'center', vertical: 'middle', wrapText: true },
            fill: { type: 'pattern', pattern: 'solid', fgColor: { argb: '0079c1' }},
            font: { name: 'Calibri', size: 13, color: { argb: 'FFFFFFFF' }, bold: true }
        }

        const l2Style = {
            alignment: { horizontal: 'center', vertical: 'middle', wrapText: true },
            fill: { type: 'pattern', pattern: 'solid', fgColor: { argb: '3795ce' }},
            font: { name: 'Calibri', size: 13, color: { argb: 'FFFFFFFF' }, bold: true }
        }

        const m2Style = {
            alignment: { horizontal: 'center', vertical: 'middle', wrapText: true },
            fill: { type: 'pattern', pattern: 'solid', fgColor: { argb: '3795ce' }},
            font: { name: 'Calibri', size: 13, color: { argb: 'FFFFFFFF' }, bold: true }
        }

        const n2Style = {
            alignment: { horizontal: 'center', vertical: 'middle', wrapText: true },
            fill: { type: 'pattern', pattern: 'solid', fgColor: { argb: '0A2A41' }},
            font: { name: 'Calibri', size: 13, color: { argb: 'FFFFFFFF' }, bold: true }
        }

        worksheet.getCell('A1').value = 'Glass Tag';
        worksheet.getCell('B1').value = 'Product';
        worksheet.getCell('C1').value = 'Transmission';
        worksheet.getCell('F1').value = 'Reflectance';
        worksheet.getCell('I1').value = 'U-Value';
        worksheet.getCell('K1').value = 'Shading Coefficient';
        worksheet.getCell('L1').value = 'Solar Heat Gain';
        worksheet.getCell('M1').value = 'Relative Heat Gain';
        worksheet.getCell('N1').value = 'Light to Solar Gain';
        worksheet.getCell('O1').value = 'Sample Size';
        worksheet.getCell('P1').value = 'Quantity';

        worksheet.getCell('A1').style = c2Style;
        worksheet.getCell('B1').style = b2Style;
        worksheet.getCell('C1').style = c2Style;
        worksheet.getCell('F1').style = f2Style;
        worksheet.getCell('I1').style = i2Style;
        worksheet.getCell('K1').style = k2Style;
        worksheet.getCell('L1').style = l2Style;
        worksheet.getCell('M1').style = m2Style;
        worksheet.getCell('N1').style = n2Style;
        worksheet.getCell('O1').style = f2Style;
        worksheet.getCell('P1').style = i2Style;

        const c3Style = {
            alignment: { horizontal: 'center', vertical: 'middle', wrapText: true },
            fill: { type: 'pattern', pattern: 'solid', fgColor: { argb: '3d6078' }},
            font: { name: 'Calibri', size: 13, color: { argb: 'FFFFFFFF' }, bold: true }
        }
        const d3Style = {
            alignment: { horizontal: 'center', vertical: 'middle', wrapText: true },
            fill: { type: 'pattern', pattern: 'solid', fgColor: { argb: '557489' }},
            font: { name: 'Calibri', size: 13, color: { argb: 'FFFFFFFF' }, bold: true }
        }
        const e3Style = {
            alignment: { horizontal: 'center', vertical: 'middle', wrapText: true },
            fill: { type: 'pattern', pattern: 'solid', fgColor: { argb: '6e889a' }},
            font: { name: 'Calibri', size: 13, color: { argb: 'FFFFFFFF' }, bold: true }
        }
        const f3Style = {
            alignment: { horizontal: 'center', vertical: 'middle', wrapText: true },
            fill: { type: 'pattern', pattern: 'solid', fgColor: { argb: '628193' }},
            font: { name: 'Calibri', size: 13, color: { argb: 'FFFFFFFF' }, bold: true }
        }
        const g3Style = {
            alignment: { horizontal: 'center', vertical: 'middle', wrapText: true },
            fill: { type: 'pattern', pattern: 'solid', fgColor: { argb: '7690a0' }},
            font: { name: 'Calibri', size: 13, color: { argb: 'FFFFFFFF' }, bold: true }
        }
        const h3Style = {
            alignment: { horizontal: 'center', vertical: 'middle', wrapText: true },
            fill: { type: 'pattern', pattern: 'solid', fgColor: { argb: '89a0ae' }},
            font: { name: 'Calibri', size: 13, color: { argb: 'FFFFFFFF' }, bold: true }
        }
        const i3Style = {
            alignment: { horizontal: 'center', vertical: 'middle', wrapText: true },
            fill: { type: 'pattern', pattern: 'solid', fgColor: { argb: '4b8bb1' }},
            font: { name: 'Calibri', size: 13, color: { argb: 'FFFFFFFF' }, bold: true }
        }
        const j3Style = {
            alignment: { horizontal: 'center', vertical: 'middle', wrapText: true },
            fill: { type: 'pattern', pattern: 'solid', fgColor: { argb: '6299bb' }},
            font: { name: 'Calibri', size: 13, color: { argb: 'FFFFFFFF' }, bold: true }
        }

        worksheet.getCell('A2').value = '';
        worksheet.getCell('B2').value = '';
        worksheet.getCell('C2').value = 'Visible';
        worksheet.getCell('D2').value = 'Solar';
        worksheet.getCell('E2').value = 'Uv';
        worksheet.getCell('F2').value = 'Vis-Out';
        worksheet.getCell('G2').value = 'Vis-In';
        worksheet.getCell('H2').value = 'Solar';
        worksheet.getCell('I2').value = 'Winter';
        worksheet.getCell('J2').value = 'Summer';
        worksheet.getCell('K2').value = '';
        worksheet.getCell('L2').value = '';
        worksheet.getCell('M2').value = '';
        worksheet.getCell('N2').value = '';
        worksheet.getCell('O2').value = '';
        worksheet.getCell('P2').value = '';

        worksheet.getCell('A2').style = c2Style;
        worksheet.getCell('B2').style = b2Style;
        worksheet.getCell('C2').style = c3Style;
        worksheet.getCell('D2').style = d3Style;
        worksheet.getCell('E2').style = e3Style;
        worksheet.getCell('F2').style = f3Style;
        worksheet.getCell('G2').style = g3Style;
        worksheet.getCell('H2').style = h3Style;
        worksheet.getCell('I2').style = i3Style;
        worksheet.getCell('J2').style = j3Style;
        worksheet.getCell('K2').style = k2Style;
        worksheet.getCell('L2').style = l2Style;
        worksheet.getCell('M2').style = m2Style;
        worksheet.getCell('N2').style = n2Style;
        worksheet.getCell('O2').style = f2Style;
        worksheet.getCell('P2').style = i2Style;

        // Add data and styles for the second and third rows
        // Note: You need to adjust the styles and data based on your requirements

        // Create a buffer and save the file
        const buffer = await workbook.xlsx.writeBuffer();
        const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        const filename = `${currentDate}${fileExtension}`;
        FileSaver.saveAs(blob, filename);
    } catch (error) {
        console.error('Error exporting to Excel:', error);
    }
};