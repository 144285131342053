export const INSULATING_GLASS_TYPE_CONSTANT = [
    {
        id: 0,
        name: 'GAG',
        value: 'GAG',
    },
    {
        id: 1,
        name: 'GAGAG',
        value: 'GAGAG',
    },
];

export const LAMINATING_GLASS_TYPE_CONSTANT = [
    {
        id: 0,
        name: 'GVG',
        value: 'GIG',
    },
    {
        id: 1,
        name: 'GVGVG',
        value: 'GIGIG',
    },
    {
        id: 2,
        name: 'GVGVGVG',
        value: 'GIGIGIG',
    },
];

export const INSULATING_LAMINATING_GLASS_TYPE_CONSTANT = [
    {
        id: 0,
        name: 'GAGVG',
        value: 'GAGIG',
    },
    {
        id: 1,
        name: 'GAGAGVG',
        value: 'GAGAGIG',
    },
    {
        id: 2,
        name: 'GAGVGAG',
        value: 'GAGIGAG',
    },
    {
        id: 3,
        name: 'GAGAGVGVG',
        value: 'GAGAGIGIG',
    },
    {
        id: 4,
        name: 'GAGVGVG',
        value: 'GAGIGIG',
    },
    {
        id: 5,
        name: 'GAGVGVGVG',
        value: 'GAGIGIGIG',
    },
    {
        id: 6,
        name: 'GAGVGVGVGVG',
        value: 'GAGIGIGIGIG',
    },
];

export const LAMINATED_INSULATING_GLASS_TYPE_CONSTANT = [
    {
        id: 0,
        name: 'GVGAG',
        value: 'GIGAG',
    },
    {
        id: 1,
        name: 'GVGAGAG',
        value: 'GIGAGAG',
    },
    {
        id: 2,
        name: 'GVGVGAG',
        value: 'GIGIGAG',
    },
];

export const DOUBLE_LAMINATED_INSULATING_GLASS_TYPE_CONSTANT = [
    {
        id: 0,
        name: 'GVGAGVG',
        value: 'GIGAGIG',
    },
    {
        id: 1,
        name: 'GVGVGAGVG',
        value: 'GIGIGAGIG',
    },
    {
        id: 2,
        name: 'GAGVGAGVG',
        value: 'GAGIGAGIG',
    },{
        id: 3,
        name: 'GVGAGAGVG',
        value: 'GIGAGAGIG',
    },
    {
        id: 4,
        name: 'GVGAGAGVGVG',
        value: 'GIGAGAGIGIG',
    },
    {
        id: 5,
        name: 'GVGAGVGVG',
        value: 'GIGAGIGIG',
    },
    {
        id: 6,
        name: 'GVGAGVGAGVG',
        value: 'GIGAGIGAGIG',
    },
    {
        id: 7,
        name: 'GVGAGVGVGVG',
        value: 'GIGAGIGIGIG',
    },
];