import PropTypes from 'prop-types';
import { ErrorMessage, Field } from 'formik';

const FormSelect = ({ name, label, placeholder, options }) => (
    <div className="flex flex-row w-full h-10 text-[#3C3C3C] text-[11px]">
        <label htmlFor={name} className="flex flex-col justify-center w-1/4 pl-1 mt-2">
            <span className="text-black">{label}</span>
            <span className="text-red-600"><ErrorMessage name={name} /></span>
        </label>

        <Field as="select" name={name} className="flex flex-col w-3/4 rounded border border-gray-300 py-2">
            <option value="" disabled>
                {placeholder}
            </option>
            {options.map(({ key, value }) => (
                <option value={value}>
                    {value}
                </option>
            ))}
        </Field>
    </div>
);

FormSelect.propTypes = {
    name: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    placeholder: PropTypes.string.isRequired,
    options: PropTypes.array.isRequired,
};

export default FormSelect;
