import { useEffect, useState } from 'react';
import * as Yup from 'yup';
import { Form, Formik } from 'formik';
import PropTypes from 'prop-types';
import FormSelect from '../../components/Form/FormSelect';
import {
    DESTINATION_CONSTANTS, PRODUCT_TYPE_CONSTANTS,
    PROJECT_TYPE_CONSTANTS,
    SALE_TERRITORY_TYPE_CONSTANTS,
    COUNTRY_CONSTANT, STATE_CONSTANTS
} from './FormConstant';
import FormTextInputRow from '../../components/Form/FormTextInputRow';
import FormCheckbox from '../../components/Form/FormCheckbox';
import FormDatePicker from '../../components/Form/FormDatePicker';

const ShipmentDetailForm = ({data, prevFormData, submitFunction, buttonLabel}) => {
    const [isLoading, setIsLoading] = useState(false);
    const [shipmentDetailValues, setShipmentDetailValues] = useState({
        destination: prevFormData?.destination || '',
        address1: prevFormData?.address1 || '',
        address2: prevFormData?.address2 || '',
        city: prevFormData?.city || '',
        state: prevFormData?.state || '',
        postcode: prevFormData?.postcode || '',
        address3: prevFormData?.address3 || '',
        country: prevFormData?.country || '',
        customerName: prevFormData?.customerName || '',
        customerPhone: prevFormData?.customerPhone || '',
        customerEmail: prevFormData?.customerEmail || '',
        projectType: prevFormData?.projectType || '',
        salesTerritory: prevFormData?.salesTerritory || '',
        productType: prevFormData?.productType || '',
        shipToSameAddress: prevFormData?.shipToSameAddress || false,
        shipmentDestination: prevFormData?.shipmentDestination || '',
        shipmentAddress: prevFormData?.shipmentAddress || '',
        shipmentAddress2: prevFormData?.shipmentAddress2 || '',
        shipmentCity: prevFormData?.shipmentCity || '',
        shipmentState: prevFormData?.shipmentState || '',
        shipmentPostcode: prevFormData?.shipmentPostcode || '',
        shipmentAddress3: prevFormData?.shipmentAddress3 || '',
        shipmentCountry: prevFormData?.shipmentCountry || '',
        projectName: prevFormData?.projectName || '',
        projectShortName: prevFormData?.projectShortName || '',
        chargeable: prevFormData?.chargeable || false,
        requestedDeliveryDate: prevFormData?.requestedDeliveryDate || new Date().toISOString().split('T')[0],
        estimatedDeliveryDate: prevFormData?.estimatedDeliveryDate || new Date().toISOString().split('T')[0],
        ownerName: prevFormData?.ownerName || '',
        ownerPhone: prevFormData?.ownerPhone || '',
        ownerEmail: prevFormData?.ownerEmail || '',
    });
    const formikShipmentDetailValuesSchema = Yup.object().shape({
        destination: Yup.string().required('Field required'),
        address1: Yup.string(),
        address2: Yup.string(),
        city: Yup.string(),
        state: Yup.string(),
        postcode: Yup.string(),
        address3: Yup.string(),
        country: Yup.string(),
        customerName: Yup.string(),
        customerPhone: Yup.string(),
        customerEmail: Yup.string(),
        projectType: Yup.string(),
        salesTerritory: Yup.string(),
        productType: Yup.string(),
        shipToSameAddress: Yup.boolean(),
        shipmentDestination: Yup.string(),
        shipmentAddress: Yup.string() ,
        shipmentAddress2: Yup.string(),
        shipmentCity: Yup.string(),
        shipmentState: Yup.string(),
        shipmentPostcode: Yup.string(),
        shipmentAddress3: Yup.string(),
        shipmentCountry: Yup.string(),
        projectName: Yup.string(),
        projectShortName: Yup.string(),
        chargeable: Yup.boolean(),
        requestedDeliveryDate: Yup.date(),
        estimatedDeliveryDate: Yup.date(),
        ownerName: Yup.string(),
        ownerPhone: Yup.string(),
        ownerEmail: Yup.string(),
    });

    const shipmentDetailsSubmitHandler = async (formData) => {
        submitFunction(data,formData);
    }

    useEffect( () => {
        const newDate = new Date();
        newDate.setDate(newDate.getDate() + 1);

        if(shipmentDetailValues.shipmentDestination !== '' && shipmentDetailValues.shipmentDestination === 'International' && shipmentDetailValues.shipmentCountry !== '') {
            const destinationLeadTime = COUNTRY_CONSTANT.filter(element => element.name === shipmentDetailValues.shipmentCountry);
            newDate.setDate(newDate.getDate() + destinationLeadTime[0].leadtime);
        } else if (shipmentDetailValues.shipmentDestination !== '' && shipmentDetailValues.shipmentDestination === 'Domestic' && shipmentDetailValues.shipmentState !== ''){
            const destinationLeadTime = STATE_CONSTANTS.filter(element => element.name === shipmentDetailValues.shipmentState);
            newDate.setDate(newDate.getDate() + destinationLeadTime[0].leadtime);
        }

        if(shipmentDetailValues.productType !== '') {
            const productLeadTime = PRODUCT_TYPE_CONSTANTS.filter(element => element.name === shipmentDetailValues.productType);
            newDate.setDate(newDate.getDate() + productLeadTime[0].leadtime);
        }

        const newEstimatedDeliveryDate = newDate.toISOString().split('T')[0]

        setShipmentDetailValues({
            ...shipmentDetailValues,
            estimatedDeliveryDate: newEstimatedDeliveryDate,
        })

    }, [shipmentDetailValues.shipmentDestination, shipmentDetailValues.productType, shipmentDetailValues.shipmentCountry, shipmentDetailValues.shipmentState]);

    useEffect( () => {
        if(shipmentDetailValues.shipToSameAddress) {
            if(shipmentDetailValues.destination === 'Domestic') {
                setShipmentDetailValues({
                    ...shipmentDetailValues,
                    shipmentDestination: shipmentDetailValues.destination,
                    shipmentAddress: shipmentDetailValues.address1,
                    shipmentAddress2: shipmentDetailValues.address2,
                    shipmentCity: shipmentDetailValues.city,
                    shipmentState: shipmentDetailValues.state,
                    shipmentPostcode: shipmentDetailValues.postcode,
                })
            }

            if(shipmentDetailValues.destination === 'International') {
                setShipmentDetailValues({
                    ...shipmentDetailValues,
                    shipmentDestination: shipmentDetailValues.destination,
                    shipmentAddress: shipmentDetailValues.address1,
                    shipmentAddress2: shipmentDetailValues.address2,
                    shipmentAddress3: shipmentDetailValues.address3,
                    shipmentCountry: shipmentDetailValues.country,
                })
            }
        }
    }, [shipmentDetailValues.shipToSameAddress]);

    return (
        <Formik
            // eslint-disable-next-line react/jsx-boolean-value
            enableReinitialize={true}
            initialValues={shipmentDetailValues}
            validationSchema={formikShipmentDetailValuesSchema}
            onSubmit={shipmentDetailsSubmitHandler}
        >
            {({ values, handleChange, handleSubmit, resetForm }) => {
                // Update state when form values change
                useEffect(() => {
                    setShipmentDetailValues(values);
                }, [values]);

                return (
                    <Form className="flex flex-row w-full h-full gap-2 py-4">
                        <div className="flex flex-col w-1/3 h-full">
                            <FormTextInputRow name="ownerName" label='Owner Name:' />
                            <FormTextInputRow name="ownerPhone" label='Owner Phone:' />
                            <FormTextInputRow name="ownerEmail" label='Owner Email:' />
                            <FormSelect
                                name="destination"
                                label='Destination:'
                                placeholder="Select Destination"
                                options={DESTINATION_CONSTANTS.map((element) => ({ key: element.id, value: element.name }))}
                            />
                            <FormTextInputRow name="address1" label='Address 1:' />
                            <FormTextInputRow name="address2" label='Address 2:' />
                            {shipmentDetailValues.destination != null && shipmentDetailValues.destination === 'Domestic' &&
                                <>
                                    <FormTextInputRow name="city" label='City:' />
                                    <FormSelect
                                        name="state"
                                        label='State:'
                                        placeholder="Select State"
                                        options={STATE_CONSTANTS.map((element) => ({ key: element.id, value: element.name }))}
                                    />
                                    <FormTextInputRow name="postcode" label='Postcode:' />
                                </>
                            }
                            {shipmentDetailValues.destination != null && shipmentDetailValues.destination === 'International' &&
                                <>
                                    <FormTextInputRow name="address3" label='Address 3:' />
                                    <FormSelect
                                        name="country"
                                        label='Country:'
                                        placeholder="Select Country"
                                        options={COUNTRY_CONSTANT.map((element) => ({ key: element.id, value: element.name }))}
                                    />
                                </>
                            }
                            <FormCheckbox name="shipToSameAddress" label="Ship to Same address ?" />
                        </div>
                        <div className="flex flex-col w-1/3 h-full">
                            <FormSelect
                                name="shipmentDestination"
                                label='Shipment Destination:'
                                placeholder="Select Destination"
                                options={DESTINATION_CONSTANTS.map((element) => ({ key: element.id, value: element.name }))}
                            />
                            <FormTextInputRow name="shipmentAddress" label='Shipment Address 1:' />
                            <FormTextInputRow name="shipmentAddress2" label='Shipment Address 2:' />
                            {shipmentDetailValues.shipmentDestination != null && shipmentDetailValues.shipmentDestination === 'Domestic' &&
                                <>
                                    <FormTextInputRow name="shipmentCity" label='Shipment City:' />
                                    <FormTextInputRow name="shipmentState" label='Shipment State:' />
                                    <FormTextInputRow name="shipmentPostcode" label='Shipment Postcode:' />
                                </>
                            }
                            {shipmentDetailValues.shipmentDestination != null && shipmentDetailValues.shipmentDestination === 'International' &&
                                <>
                                    <FormTextInputRow name="shipmentAddress3" label='Shipment Address 3:' />
                                    <FormSelect
                                        name="shipmentCountry"
                                        label='Shipment Country:'
                                        placeholder="Select Shipment Country"
                                        options={COUNTRY_CONSTANT.map((element) => ({ key: element.id, value: element.name }))}
                                    />
                                </>
                            }
                            <FormCheckbox name="chargeable" label="Chargeable ?" />
                            <FormDatePicker name="requestedDeliveryDate" label='Requested Delivery Date:' />
                        </div>
                        <div className="flex flex-col w-1/3 h-full">
                            <FormTextInputRow name="customerName" label='Customer Name:' />
                            <FormTextInputRow name="customerPhone" label='Customer Phone:' />
                            <FormTextInputRow name="customerEmail" label='Customer Email:' />
                            <FormTextInputRow name="projectName" label='Project Name:' />
                            {/* <FormTextInputRow name="projectShortName" label='Project Short Name:' /> */}
                            <FormSelect
                                name="projectType"
                                label='Project Type:'
                                placeholder="Select Project Type"
                                options={PROJECT_TYPE_CONSTANTS.map((element) => ({ key: element.id, value: element.name }))}
                            />
                            <FormSelect
                                name="salesTerritory"
                                label='Sale Territory:'
                                placeholder="Select Sale Territory"
                                options={SALE_TERRITORY_TYPE_CONSTANTS.map((element) => ({ key: element.id, value: element.name }))}
                            />
                            <FormSelect
                                name="productType"
                                label='Product Type:'
                                placeholder="Select Product Type"
                                options={PRODUCT_TYPE_CONSTANTS.map((element) => ({ key: element.id, value: element.name }))}
                            />
                            <FormDatePicker name="estimatedDeliveryDate" label='Estimated Delivery Date:' isDisable='true' />

                            <div className="flex flex-row w-full items-end justify-end mt-4">
                                <button
                                    type="submit"
                                    id='2'
                                    className="w-3/4 p-2 mt-2 mb-2 border-2 border-slate-300 rounded bg-slate-100 text-[11px] text-black font-bold"
                                    disabled={isLoading}
                                >
                                    {buttonLabel}
                                </button>
                            </div>
                        </div>

                    </Form>
                )}}
        </Formik>
    )
};

ShipmentDetailForm.propTypes = {
    data: PropTypes.object.isRequired,
    prevFormData: PropTypes.object,
    submitFunction: PropTypes.func.isRequired,
    buttonLabel: PropTypes.string,
};

ShipmentDetailForm.defaultProps = {
    prevFormData: {},
    buttonLabel: 'Create Order',
};

export default ShipmentDetailForm;