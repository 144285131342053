import PropTypes from 'prop-types';
import { ErrorMessage, Field } from 'formik';

const FormDataPicker = ({ name, label, labelColor, isDisable }) => (
    <div className="flex flex-row w-full h-10 text-[#3C3C3C] text-[11px]">
        <label htmlFor={name} className="flex flex-col justify-center w-1/4 pl-1 mt-2">
            <span className={`text-${labelColor}`}>{label}</span>
            <span className="text-red-600"><ErrorMessage name={name} /></span>
        </label>

        <div className="flex flex-col w-3/4 mt-1">
            <Field
                id={name}
                type="date"
                disabled={isDisable}
                name={name}
                className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-highlight-primary focus:border-highlight-primary sm:text-sm"
            />
        </div>
    </div>
);

FormDataPicker.propTypes = {
    name: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    labelColor: PropTypes.string,
    isDisable: PropTypes.bool,
};

FormDataPicker.defaultProps = {
    labelColor: 'black',
    isDisable: false
};

export default FormDataPicker;
