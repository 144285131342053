import { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import SurfaceDropdown from '../../components/Dropdown/SurfaceDropdown';
import IconPlus from '../../components/Icon/IconPlus';
import DropdownInterlayer from '../../components/DropdownMenu/DropdownInterlayer';
import IconTrash from '../../components/Icon/IconTrash';
import IconRefresh from '../../components/Icon/IconRefresh';
import ReportModal from './ReportModal';
import IconDownArrow from '../../components/Icon/IconDownArrow';
import { useGetRuleCheckMutation } from '../../store/services/api';

const MainPage = () => {
    const [customDiv, setCustomDiv] = useState([]);
    const [selectedGlassType, setSelectedGlassType] = useState('');
    const [glassLayerList, setGlassLayerList] = useState([]);
    const [assignGlassListInner, setAssignGlassListInner] = useState([]);
    const [assignGlassListMiddle, setAssignGlassListMiddle] = useState([]);
    const [assignGlassListOuter, setAssignGlassListOuter] = useState([]);
    const [customDivCenter, setCustomDivCenter] = useState(0);
    const [dropdownHandler, setDropdownHandler] = useState({
        id: -1,
        value: '',
        surfaceIndex: -1
    })
    const [dropdownThicknessHandler, setDropdownThicknessHandler] = useState({
        id: -1,
        value: '',
        surfaceIndex: -1,
        isPrint: false,
    })
    const [extraDropdownHandler, setExtraDropdownHandler] = useState({
        id: -1,
        value: '',
        surfaceIndex: -1
    })
    const [extraDropdownThicknessHandler, setExtraDropdownThicknessHandler] = useState({
        id: -1,
        value: '',
        surfaceIndex: -1,
        isExtra: false,
    })
    const [searchItems, setSearchItems] = useState([]);
    const [layerLabel, setLayerLabel] = useState('');
    const [requestObject, setRequestObject] = useState([]);
    const [isReportModalOpen, setIsReportModalOpen] = useState(false);
    const [isNoCoating, setIsNoCoating] = useState(false);
    const [isNoSilkscreen, setIsNoSilkscreen] = useState(false);
    const [isOuterOpen, setIsOuterOpen] = useState(false);
    const [isOuterAirOpen, setIsOuterAirOpen] = useState(false);
    const [isMiddleOpen, setIsMiddleOpen] = useState(false);
    const [isMiddleAirOpen, setIsMiddleAirOpen] = useState(false);
    const [isInnerOpen, setIsInnerOpen] = useState(false);
    const [trigger, result] = useGetRuleCheckMutation()
    const [isCheckingRule, setIsCheckingRule] = useState(false);
    const [ruleError, setRuleError] = useState([]);
    const [component1Description, setComponent1Description] = useState('');
    const [component2Description, setComponent2Description] = useState('');
    const [component3Description, setComponent3Description] = useState('');

    // This logic still doesn't work
    const [customLayerLabelDiv, setCustomLayerLabelDiv] = useState([]);
    const [customLabelDivCenter, setCustomLabelDivCenter] = useState(0);

    useEffect(() => {
        if(result.data){
            const tmpRuleError = [];

            // eslint-disable-next-line dot-notation
            result.data['_rule_checks'].forEach((error) => {
                if(!error.test_passed) {
                    tmpRuleError.push(error.rule_message);
                }
            })

            setRuleError(tmpRuleError);
            setIsCheckingRule(false);
        }
    }, [result.isSuccess]);

    // if mode is 1 then it should reset the inner, middle and outer states
    const handleGlassTypeChange = (glassType, mode = 1) => {
        // TODO this part creating bottle neck
        if(mode){
            setAssignGlassListInner([]);
            setAssignGlassListMiddle([]);
            setAssignGlassListOuter([]);
        }
        setSelectedGlassType(glassType);
        const selection = glassType;
        const newGlassLayerList = [];
        let layerIndex = 0;

        selection.split("").forEach(() => {
            newGlassLayerList.push({
                index: layerIndex,
            });
            layerIndex += 1;
        });

        setGlassLayerList(newGlassLayerList);
    };

    useEffect(() => {
        switch (dropdownHandler.surfaceIndex) {
            case 0:
                // eslint-disable-next-line no-case-declarations
                const tmpOuter = [...assignGlassListOuter];
                tmpOuter[dropdownHandler.id].value = dropdownHandler.value;
                setAssignGlassListOuter(tmpOuter);
                break;
            case 1:
                // eslint-disable-next-line no-case-declarations
                const tmpMiddle = [...assignGlassListMiddle];
                tmpMiddle[dropdownHandler.id - assignGlassListOuter.length].value = dropdownHandler.value;
                setAssignGlassListMiddle(tmpMiddle);
                break;
            case 2:
                // eslint-disable-next-line no-case-declarations
                const tmpInner = [...assignGlassListInner];
                tmpInner[dropdownHandler.id - assignGlassListOuter.length - assignGlassListMiddle.length].value = dropdownHandler.value;
                setAssignGlassListInner(tmpInner);
                break;
            default:
                break;
        }
    }, [dropdownHandler])

    useEffect(() => {
        switch (extraDropdownHandler.surfaceIndex) {
            case 0:
                // eslint-disable-next-line no-case-declarations
                const tmpOuter = [...assignGlassListOuter];
                tmpOuter[extraDropdownHandler.id].extraValue = extraDropdownHandler.value;
                setAssignGlassListOuter(tmpOuter);
                break;
            case 1:
                // eslint-disable-next-line no-case-declarations
                const tmpMiddle = [...assignGlassListMiddle];
                tmpMiddle[extraDropdownHandler.id - assignGlassListOuter.length].extraValue = extraDropdownHandler.value;
                setAssignGlassListMiddle(tmpMiddle);
                break;
            case 2:
                // eslint-disable-next-line no-case-declarations
                const tmpInner = [...assignGlassListInner];
                tmpInner[extraDropdownHandler.id - assignGlassListOuter.length - assignGlassListMiddle.length].extraValue = extraDropdownHandler.value;
                setAssignGlassListInner(tmpInner);
                break;
            default:
                break;
        }
    }, [extraDropdownHandler])

    useEffect(() => {
        switch (dropdownThicknessHandler.surfaceIndex) {
            case 0:
                // eslint-disable-next-line no-case-declarations
                const tmpOuter = [...assignGlassListOuter];
                tmpOuter[dropdownThicknessHandler.id].thickness = dropdownThicknessHandler.value;
                tmpOuter[dropdownThicknessHandler.id].isPrint = dropdownThicknessHandler.isPrint;
                setAssignGlassListOuter(tmpOuter);
                break;
            case 1:
                // eslint-disable-next-line no-case-declarations
                const tmpMiddle = [...assignGlassListMiddle];
                tmpMiddle[dropdownThicknessHandler.id - assignGlassListOuter.length].thickness = dropdownThicknessHandler.value;
                tmpMiddle[dropdownThicknessHandler.id - assignGlassListOuter.length].isPrint = dropdownThicknessHandler.isPrint;
                setAssignGlassListMiddle(tmpMiddle);
                break;
            case 2:
                // eslint-disable-next-line no-case-declarations
                const tmpInner = [...assignGlassListInner];
                tmpInner[dropdownThicknessHandler.id - assignGlassListOuter.length - assignGlassListMiddle.length].thickness = dropdownThicknessHandler.value;
                tmpInner[dropdownThicknessHandler.id - assignGlassListOuter.length - assignGlassListMiddle.length].isPrint = dropdownThicknessHandler.isPrint;
                setAssignGlassListInner(tmpInner);
                break;
            default:
                break;
        }
    }, [dropdownThicknessHandler])

    useEffect(() => {
        switch (extraDropdownThicknessHandler.surfaceIndex) {
            case 0:
                // eslint-disable-next-line no-case-declarations
                const tmpOuter = [...assignGlassListOuter];
                tmpOuter[extraDropdownThicknessHandler.id].extraThickness = extraDropdownThicknessHandler.value;
                tmpOuter[extraDropdownThicknessHandler.id].isExtra = extraDropdownThicknessHandler.isExtra;
                setAssignGlassListOuter(tmpOuter);
                break;
            case 1:
                // eslint-disable-next-line no-case-declarations
                const tmpMiddle = [...assignGlassListMiddle];
                tmpMiddle[extraDropdownThicknessHandler.id - assignGlassListOuter.length].extraThickness = extraDropdownThicknessHandler.value;
                tmpMiddle[extraDropdownThicknessHandler.id - assignGlassListOuter.length].isExtra = extraDropdownThicknessHandler.isExtra;
                setAssignGlassListMiddle(tmpMiddle);
                break;
            case 2:
                // eslint-disable-next-line no-case-declarations
                const tmpInner = [...assignGlassListInner];
                tmpInner[extraDropdownThicknessHandler.id - assignGlassListOuter.length - assignGlassListMiddle.length].extraThickness = extraDropdownThicknessHandler.value;
                tmpInner[extraDropdownThicknessHandler.id - assignGlassListOuter.length - assignGlassListMiddle.length].isExtra = extraDropdownThicknessHandler.isExtra;
                setAssignGlassListInner(tmpInner);
                break;
            default:
                break;
        }
    }, [extraDropdownThicknessHandler])

    function addInterlayerToGlass(index, size) {
        const selection = selectedGlassType;
        let firstPart = selection.slice(0, index+1);
        const secondPart = selection.slice(index+1);
        // let newObjectStartIndex = 1;

        for(let i = 0 ; i < size ; i += 1) {
            firstPart += 'I';
            // newObjectStartIndex += 1;
        }
        firstPart += 'G';
        handleGlassTypeChange(firstPart + secondPart, 0);
    }

    function removeInterlayerFromGlass (index) {
        const selection = selectedGlassType;
        const firstPart = selection.slice(0, index+1);
        let secondPart = '';

        if(selection.length > index) {
            for(let i = index + 1 ; i < selection.length ; i += 1) {
                if (selection[i] === 'G') {
                    secondPart = selection.slice(i+1, selection.length);
                    break;
                }
            }
        }

        handleGlassTypeChange(firstPart + secondPart, 0);
    }

    function createDiv (index, type, marginPixel, counter) {
        switch (type) {
            case 'G':
                return <div
                    className="w-24 h-[35vh] mb-24 bg-single-glass object-cover bg-no-repeat bg-contain absolute"
                    style={{marginLeft: marginPixel}}
                >
                    <div className="text-sm text-white text-blue-500 absolute bottom-[-10vh] left-0">
                        <h2 className="text-[#0079C1] text-base">ADD</h2>
                        <DropdownInterlayer
                            position="below"
                            menuButton={(
                                <>
                                    <span className="sr-only">Open options</span>
                                    <IconPlus />
                                </>
                            )}
                        >
                            <div className="w-full h-full">
                                <h2 className="text-center text-[#0079C1] mb-2">Number of Interlayers</h2>
                                <div className="flex flex-row justify-evenly">
                                    <button type="submit" onClick={() => addInterlayerToGlass(index, 1)} className="border-solid border border-[#0079C1] rounded-full w-6 h-6 text-[#0079C1]">1</button>
                                    <button type="submit" onClick={() => addInterlayerToGlass(index, 2)} className="border-solid border border-[#0079C1] rounded-full w-6 h-6 text-[#0079C1]">2</button>
                                    <button type="submit" onClick={() => addInterlayerToGlass(index, 3)} className="border-solid border border-[#0079C1] rounded-full w-6 h-6 text-[#0079C1]">3</button>
                                    <button type="submit" onClick={() => addInterlayerToGlass(index, 4)} className="border-solid border border-[#0079C1] rounded-full w-6 h-6 text-[#0079C1]">4</button>
                                    <button type="submit" onClick={() => addInterlayerToGlass(index, 5)} className="border-solid border border-[#0079C1] rounded-full w-6 h-6 text-[#0079C1]">5</button>
                                    <button type="submit" onClick={() => removeInterlayerFromGlass(index)} className="text-[#0079C1]" aria-label="Delete">
                                        <IconTrash />
                                    </button>
                                </div>
                            </div>
                        </DropdownInterlayer>
                    </div>
                    <div className="text-sm text-white text-blue-500 absolute top-[-3vh] left-14">
                        <h2 className="text-[#0079C1] text-base">{type} {counter}</h2>
                    </div>
                </div>
            case 'I':
                return <div
                    style={{marginLeft: marginPixel}}
                    className="w-[57px] h-[35vh] mb-24 bg-interlayer object-cover bg-no-repeat bg-contain absolute"
                >
                    {/* <div className="text-sm text-white text-blue-500 absolute top-[-4vh] left-9">
                        <h2 className="text-[#0079C1] text-xs">{type} {counter}</h2>
                    </div> */}
                </div>
            case 'A':
                return <div
                    style={{marginLeft: marginPixel}}
                    className="w-12 h-72"
                />
            default:
                break;
        }
    }

    function createLabel (text, marginLeftPixel, marginBottomPixel) {
        return <div
            style={{marginLeft: marginLeftPixel, marginBottom: marginBottomPixel}}
            className="w-4 h-8 z-50 text-[#047bc2]"
        >
            {text}
        </div>
    }

    function createDropdown(type, name, id, surfaceIndex, defaultValue, defaultThickness, defaultExtraValue, defaultExtraThickness) {
        let dropdownThickness = '';
        if(type === 'A') {
            switch (defaultThickness){
                case 0.3125:
                    dropdownThickness = '5/16 - 7.5mm';
                    break;
                case 0.375:
                    dropdownThickness = '3/8 - 9mm';
                    break;
                case 0.4375:
                    dropdownThickness = '7/16 - 11mm';
                    break;
                case 0.46875:
                    dropdownThickness = '15/32 - 12mm';
                    break;
                case 0.5:
                    dropdownThickness = '1/2 - 13.2mm';
                    break;
                case 0.5625:
                    dropdownThickness = '9/16 - 14mm';
                    break;
                case 0.625:
                    dropdownThickness = '5/8 - 15.5mm';
                    break;
                case 0.71875:
                    dropdownThickness = '23/32 - 18mm';
                    break;
                case 0.75:
                    dropdownThickness = '3/4 - 18.5mm';
                    break;
                case 0.875:
                    dropdownThickness = '7/8 - 22mm';
                    break;
                case 1:
                    dropdownThickness = '1 - 25.4mm';
                    break;
                default:
                    break;
            }
        } else {
            switch (defaultThickness){
                case .060:
                    dropdownThickness ='.060';
                    break;
                case 0.125:
                    dropdownThickness ='1/8 - 3mm';
                    break;
                case 0.15625:
                    dropdownThickness = '5/32 - 4mm';
                    break;
                case  0.1875:
                    dropdownThickness = '5/32 - 4mm';
                    break;
                case  0.25:
                    dropdownThickness = '1/4 - 6mm';
                    break;
                case 0.3125:
                    dropdownThickness = '5/16 - 8mm';
                    break;
                case 0.375:
                    dropdownThickness = '3/8 - 10mm';
                    break;
                case 0.4375:
                    dropdownThickness = '7/16 - 11mm';
                    break;
                case 0.46875:
                    dropdownThickness = '15/32 - 11.9mm';
                    break;
                case 0.5:
                    dropdownThickness = '1/2 - 12mm';
                    break;
                case 0.5625:
                    dropdownThickness = '9/16 - 14.2mm';
                    break;
                case 0.625:
                    dropdownThickness = '5/8 - 15.5mm';
                    break;
                case 0.71875:
                    dropdownThickness = '23/32 - 18.2mm';
                    break;
                case 0.75:
                    dropdownThickness = '3/4 - 18.5mm';
                    break;
                case 0.875:
                    dropdownThickness = '7/8 - 22.2mm';
                    break;
                case 1:
                    dropdownThickness = '1 - 25.4mm';
                    break;
                default:
                    break;
            }
        }

        switch (type) {
            case 'G':
                // Glass has both value and thickness
                return <SurfaceDropdown defaultValue={defaultValue} defaultThickness={dropdownThickness} defaultOption="Select Glass" selectType="G" title={name} id={id} surfaceIndex={surfaceIndex} handleFunction={setDropdownHandler} handleThicknessFunction={setDropdownThicknessHandler}/>;
            case 'I':
                // Interlayer only has value thickness is always 100
                return <SurfaceDropdown defaultValue={defaultValue} defaultThickness={dropdownThickness} defaultOption="Select Interlayer" selectType="I" title={name} id={id} surfaceIndex={surfaceIndex} handleFunction={setDropdownHandler} handleThicknessFunction={setDropdownThicknessHandler}/>;
            case 'A':
                // Air has both value and thickness
                return <SurfaceDropdown defaultValue={defaultValue} defaultThickness={dropdownThickness} defaultOption="Select Air" selectType="A" title={name} id={id} surfaceIndex={surfaceIndex} handleFunction={setDropdownHandler} handleThicknessFunction={setDropdownThicknessHandler}/>;
            case 'S':
                // Surface has only value but also there might be some cases where there might be extra value and thickness for print
                return <SurfaceDropdown defaultValue={defaultValue}  defaultExtraValue={defaultExtraValue} defaultExtraThickness={defaultExtraThickness} defaultOption="Select Coating" selectType="S" title={name} id={id} surfaceIndex={surfaceIndex} handleFunction={setDropdownHandler} handleThicknessFunction={setDropdownThicknessHandler} extraHandleFunction={setExtraDropdownHandler} extraHandleThicknessFunction={setExtraDropdownThicknessHandler} />
            default:
                break;
        }
    }

    function createConfiguratorVisual(selection) {
        let absoluteMarginPixel = 0;
        let counter = 0;
        let layerIndex = 0;
        const newBottomItems = [];
        let glassCounter = 1;
        let interlayerCounter = 1;
        let airCounter = 1;

        selection.split("").forEach(character => {
            if(counter !== 0) {
                switch (character) {
                    case 'G':
                        absoluteMarginPixel += 95;
                        break;
                    case 'A':
                        absoluteMarginPixel += 80;
                        break;
                    case 'I':
                        absoluteMarginPixel += 57;
                        break;
                    default:
                        break;
                }

                switch (selection[counter-1]){
                    case 'G':
                        // console.log('Before Glass');
                        break;
                    case 'A':
                        // console.log('Before Air');
                        break;
                    case 'I':
                        switch (character) {
                            case 'I':
                                absoluteMarginPixel += -38;
                                break;
                            case 'G':
                                absoluteMarginPixel += -35;
                                break;
                            default:
                                break;
                        }
                        // console.log('Before Interlayer');
                        break;
                    default:
                        break;
                }

            }

            setCustomDivCenter(absoluteMarginPixel);

            switch (character) {
                case 'G':
                    newBottomItems.push(createDiv(layerIndex, 'G', absoluteMarginPixel, glassCounter));
                    layerIndex += 1;
                    counter += 1;
                    glassCounter += 1;
                    break;
                case 'A':
                    newBottomItems.push(createDiv(layerIndex, 'A', absoluteMarginPixel, airCounter));
                    layerIndex += 1;
                    counter += 1;
                    airCounter += 1;
                    break;
                case 'I':
                    newBottomItems.push(createDiv(layerIndex, 'I', absoluteMarginPixel, interlayerCounter));
                    layerIndex += 1;
                    counter += 1;
                    interlayerCounter += 1;
                    break;
                default:
                    break;
            }
        });
        setCustomDiv(newBottomItems);
    }

    async function createDropdownAssignSurfaces(selection) {
        let assignSurfaceIndex = 0;
        let id = 0;
        let airSurfaceCounter = 1;
        let surfaceCounter = 1;
        let interlayerCounter = 1;
        let glassCounter = 1;
        const tmpAssignGlassListInner = [];
        const tmpAssignGlassListMiddle = [];
        const tmpAssignGlassListOuter = [];

        const tmpAssignGlassListInnerHistory = [];
        const tmpAssignGlassListMiddleHistory = [];
        const tmpAssignGlassListOuterHistory = [];
        let tmpLayerLabel = '';

        function getDefaultValue(type) {
            switch (type) {
                case 'A':
                    return 'Argon'
                case 'G':
                    return 'Clear';
                case 'I':
                    return 'Clear PVB';
                case 'S':
                    return 'No Coating';
                default:
                    return '';
            }
        }

        function getDefaultThickness(type) {
            switch (type) {
                case 'A':
                    return 0.5;
                case 'G':
                    return 0.25;
                case 'I':
                    return .060;
                case 'S':
                    return '';
                default:
                    return '';
            }
        }

        function getDefaultExtraValue (type) {
            switch (type) {
                case 'S':
                    return 'No Print';
                default:
                    return '';
            }
        }

        function assignSurfaceHistory(item, type, surfaceIndex) {
            switch (assignSurfaceIndex) {
                case 0:
                    if(type === 'A') {
                        tmpAssignGlassListOuterHistory.push({
                            name: item,
                            surfaceId: surfaceIndex,
                            surfaceType: type,
                            value: assignGlassListOuter[assignGlassListOuter.length-1] !== undefined ? assignGlassListOuter[assignGlassListOuter.length-1].value : getDefaultValue(type),
                            extraValue: '',
                            thickness: assignGlassListOuter[assignGlassListOuter.length-1] !== undefined ? assignGlassListOuter[assignGlassListOuter.length-1].thickness : getDefaultThickness(type),
                            extraThickness: '',
                            orientation: '',
                            isPrint: false,
                            isExtra: false,
                            html: ''
                        });
                    } else {
                        tmpAssignGlassListOuterHistory.push({
                            name: item,
                            surfaceId: surfaceIndex,
                            surfaceType: type,
                            value: assignGlassListOuter.length > surfaceIndex && assignGlassListOuter[surfaceIndex].surfaceType === type ? assignGlassListOuter[surfaceIndex].value : getDefaultValue(type),
                            extraValue: assignGlassListOuter.length > surfaceIndex && assignGlassListOuter[surfaceIndex].surfaceType === type ? assignGlassListOuter[surfaceIndex].extraValue : getDefaultExtraValue(type),
                            thickness: assignGlassListOuter.length > surfaceIndex && assignGlassListOuter[surfaceIndex].surfaceType === type ? assignGlassListOuter[surfaceIndex].thickness : getDefaultThickness(type),
                            extraThickness: assignGlassListOuter.length > surfaceIndex && assignGlassListOuter[surfaceIndex].surfaceType === type ? assignGlassListOuter[surfaceIndex].extraThickness : '',
                            orientation: assignGlassListOuter.length > surfaceIndex && assignGlassListOuter[surfaceIndex].surfaceType === type ? assignGlassListOuter[surfaceIndex].orientation : '',
                            isPrint: assignGlassListOuter.length > surfaceIndex && assignGlassListOuter[surfaceIndex].surfaceType === type ? assignGlassListOuter[surfaceIndex].isPrint : false,
                            isExtra: assignGlassListOuter.length > surfaceIndex && assignGlassListOuter[surfaceIndex].surfaceType === type ? assignGlassListOuter[surfaceIndex].isExtra : false,
                            html: ''
                        });
                    }
                    break;
                case 1:
                    if(type === 'A') {
                        tmpAssignGlassListMiddleHistory.push({
                            name: item,
                            surfaceId: surfaceIndex,
                            surfaceType: type,
                            value: assignGlassListMiddle[assignGlassListMiddle.length-1] !== undefined ? assignGlassListMiddle[assignGlassListMiddle.length-1].value : getDefaultValue(type),
                            extraValue: '',
                            thickness: assignGlassListMiddle[assignGlassListMiddle.length-1] !== undefined ? assignGlassListMiddle[assignGlassListMiddle.length-1].thickness : getDefaultThickness(type),
                            extraThickness: '',
                            orientation: '',
                            isPrint: false,
                            isExtra: false,
                            html: ''
                        });
                    } else {
                        tmpAssignGlassListMiddleHistory.push({
                            name: item,
                            surfaceId: surfaceIndex,
                            surfaceType: type,
                            value: assignGlassListMiddle.length > surfaceIndex - tmpAssignGlassListOuterHistory.length && assignGlassListMiddle[surfaceIndex - tmpAssignGlassListOuterHistory.length].surfaceType === type ? assignGlassListMiddle[surfaceIndex - tmpAssignGlassListOuterHistory.length].value : getDefaultValue(type),
                            extraValue: assignGlassListMiddle.length > surfaceIndex - tmpAssignGlassListOuterHistory.length && assignGlassListMiddle[surfaceIndex - tmpAssignGlassListOuterHistory.length].surfaceType === type ? assignGlassListMiddle[surfaceIndex - tmpAssignGlassListOuterHistory.length].extraValue : getDefaultExtraValue(type),
                            thickness: assignGlassListMiddle.length > surfaceIndex - tmpAssignGlassListOuterHistory.length && assignGlassListMiddle[surfaceIndex - tmpAssignGlassListOuterHistory.length].surfaceType === type ? assignGlassListMiddle[surfaceIndex - tmpAssignGlassListOuterHistory.length].thickness : getDefaultThickness(type),
                            extraThickness: assignGlassListMiddle.length > surfaceIndex - tmpAssignGlassListOuterHistory.length && assignGlassListMiddle[surfaceIndex - tmpAssignGlassListOuterHistory.length].surfaceType === type ? assignGlassListMiddle[surfaceIndex - tmpAssignGlassListOuterHistory.length].extraThickness : '',
                            orientation: assignGlassListMiddle.length > surfaceIndex - tmpAssignGlassListOuterHistory.length && assignGlassListMiddle[surfaceIndex - tmpAssignGlassListOuterHistory.length].surfaceType === type ? assignGlassListMiddle[surfaceIndex - tmpAssignGlassListOuterHistory.length].orientation : '',
                            isPrint: assignGlassListMiddle.length > surfaceIndex - tmpAssignGlassListOuterHistory.length && assignGlassListMiddle[surfaceIndex - tmpAssignGlassListOuterHistory.length].surfaceType === type ? assignGlassListMiddle[surfaceIndex - tmpAssignGlassListOuterHistory.length].isPrint : false,
                            isExtra: assignGlassListMiddle.length > surfaceIndex - tmpAssignGlassListOuterHistory.length && assignGlassListMiddle[surfaceIndex - tmpAssignGlassListOuterHistory.length].surfaceType === type ? assignGlassListMiddle[surfaceIndex - tmpAssignGlassListOuterHistory.length].isExtra : false,
                            html: ''
                        });
                    }
                    break;
                case 2:
                    tmpAssignGlassListInnerHistory.push({
                        name: item,
                        surfaceId: surfaceIndex,
                        surfaceType: type,
                        value: assignGlassListInner.length > surfaceIndex - tmpAssignGlassListMiddleHistory.length - tmpAssignGlassListOuterHistory.length ? assignGlassListInner[surfaceIndex - tmpAssignGlassListMiddleHistory.length - tmpAssignGlassListOuterHistory.length].value : getDefaultValue(type),
                        extraValue: assignGlassListInner.length > surfaceIndex - tmpAssignGlassListMiddleHistory.length - tmpAssignGlassListOuterHistory.length ? assignGlassListInner[surfaceIndex - tmpAssignGlassListMiddleHistory.length - tmpAssignGlassListOuterHistory.length].extraValue : getDefaultExtraValue(type),
                        thickness: assignGlassListInner.length > surfaceIndex - tmpAssignGlassListMiddleHistory.length - tmpAssignGlassListOuterHistory.length ? assignGlassListInner[surfaceIndex - tmpAssignGlassListMiddleHistory.length - tmpAssignGlassListOuterHistory.length].thickness : getDefaultThickness(type),
                        extraThickness: assignGlassListInner.length > surfaceIndex - tmpAssignGlassListMiddleHistory.length - tmpAssignGlassListOuterHistory.length ? assignGlassListInner[surfaceIndex - tmpAssignGlassListMiddleHistory.length - tmpAssignGlassListOuterHistory.length].extraThickness : '',
                        orientation: assignGlassListInner.length > surfaceIndex - tmpAssignGlassListMiddleHistory.length - tmpAssignGlassListOuterHistory.length ? assignGlassListInner[surfaceIndex - tmpAssignGlassListMiddleHistory.length - tmpAssignGlassListOuterHistory.length].orientation : '',
                        isPrint: assignGlassListInner.length > surfaceIndex - tmpAssignGlassListMiddleHistory.length - tmpAssignGlassListOuterHistory.length ? assignGlassListInner[surfaceIndex - tmpAssignGlassListMiddleHistory.length - tmpAssignGlassListOuterHistory.length].isPrint : false,
                        isExtra: assignGlassListInner.length > surfaceIndex - tmpAssignGlassListMiddleHistory.length - tmpAssignGlassListOuterHistory.length ? assignGlassListInner[surfaceIndex - tmpAssignGlassListMiddleHistory.length - tmpAssignGlassListOuterHistory.length].isPrint : false,
                        html: ''
                    });
                    break;
                default:
                    break;
            }
        }

        await selection.split("").forEach((character, index) => {
            if (index === 0) {
                // eslint-disable-line prefer-template
                // console.log(`"left surface created for --> " ${character} " for index --> " ${index}`);
                assignSurfaceHistory(`Surface ${surfaceCounter}`, 'S', id);
                surfaceCounter += 1;
                id += 1;
                tmpLayerLabel += 'S';
                // console.log(`"Glass surface created for --> " ${character} " for index --> " ${index}`);
                assignSurfaceHistory(`Glass ${glassCounter}`, 'G', id);
                glassCounter += 1;
                id += 1;
                tmpLayerLabel += 'G';
                if (selection.length > 1) {
                    switch (selection[1]) {
                        case 'A':
                            // console.log(`"right surface created for --> " ${character} " for index --> " ${index}`);
                            assignSurfaceHistory(`Surface ${surfaceCounter}`, 'S', id);
                            surfaceCounter += 1;
                            id += 1;
                            tmpLayerLabel += 'S';
                            break;
                        case 'I':
                            break;
                        default:
                            break;
                    }
                } else {
                    // console.log(`"right surface created for --> " ${character} " for index --> " ${index}`);
                    assignSurfaceHistory(`Surface ${surfaceCounter}`, 'S', id);
                    surfaceCounter += 1;
                    id += 1;
                    tmpLayerLabel += 'S';
                }
            } else {
                switch (selection[index]) {
                    case 'A':
                        // console.log(`"Air surface created for --> " ${character} " for index --> " ${index}`);
                        assignSurfaceHistory(`Air Space ${airSurfaceCounter}`, 'A', id);
                        id += 1;
                        assignSurfaceIndex += 1;
                        airSurfaceCounter += 1;
                        tmpLayerLabel += 'A';
                        break;
                    case 'I':
                        // Add another surface before 'I'
                        // it will not create a surface between two interlayer
                        if (selection[index - 1] !== 'I') {
                            assignSurfaceHistory(`Surface ${surfaceCounter}`, 'S', id);
                            surfaceCounter += 1;
                            id += 1;
                        }
                        // console.log(`"Interlayer surface created for --> " ${character} " for index --> " ${index}`);
                        assignSurfaceHistory(`Interlayer ${interlayerCounter}`, 'I', id);
                        interlayerCounter += 1;
                        id += 1;
                        tmpLayerLabel += 'I';
                        break;
                    case 'G':
                        // Add another surface before 'G' if it is 'I'
                        if (selection.length > index && selection[index - 1] === 'I') {
                            assignSurfaceHistory(`Surface ${surfaceCounter}`, 'S', id);
                            surfaceCounter += 1;
                            id += 1;
                        }

                        if (selection.length > index && selection.length !== index + 1) {
                            switch (selection[index - 1]) {
                                case 'A':
                                    // console.log(`"left surface created for --> " ${character} " for index --> " ${index}`);
                                    assignSurfaceHistory(`Surface ${surfaceCounter}`, 'S', id);
                                    surfaceCounter += 1;
                                    id += 1;
                                    tmpLayerLabel += 'S';
                                    break;
                                case 'I':
                                    break;
                                default:
                                    break;
                            }
                        }

                        if (selection.length > index && selection.length === index + 1 && selection[index - 1] !== 'I') {
                            // console.log(`"right surface created for --> " ${character} " for index --> " ${index}`);
                            assignSurfaceHistory(`Surface ${surfaceCounter}`, 'S', id);
                            surfaceCounter += 1;
                            id += 1;
                            tmpLayerLabel += 'S';
                        }

                        // console.log(`"Glass surface created for --> " ${character} " for index --> " ${index}`);
                        assignSurfaceHistory(`Glass ${glassCounter}`, 'G', id);
                        glassCounter += 1;
                        id += 1;
                        tmpLayerLabel += 'G';

                        if (selection.length > index) {
                            if (selection.length === index + 1) {
                                if (selection[index - 1] === 'I') {
                                    // console.log(`"right surface created for --> " ${character} " for index --> " ${index}`);
                                    assignSurfaceHistory(`Surface ${surfaceCounter}`, 'S', id);
                                    surfaceCounter += 1;
                                    id += 1;
                                    tmpLayerLabel += 'S';
                                }
                                switch (selection[index - 1]) {
                                    case 'A':
                                        // console.log(`"left surface created for --> " ${character} " for index --> " ${index}`);
                                        assignSurfaceHistory(`Surface ${surfaceCounter}`, 'S', id);
                                        surfaceCounter += 1;
                                        id += 1;
                                        tmpLayerLabel += 'S';
                                        break;
                                    case 'I':
                                        break;
                                    default:
                                        break;
                                }
                            } else {
                                switch (selection[index + 1]) {
                                    case 'A':
                                        // console.log(`"right surface created for --> " ${character} " for index --> " ${index}`);
                                        assignSurfaceHistory(`Surface ${surfaceCounter}`, 'S', id);
                                        surfaceCounter += 1;
                                        id += 1;
                                        tmpLayerLabel += 'S';
                                        break;
                                    case 'I':
                                        break;
                                    default:
                                        break;
                                }
                            }
                        }
                        break;
                    default:
                        break;
                }
            }
        });

        assignSurfaceIndex = 0;
        id = 0;
        airSurfaceCounter = 1;
        surfaceCounter = 1;
        interlayerCounter = 1;
        glassCounter = 1;

        /* console.log('History');
        console.log(tmpAssignGlassListOuterHistory);
        console.log(tmpAssignGlassListMiddleHistory);
        console.log(tmpAssignGlassListInnerHistory);

        console.log('Real');
        console.log(assignGlassListOuter);
        console.log(assignGlassListMiddle);
        console.log(assignGlassListInner); */

        function assignSurface(item, type, surfaceIndex)  {
            let value = '';
            let extraValue = '';
            let thickness = '';
            let extraThickness = '';
            switch (assignSurfaceIndex) {
                case 0: {
                    if (tmpAssignGlassListOuterHistory.length > surfaceIndex) {
                        const surface = tmpAssignGlassListOuterHistory[surfaceIndex];
                        value = surface.value;
                        extraValue = surface.extraValue;
                        thickness = surface.thickness;
                        extraThickness = surface.extraThickness;
                    }
                    tmpAssignGlassListOuter.push({
                        name: item,
                        surfaceId: surfaceIndex,
                        surfaceType: type,
                        value,
                        thickness,
                        extraValue,
                        extraThickness,
                        orientation: tmpAssignGlassListOuterHistory.length > surfaceIndex ? tmpAssignGlassListOuterHistory[surfaceIndex].orientation : '',
                        isPrint: tmpAssignGlassListOuterHistory.length > surfaceIndex ? tmpAssignGlassListOuterHistory[surfaceIndex].isPrint : false,
                        isExtra: tmpAssignGlassListOuterHistory.length > surfaceIndex ? tmpAssignGlassListOuterHistory[surfaceIndex].isExtra : false,
                        html: createDropdown(type, item, surfaceIndex, 0, value, thickness, extraValue)
                    });
                    break;
                }
                case 1: {
                    if (tmpAssignGlassListMiddleHistory.length > surfaceIndex - tmpAssignGlassListOuterHistory.length) {
                        const surface = tmpAssignGlassListMiddleHistory[surfaceIndex - tmpAssignGlassListOuterHistory.length];
                        value = surface.value;
                        extraValue = surface.extraValue;
                        thickness = surface.thickness;
                        extraThickness = surface.extraThickness;
                    }
                    tmpAssignGlassListMiddle.push({
                        name: item,
                        surfaceId: surfaceIndex,
                        surfaceType: type,
                        value,
                        thickness,
                        extraValue,
                        extraThickness,
                        orientation: tmpAssignGlassListMiddleHistory.length > surfaceIndex - tmpAssignGlassListOuterHistory.length ? tmpAssignGlassListMiddleHistory[surfaceIndex - tmpAssignGlassListOuterHistory.length].orientation : '',
                        isPrint: tmpAssignGlassListMiddleHistory.length > surfaceIndex - tmpAssignGlassListOuterHistory.length ? tmpAssignGlassListMiddleHistory[surfaceIndex - tmpAssignGlassListOuterHistory.length].value : false,
                        isExtra: tmpAssignGlassListMiddleHistory.length > surfaceIndex - tmpAssignGlassListOuterHistory.length ? tmpAssignGlassListMiddleHistory[surfaceIndex - tmpAssignGlassListOuterHistory.length].value : false,
                        html: createDropdown(type, item, surfaceIndex, 1, value, thickness, extraValue)
                    });
                    break;
                }
                case 2:
                    if (tmpAssignGlassListInnerHistory.length > surfaceIndex - tmpAssignGlassListMiddleHistory.length - tmpAssignGlassListOuterHistory.length) {
                        const surface = tmpAssignGlassListInnerHistory[surfaceIndex - tmpAssignGlassListMiddleHistory.length - tmpAssignGlassListOuterHistory.length];
                        value = surface.value;
                        extraValue = surface.extraValue;
                        thickness = surface.thickness;
                        extraThickness = surface.extraThickness;
                    }
                    tmpAssignGlassListInner.push({
                        name: item,
                        surfaceId: surfaceIndex,
                        surfaceType: type,
                        value,
                        thickness,
                        extraValue,
                        extraThickness,
                        orientation: tmpAssignGlassListInnerHistory.length > surfaceIndex - tmpAssignGlassListMiddleHistory.length - tmpAssignGlassListOuterHistory.length ? tmpAssignGlassListInnerHistory[surfaceIndex - tmpAssignGlassListMiddleHistory.length - tmpAssignGlassListOuterHistory.length].orientation : '',
                        isPrint: tmpAssignGlassListInnerHistory.length > surfaceIndex - tmpAssignGlassListMiddleHistory.length - tmpAssignGlassListOuterHistory.length ? tmpAssignGlassListInnerHistory[surfaceIndex - tmpAssignGlassListMiddleHistory.length - tmpAssignGlassListOuterHistory.length].isPrint : false,
                        isExtra: tmpAssignGlassListInnerHistory.length > surfaceIndex - tmpAssignGlassListMiddleHistory.length - tmpAssignGlassListOuterHistory.length ? tmpAssignGlassListInnerHistory[surfaceIndex - tmpAssignGlassListMiddleHistory.length - tmpAssignGlassListOuterHistory.length].isExtra : false,
                        html: createDropdown(type, item, surfaceIndex, 2, value, thickness, extraValue)
                    });
                    break;
                default:
                    break;
            }
        }

        selection.split("").forEach((character, index) => {
            if (index === 0) {
                // eslint-disable-line prefer-template
                // console.log(`"left surface created for --> " ${character} " for index --> " ${index}`);
                assignSurface(`Surface ${surfaceCounter}`, 'S', id);
                surfaceCounter += 1;
                id += 1;
                tmpLayerLabel += 'S';
                // console.log(`"Glass surface created for --> " ${character} " for index --> " ${index}`);
                assignSurface(`Glass ${glassCounter}`, 'G', id);
                glassCounter += 1;
                id += 1;
                tmpLayerLabel += 'G';
                if (selection.length > 1) {
                    switch (selection[1]) {
                        case 'A':
                            // console.log(`"right surface created for --> " ${character} " for index --> " ${index}`);
                            assignSurface(`Surface ${surfaceCounter}`, 'S', id);
                            surfaceCounter += 1;
                            id += 1;
                            tmpLayerLabel += 'S';
                            break;
                        case 'I':
                            break;
                        default:
                            break;
                    }
                } else {
                    // console.log(`"right surface created for --> " ${character} " for index --> " ${index}`);
                    assignSurface(`Surface ${surfaceCounter}`, 'S', id);
                    surfaceCounter += 1;
                    id += 1;
                    tmpLayerLabel += 'S';
                }
            } else {
                switch (selection[index]) {
                    case 'A':
                        // console.log(`"Air surface created for --> " ${character} " for index --> " ${index}`);
                        assignSurface(`Air Space ${airSurfaceCounter}`, 'A', id);
                        id += 1;
                        assignSurfaceIndex += 1;
                        airSurfaceCounter += 1;
                        tmpLayerLabel += 'A';
                        break;
                    case 'I':
                        // Add another surface before 'I'
                        // it will not create a surface between two interlayer
                        if (selection[index - 1] !== 'I') {
                            assignSurface(`Surface ${surfaceCounter}`, 'S', id);
                            surfaceCounter += 1;
                            id += 1;
                        }
                        // console.log(`"Interlayer surface created for --> " ${character} " for index --> " ${index}`);
                        assignSurface(`Interlayer ${interlayerCounter}`, 'I', id);
                        interlayerCounter += 1;
                        id += 1;
                        tmpLayerLabel += 'I';
                        break;
                    case 'G':
                        // Add another surface before 'G' if it is 'I'
                        if (selection.length > index && selection[index - 1] === 'I') {
                            assignSurface(`Surface ${surfaceCounter}`, 'S', id);
                            surfaceCounter += 1;
                            id += 1;
                        }

                        if (selection.length > index && selection.length !== index + 1) {
                            switch (selection[index - 1]) {
                                case 'A':
                                    // console.log(`"left surface created for --> " ${character} " for index --> " ${index}`);
                                    assignSurface(`Surface ${surfaceCounter}`, 'S', id);
                                    surfaceCounter += 1;
                                    id += 1;
                                    tmpLayerLabel += 'S';
                                    break;
                                case 'I':
                                    break;
                                default:
                                    break;
                            }
                        }

                        if (selection.length > index && selection.length === index + 1 && selection[index - 1] !== 'I') {
                            // console.log(`"right surface created for --> " ${character} " for index --> " ${index}`);
                            assignSurface(`Surface ${surfaceCounter}`, 'S', id);
                            surfaceCounter += 1;
                            id += 1;
                            tmpLayerLabel += 'S';
                        }

                        // console.log(`"Glass surface created for --> " ${character} " for index --> " ${index}`);
                        assignSurface(`Glass ${glassCounter}`, 'G', id);
                        glassCounter += 1;
                        id += 1;
                        tmpLayerLabel += 'G';

                        if (selection.length > index) {
                            if (selection.length === index + 1) {
                                if (selection[index - 1] === 'I') {
                                    // console.log(`"right surface created for --> " ${character} " for index --> " ${index}`);
                                    assignSurface(`Surface ${surfaceCounter}`, 'S', id);
                                    surfaceCounter += 1;
                                    id += 1;
                                    tmpLayerLabel += 'S';
                                }
                                switch (selection[index - 1]) {
                                    case 'A':
                                        // console.log(`"left surface created for --> " ${character} " for index --> " ${index}`);
                                        assignSurface(`Surface ${surfaceCounter}`, 'S', id);
                                        surfaceCounter += 1;
                                        id += 1;
                                        tmpLayerLabel += 'S';
                                        break;
                                    case 'I':
                                        break;
                                    default:
                                        break;
                                }
                            } else {
                                switch (selection[index + 1]) {
                                    case 'A':
                                        // console.log(`"right surface created for --> " ${character} " for index --> " ${index}`);
                                        assignSurface(`Surface ${surfaceCounter}`, 'S', id);
                                        surfaceCounter += 1;
                                        id += 1;
                                        tmpLayerLabel += 'S';
                                        break;
                                    case 'I':
                                        break;
                                    default:
                                        break;
                                }
                            }
                        }
                        break;
                    default:
                        break;
                }
            }
        });

        const generateDescription = (list, startCounter) => {
            let description = '( ';
            let counter = startCounter;
            list.forEach(item => {
                if (item.surfaceType === 'G') {
                    description += `G${counter} `;
                    counter += 1;
                }
            });
            description += ')';
            return { description, counter };
        }

        let counter = 1;

        const resultOuter = generateDescription(tmpAssignGlassListOuter, counter);
        setComponent1Description(resultOuter.description);
        counter = resultOuter.counter;

        const resultMiddle = generateDescription(tmpAssignGlassListMiddle, counter);
        setComponent2Description(resultMiddle.description);
        counter = resultMiddle.counter;

        const resultInner = generateDescription(tmpAssignGlassListInner, counter);
        setComponent3Description(resultInner.description);

        setAssignGlassListInner(tmpAssignGlassListInner);
        setAssignGlassListMiddle(tmpAssignGlassListMiddle);
        setAssignGlassListOuter(tmpAssignGlassListOuter);
        setLayerLabel(tmpLayerLabel);
    }

    useEffect(() => {
        const selection = selectedGlassType;
        createConfiguratorVisual(selection);
        createDropdownAssignSurfaces(selection);
    }, [glassLayerList])

    useEffect(() => {
        const tmpCustomLayerDiv = [];
        let absoluteMarginBottom = 200;
        let counter = 0;
        let glassCounter = 1;
        let airCounter = 1;
        let surfaceCounter = 1;
        const selection = layerLabel;

        selection.split("").forEach(character => {
            let absoluteMarginLeft = 0;
            if(counter !== 0) {
                switch (character) {
                    case 'G':
                        // console.log('HERE G');
                        absoluteMarginLeft += 10;
                        break;
                    case 'A':
                        // console.log('HERE A');
                        absoluteMarginLeft += 0;
                        break;
                    case 'I':
                        // console.log('HERE I');
                        absoluteMarginLeft += 0;
                        break;
                    case 'S':
                        // console.log('HERE S');
                        absoluteMarginLeft += 45;
                        break;
                    default:
                        break;
                }

                switch (selection[counter - 1]) {
                    case 'G':
                        // console.log('Before Glass');
                        break;
                    case 'A':
                        // console.log('Before Air');
                        break;
                    case 'I':
                        switch (character) {
                            case 'I':
                                absoluteMarginLeft += 0;
                                break;
                            case 'G':
                                absoluteMarginLeft += 0;
                                break;
                            default:
                                break;
                        }
                        // console.log('Before Interlayer');
                        break;
                    default:
                        break;
                }
            }

            // console.log(absoluteMarginLeft);

            switch (character) {
                case 'G':
                    tmpCustomLayerDiv.push(createLabel(`G${glassCounter}`, absoluteMarginLeft, absoluteMarginBottom));
                    glassCounter += 1;
                    counter += 1;
                    break;
                case 'A':
                    tmpCustomLayerDiv.push(createLabel(`A${airCounter}`, absoluteMarginLeft, absoluteMarginBottom));
                    airCounter += 1;
                    counter += 1;
                    if(absoluteMarginBottom === 200 ) {
                        absoluteMarginBottom = -150;
                    } else {
                        absoluteMarginBottom = 200;
                    }
                    break;
                case 'I':
                    counter += 1;
                    break;
                case 'S':
                    tmpCustomLayerDiv.push(createLabel(`S${surfaceCounter}`, absoluteMarginLeft, absoluteMarginBottom));
                    surfaceCounter += 1;
                    counter += 1;
                    break;
                default:
                    break;
            }
            setCustomLabelDivCenter(absoluteMarginLeft + (counter * 16));
        });
        setCustomLayerLabelDiv(tmpCustomLayerDiv);
    }, [layerLabel])

    async function addSearchItem() {
        // checking rule loading initiated
        setIsCheckingRule(true);
        let counter = 0;

        selectedGlassType.split("").forEach((item) => {
            if (item === 'A') {
                counter += 1;
            }
        });

        let familyType = '';
        switch (counter) {
            case 0:
                familyType = 'Single';
                break;
            case 1:
                familyType = 'Double';
                break;
            case 2:
                familyType = 'Triple';
                break;
            default:
                break;
        }

        let description = '';
        let thickness = 0;
        let errorFlag = false;

        assignGlassListOuter.forEach((item) => {

            if(item.surfaceType === 'S'){
                description += `${item.value}  | `;
                if(item.extraValue !== 'All Print' && item.extraValue !== 'No Print') {
                    description += `${item.extraThickness}%  ${item.extraValue}  | `;
                } else {
                    description += `${item.extraValue}  | `;
                }
            } else {
                description += `${item.thickness}  ${item.value}  | `;
            }

            console.log(item.surfaceType);
            if (item.surfaceType === 'G' || item.surfaceType === 'I' || item.surfaceType === 'A') {
                thickness += Number(item.thickness);
                if (item.thickness === '' && item.value !== '') {
                    errorFlag = true;
                }
            }
        })

        assignGlassListMiddle.forEach((item) => {

            if(item.surfaceType === 'S'){
                description += `${item.value}  | `;
                if(item.extraValue !== 'All Print' || item.extraValue !== 'No Print' ) {
                    description += `${item.extraThickness}%  ${item.extraValue}  | `;
                } else {
                    description += `${item.extraValue}  | `;
                }
            } else {
                description += `${item.thickness}  ${item.value}  | `;
            }

            if (item.surfaceType === 'G' || item.surfaceType === 'I' || item.surfaceType === 'A') {
                thickness += Number(item.thickness);
                if (item.thickness === '' && item.value !== '') {
                    errorFlag = true;
                }
            }
        })

        assignGlassListInner.forEach((item) => {

            if(item.surfaceType === 'S'){
                description += `${item.value}  | `;
                if(item.extraValue !== 'All Print' || item.extraValue !== 'No Print' ) {
                    description += `${item.extraThickness}%  ${item.extraValue}  | `;
                } else {
                    description += `${item.extraValue}  | `;
                }
            } else {
                description += `${item.thickness}  ${item.value}  | `;
            }

            description += `${item.value}  | `;
            if (item.surfaceType === 'G' || item.surfaceType === 'I' || item.surfaceType === 'A') {
                thickness += Number(item.thickness);
                if (item.thickness === '' && item.value !== '') {
                    errorFlag = true;
                }
            }
        })

        if (errorFlag) {
            toast.error('Missing thickness details!');
        } else {
            // eslint-disable-next-line no-case-declarations
            const tmpRequestObject = [...requestObject];

            const newTmpRequestObject = {
                selectedGlassType,
                assignGlassListInner: assignGlassListInner.map(obj => ({...obj})),
                assignGlassListMiddle: assignGlassListMiddle.map(obj => ({...obj})),
                assignGlassListOuter: assignGlassListOuter.map(obj => ({...obj})),
                isCoatingExist: isNoCoating,
                isPrintExist: isNoSilkscreen,
            }

            // TODO need to do rule check in here
            const ruleCheck = await trigger({newTmpRequestObject});

            // eslint-disable-next-line dot-notation
            if(!ruleCheck.data['_rule_check_passed']) {
                toast.error('Please check Configuration Error');
            } else {
                // eslint-disable-next-line no-case-declarations
                const tmpSearchItem = [...searchItems];
                tmpSearchItem.push({
                    familyType,
                    description,
                    thickness,
                });
                setSearchItems(tmpSearchItem);

                tmpRequestObject.push(newTmpRequestObject);
                setRequestObject(tmpRequestObject);
                toast.success('New Glazing Configuration Added');
                setIsNoCoating(false);
                setIsNoSilkscreen(false);
                setRuleError([])
            }
        }
    }

    function deleteSearchItem(index) {
        // eslint-disable-next-line no-case-declarations
        const tmpSearchItem = [...searchItems];
        // eslint-disable-next-line no-case-declarations
        const tmpRequestObject = [...requestObject]

        tmpSearchItem.splice(index, 1);
        tmpRequestObject.splice(index, 1);
        setSearchItems(tmpSearchItem);
        setRequestObject(tmpRequestObject);
        toast.success('Glazing Configuration Removed From Search Item!');
    }

    function startOver() {
        setSelectedGlassType('');
        setAssignGlassListInner([]);
        setAssignGlassListMiddle([]);
        setAssignGlassListOuter([]);
        setIsNoCoating(false);
        setIsNoSilkscreen(false);
        setIsOuterOpen(false);
        setIsOuterAirOpen(false);
        setIsMiddleOpen(false);
        setIsMiddleAirOpen(false);
        setIsInnerOpen(false);
        setRuleError([]);
    }

    function resetGlassSpecs() {
        handleGlassTypeChange(selectedGlassType);
        setIsNoCoating(false);
        setIsNoSilkscreen(false);
        setRuleError([]);
    }

    const reportModalOpenHandler = () => {
        setIsReportModalOpen(true);
    }

    return (
        <section className="h-[calc(100vh_-_2rem)] w-full bg-transparent p-4 rounded">
            <div className="flex flex-row h-full">
                <div className="flex flex-col w-4/6 h-full">
                    <div className="h-4/6 flex flex-row">
                        <div className="w-full flex flex-col p-2 bg-white">
                            <div className="flex flex-row justify-between">
                                <h2 className="font-bold text-[#047bc2]">Select Glazing Configuration</h2>
                                <button
                                    type="submit"
                                    aria-label="Refresh Glass Type"
                                    onClick={() => startOver()}
                                >
                                    <IconRefresh />
                                </button>
                            </div>
                            <hr className="h-px my-1 bg-[#f2f4f6] border-2"/>
                            <div className="flex flex-col h-full w-full bg-[#e0e9f6] pt-2 pl-4 pr-4">
                                {selectedGlassType !== '' &&
                                    <div className="flex flex-row justify-between">
                                        <div className="flex flex-row">
                                            <img src="/logos/sun.svg" className="w-12 h-12" alt="Exterior" />
                                            <h2 className="p-2 text-[#7bacdc] font-medium">Exterior</h2>
                                        </div>
                                        <div className="flex flex-row">
                                            <h2 className="p-2 text-[#7bacdc] font-medium">Interior</h2>
                                            <img src="/logos/lamp.svg" className="w-12 h-12" alt="Interior" />
                                        </div>
                                    </div>
                                }
                                <div className="flex flex-row justify-center items-center h-full w-full">
                                    {selectedGlassType !== '' &&
                                        <>
                                            <div className="flex flex-row justify-center items-center mt-4 absolute" style={{marginRight: customDivCenter/2}}>
                                                {customDiv}
                                            </div>
                                            {/* <div className="flex flex-row justify-center items-center h-8 absolute" style={{marginRight: customLabelDivCenter/2}}>
                                                        {customLabelDivCenter}
                                                        {customLayerLabelDiv}
                                                    </div> */}
                                        </>
                                    }
                                    {selectedGlassType === '' && (
                                        <>
                                            <button
                                                type="submit"
                                                onClick={() => handleGlassTypeChange('G')}
                                                className="flex flex-col w-2/6 h-4/6 justify-center items-center bg-transparent m-4 rounded">
                                                <div className="w-full h-full bg-center bg-single-selection object-cover bg-no-repeat bg-contain" />
                                                <h2 className="font-bold text-[#047bc2]">Single Glazing</h2>
                                            </button>
                                            <button
                                                type="submit"
                                                onClick={() => handleGlassTypeChange('GAG')}
                                                className="flex flex-col w-2/6 h-4/6 justify-center items-center bg-transparent m-4 rounded">
                                                <div className="w-full h-full bg-center bg-double-selection object-cover bg-no-repeat bg-contain" />
                                                <h2 className="font-bold text-[#047bc2]">Double Glazing</h2>
                                            </button>
                                            <button
                                                type="submit"
                                                onClick={() => handleGlassTypeChange('GAGAG')}
                                                className="flex flex-col w-2/6 h-4/6 justify-center items-center bg-transparent m-4 rounded">
                                                <div className="w-full h-full bg-center bg-triple-selection object-cover bg-no-repeat bg-contain" />
                                                <h2 className="font-bold text-[#047bc2]">Triple Glazing</h2>
                                            </button>
                                        </>
                                    )}

                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="h-2/6 flex flex-col p-2 bg-white mt-2 w-full">
                        <div className="flex flex-row justify-between">
                            <h2 className="font-bold text-[#047bc2]">Search Products</h2>
                            {searchItems.length > 0 &&
                                <div className="flex flex-col justify-center items-center">
                                    <button
                                        type="submit"
                                        className="bg-[#047bc2] p-1 rounded text-white"
                                        onClick={() => reportModalOpenHandler()}
                                    >
                                        Search for Products
                                    </button>
                                </div>
                            }
                        </div>
                        <hr className="h-px my-2 bg-[#f2f4f6] border-2"/>
                        <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
                            <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
                                <thead
                                    className="text-xs text-gray-700 bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                                <tr className="text-[#047bc2]">
                                    <th scope="col" className="px-6 py-3">
                                        Family
                                    </th>
                                    <th scope="col" className="px-6 py-3">
                                        Description
                                    </th>
                                    <th scope="col" className="px-6 py-3">
                                        Thickness
                                    </th>
                                    {/* Delete Field */}
                                    <th scope="col" className="px-6 py-3" aria-label="Delete" />
                                </tr>
                                </thead>
                                <tbody>
                                {searchItems.map((searchItem, index) => (
                                    <tr className="bg-white border-b dark:bg-gray-900 dark:border-gray-700">
                                        <th scope="row"
                                            className="px-6 py-2 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                                            {searchItem.familyType}
                                        </th>
                                        <td className="px-6 py-2">
                                            {searchItem.description}
                                        </td>
                                        <td className="px-6 py-2">
                                            {searchItem.thickness}
                                        </td>
                                        <td className="px-6 py-2 cursor-pointer" onClick={() => deleteSearchItem(index)} aria-label="Delete">
                                            <IconTrash />
                                        </td>
                                    </tr>
                                ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                <hr className="h-full my-0 bg-[#f2f4f6] border-2 ml-2" />
                <div className="flex flex-col w-2/6 h-full">
                    <div className="w-full h-full flex flex-col p-2 bg-white ml-2 overflow-auto">
                        <div className="flex flex-row justify-between">
                            <h2 className="font-bold text-[#047bc2]">Specification</h2>
                            <button
                                type="submit"
                                aria-label="Reset Glass Specs"
                                onClick={() => resetGlassSpecs()}
                            >
                                <IconRefresh />
                            </button>
                        </div>
                        <hr className="h-px my-1 bg-[#f2f4f6] border-2"/>
                        {selectedGlassType !== '' &&
                            <>
                                {ruleError.length > 0 &&
                                    <div className="flex flex-col mt-4 bg-slate-50 p-2 rounded">
                                        <h2 className="font-bold text-red-500">Configuration Error</h2>
                                        <ul className="list-disc p-4">
                                            {ruleError.map((item) => (
                                                <li>{item}</li>
                                            ))}
                                        </ul>
                                    </div>
                                }

                                {assignGlassListOuter.length !== 0 &&
                                    <div className="flex flex-col mt-4 bg-slate-50 p-2 rounded">
                                        <button
                                            type="button"
                                            className="flex flex-row pl-1 pb-1 font-normal"
                                            onClick={() => setIsOuterOpen(!isOuterOpen)}
                                        >
                                            <div className="flex flex-row justify-between w-full">
                                                <h2 className="font-bold text-[#047bc2]">Component 1 - {component1Description}</h2>
                                                <IconDownArrow />
                                            </div>
                                        </button>
                                        <div  className={isOuterOpen ? 'h-auto' : 'h-0 invisible'}>
                                            {assignGlassListOuter.length > 0 && (
                                                assignGlassListOuter.map((item,index) => (
                                                    <div key={item.name}>{item.surfaceType !== 'A' && index !== 0 ? item.html : ''}</div>
                                                ))
                                            )}
                                        </div>
                                    </div>
                                }

                                {assignGlassListOuter.length !== 0 && assignGlassListOuter[assignGlassListOuter.length-1].surfaceType === 'A' &&
                                    <div className="flex flex-col mt-4 bg-slate-50 p-2 rounded">
                                        <button
                                            type="button"
                                            className="flex flex-row pl-1 pb-1 font-normal"
                                            onClick={() => setIsOuterAirOpen(!isOuterAirOpen)}
                                        >
                                            <div className="flex flex-row justify-between w-full">
                                                <h2 className="font-bold text-[#047bc2]">Air Space 1</h2>
                                                <IconDownArrow />
                                            </div>
                                        </button>
                                        <div  className={isOuterAirOpen ? 'h-auto' : 'h-0 invisible'}>
                                            <div key={assignGlassListOuter[assignGlassListOuter.length-1].name}>{assignGlassListOuter[assignGlassListOuter.length-1].html}</div>
                                        </div>
                                    </div>
                                }

                                {assignGlassListMiddle.length !== 0 &&
                                    <div className="flex flex-col mt-4 bg-slate-50 p-2 rounded">
                                        <button
                                            type="button"
                                            className="flex flex-row pl-1 pb-1 font-normal"
                                            onClick={() => setIsMiddleOpen(!isMiddleOpen)}
                                        >
                                            <div className="flex flex-row justify-between w-full">
                                                <h2 className="font-bold text-[#047bc2]">Component 2 - {component2Description}</h2>
                                                <IconDownArrow />
                                            </div>
                                        </button>
                                        <div  className={isMiddleOpen ? 'h-auto' : 'h-0 invisible'}>
                                            {assignGlassListMiddle.length > 0 && (
                                                assignGlassListMiddle.map((item) => (
                                                    <div key={item.name}>{item.surfaceType !== 'A' ? item.html : ''}</div>
                                                ))
                                            )}
                                        </div>
                                    </div>
                                }

                                {assignGlassListMiddle.length !== 0 && assignGlassListMiddle[assignGlassListMiddle.length-1].surfaceType === 'A' &&
                                    <div className="flex flex-col mt-4 bg-slate-50 p-2 rounded">
                                        <button
                                            type="button"
                                            className="flex flex-row pl-1 pb-1 font-normal"
                                            onClick={() => setIsMiddleAirOpen(!isMiddleAirOpen)}
                                        >
                                            <div className="flex flex-row justify-between w-full">
                                                <h2 className="font-bold text-[#047bc2]">Air Space 2</h2>
                                                <IconDownArrow />
                                            </div>
                                        </button>
                                        <div  className={isMiddleAirOpen ? 'h-auto' : 'h-0 invisible'}>
                                            <div key={assignGlassListMiddle[assignGlassListMiddle.length-1].name}>{assignGlassListMiddle[assignGlassListMiddle.length-1].html}</div>
                                        </div>
                                    </div>
                                }

                                {assignGlassListInner.length !== 0 &&
                                    <div className="flex flex-col mt-4 bg-slate-50 p-2 rounded">
                                        <button
                                            type="button"
                                            className="flex flex-row pl-1 pb-1 font-normal"
                                            onClick={() => setIsInnerOpen(!isInnerOpen)}
                                        >
                                            <div className="flex flex-row justify-between w-full">
                                                <h2 className="font-bold text-[#047bc2]">Component 3 - {component3Description}</h2>
                                                <IconDownArrow />
                                            </div>
                                        </button>
                                        <div  className={isInnerOpen ? 'h-auto' : 'h-0 invisible'}>
                                            {assignGlassListInner.length > 0 && (
                                                assignGlassListInner.map((item) => (
                                                    <div key={item.name}>{item.html}</div>
                                                ))
                                            )}
                                        </div>
                                    </div>
                                }

                                <div className="flex flex-col w-full justify-center items-center mt-4">
                                    <button
                                        type="submit"
                                        className="bg-[#047bc2] w-full p-2 rounded text-white"
                                        onClick={() => addSearchItem()}
                                    >
                                        {isCheckingRule ? 'Checking Configuration' : 'Add to Search'}
                                    </button>
                                </div>
                            </>
                        }
                        {selectedGlassType === '' &&
                            <div className="flex flex-col mt-4 text-center font-bold text-[#047bc2]">
                                <h2>Please select a Glazing Type to start</h2>
                            </div>
                        }
                    </div>
                </div>
                <ReportModal
                    open={isReportModalOpen}
                    requestObject={requestObject}
                    onClose={() => setIsReportModalOpen(false)}
                />
            </div>
        </section>
    );
};

export default MainPage;