import PropTypes from 'prop-types';
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import { ORDER_STATUS_CONSTANTS } from '../SampleReport/FormConstant';
import IconInformation from '../../components/Icon/IconInformation';
import IconPen from '../../components/Icon/IconPen';
import IconPlus from '../../components/Icon/IconPlus';
import IconTrash from '../../components/Icon/IconTrash';
import IconSampleLabel from '../../components/Icon/IconSampleLabel';
import IconShipment from '../../components/Icon/IconShipment';
import { exportToExcel } from './labelFunction';

const DashboardTableData = ({
    data,
    orderStatusHandleFunction,
    currentStatus,
    orderDetailHandleFunction,
    orderDeleteHandleFunction,
    orderEditHandleFunction,
    currentPage,
    dataSize,
    requestDataFunction
   }) => {
    const navigate = useNavigate();
    const formatDate = (isoString) => {
        const date = new Date(isoString);
        return date.toLocaleString();
    };
    const itemsPerPage = 5;

    // Calculate pagination
    const totalPages = Math.ceil(dataSize / itemsPerPage);
    const startIndex = (currentPage - 1) * itemsPerPage + 1;
    const endIndex = Math.min(startIndex + itemsPerPage - 1, dataSize);

    return (
        <>
            {data.map((order, orderIndex) => (
                <div className="flex flex-row w-full h-auto text-xs">
                    <div className="w-[15%] flex justify-center items-center bg-[#f3f6f7] border-solid border-r-2 border-b-2 border-white">
                        <h2 className="text-center">{order.id}</h2>
                    </div>
                    <div className="w-[30%] flex justify-center items-center bg-[#f3f6f7] border-solid border-r-2 border-b-2 border-white whitespace-pre-line">
                        <h2 className="text-center">{order.formData.customerName} {order.formData.projectName}</h2>
                    </div>
                    <div className="w-[15%] flex justify-center items-center bg-[#f3f6f7] border-solid border-r-2 border-b-2 border-white">
                        <h2 className="text-center">{formatDate(order.created_at)}</h2>
                    </div>
                    <div className="w-[15%] flex justify-center items-center bg-[#f3f6f7] border-solid border-r-2 border-b-2 border-white">
                        <select id="orderStatus"
                                value={order.status}
                                className="flex flex-col w-3/4 rounded border border-gray-300 py-2"
                                onChange={(event) => orderStatusHandleFunction(currentStatus, event, orderIndex, order.id)}
                        >
                            {ORDER_STATUS_CONSTANTS.map((status) => (
                                <option key={status.value} value={status.value}>{status.value}</option>
                            ))}
                        </select>
                    </div>
                    <div className="w-[25%] flex justify-center items-center bg-[#ebf2f6] border-solid border-r-2 border-b-2 border-white">
                        <div className="flex flex-row w-full justify-evenly text-sm text-[#0d3857]">
                            <button
                                type="submit"
                                onClick={() => orderDetailHandleFunction(currentStatus, orderIndex)}
                                className="p-2 group relative cursor-pointer"
                                aria-label="Order Sample Information"
                            >
                                <IconInformation />
                            </button>
                            {currentStatus === 'Pending' &&
                                <>
                                    <button
                                        type="submit"
                                        onClick={() => orderEditHandleFunction(currentStatus, orderIndex)}
                                        className="p-2 group relative cursor-pointer"
                                        aria-label="Order Edit"
                                    >
                                        <IconPen />
                                    </button>
                                    <button
                                        type="submit"
                                        onClick={() => toast.success('Adding Sample to the Existing Order Feature Under Development')}
                                        className="p-2 group relative cursor-pointer"
                                        aria-label="Sample Add"
                                    >
                                        <IconPlus />
                                    </button>
                                    <button
                                        type="submit"
                                        onClick={() => orderDeleteHandleFunction(currentStatus, orderIndex)}
                                        className="p-2 group relative cursor-pointer"
                                        aria-label="Order Delete"
                                    >
                                        <IconTrash />
                                    </button>
                                </>
                            }
                            {currentStatus === 'Ready For Shipment' &&
                                <>
                                    <button
                                        type="submit"
                                        onClick={() => exportToExcel(data)}
                                        className="p-2 group relative cursor-pointer"
                                        aria-label="Print Sample Labels"
                                    >
                                        <IconSampleLabel />
                                    </button>
                                    <button
                                        type="submit"
                                        onClick={() => toast.success('Shipment Label Print Feature Under Development')}
                                        className="p-2 group relative cursor-pointer"
                                        aria-label="Print Shipment Label"
                                    >
                                        <IconShipment />
                                    </button>
                                </>
                            }
                        </div>
                    </div>
                </div>
            ))}

            {/* Pagination Controls */}
            <div className="flex flex-row w-full justify-between items-center pt-4">
                <div className="flex w-1/2">
                    Showing {startIndex} to {endIndex} of {dataSize} results
                </div>
                <div className="flex w-1/2 justify-end items-center">
                    <button
                        type="submit"
                        className="px-4 py-2 mx-2 bg-gray-200 rounded disabled:opacity-50"
                        onClick={() => requestDataFunction(currentStatus, -1)}
                        disabled={currentPage === 1}
                    >
                        Previous
                    </button>
                    <span className="px-4 py-2">{currentPage} / {totalPages}</span>
                    <button
                        type="submit"
                        className="px-4 py-2 mx-2 bg-gray-200 rounded disabled:opacity-50"
                        onClick={() => requestDataFunction(currentStatus, 1)}
                        disabled={currentPage === totalPages}
                    >
                        Next
                    </button>
                </div>
            </div>
        </>
    );
};

DashboardTableData.propTypes = {
    data: PropTypes.array.isRequired,
    orderDetailHandleFunction: PropTypes.func.isRequired,
    orderStatusHandleFunction: PropTypes.func,
    orderDeleteHandleFunction: PropTypes.func,
    orderEditHandleFunction: PropTypes.func,
    currentStatus: PropTypes.string.isRequired,
    currentPage: PropTypes.number.isRequired,
    dataSize: PropTypes.number.isRequired,
    requestDataFunction: PropTypes.func.isRequired,
};

DashboardTableData.defaultProps = {
    orderStatusHandleFunction: () => console.log('Default Order Status Handle'),
    orderDeleteHandleFunction: () => console.log('Default Order Delete Handle'),
    orderEditHandleFunction: () => console.log('Default Order Edit Handle'),
};

export default DashboardTableData;
