import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Tab } from '@headlessui/react';
import toast from 'react-hot-toast';
import Modal from '../../components/Modal/Modal';
import IconTrash from '../../components/Icon/IconTrash';

const OrderDetailModal = ({
                           open, onClose, data, orderIndex, existingStatus, deleteSampleHandler
                       }) => {

    const onCloseButtonClickHandler = () => {
        onClose();
    };

    const [orderDetailData, setOrderDetailData] = useState([]);

    useEffect( () => {
        setOrderDetailData(data);
    }, [data]);

    const sampleDeleteHandle = (currentStatus, sampleIndex, sampleId) => {
        if(orderDetailData.length === 1) {
            toast.error('You cannot delete only sample in the order please delete the order!');
        } else {
            setOrderDetailData((prevData) => prevData.filter((item) => item.product_id !== sampleId));
            deleteSampleHandler(currentStatus, orderIndex, sampleIndex, sampleId);
            toast.success('Sample removed from order successfully!');
        }
    }

    return (
        <Modal open={open} setOpen={onCloseButtonClickHandler} modelSize="max-w-md">
            <div className="px-2">
                <Tab.Group>
                    <Tab.Panels>
                        <h2 className="font-bold pb-4">Order Details</h2>
                        {orderDetailData.map((item, sampleIndex) => (
                            <div className="flex flex-row w-full h-auto text-xs">
                                <div className="w-[65%] flex justify-center items-center bg-[#f3f6f7] border-solid border-r-2 border-b-2 border-white whitespace-pre-line p-2">
                                    <h2 className="text-center">{String.raw`${item.product.split(';').join(';\n')}`}</h2>
                                </div>
                                <div className="w-[20%] flex justify-center items-center bg-[#f3f6f7] border-solid border-r-2 border-b-2 border-white p-2">
                                    <h2 className="text-center">{item.quantity}x</h2>
                                    <h2 className="text-center pl-2">{item.sampleSize}</h2>
                                </div>
                                <div className="w-[15%] flex justify-center items-center bg-[#f3f6f7] border-solid border-r-2 border-b-2 border-white p-2">
                                    {existingStatus === 'Pending' &&
                                        <button
                                            type="submit"
                                            onClick={() => sampleDeleteHandle(existingStatus, sampleIndex, item.product_id)}
                                            className="p-2 group relative cursor-pointer"
                                            aria-label="Sample Delete"
                                        >
                                            <IconTrash />
                                        </button>
                                    }
                                </div>
                                {/* Designer Function Part End */}
                            </div>
                        ))}
                    </Tab.Panels>
                </Tab.Group>
            </div>
        </Modal>
    );
};

OrderDetailModal.propTypes = {
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    data: PropTypes.array.isRequired,
    orderIndex: PropTypes.number.isRequired,
    existingStatus: PropTypes.string.isRequired,
    deleteSampleHandler: PropTypes.func.isRequired,
};

export default OrderDetailModal;
