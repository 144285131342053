import { useEffect, useRef, useState } from 'react';
import html2canvas from 'html2canvas';
import { Page, Image } from '@react-pdf/renderer';
import {useLocation, useNavigate} from 'react-router-dom';
import toast from 'react-hot-toast';
import { useSelector } from 'react-redux';
import Cookies from 'js-cookie';
import {
    useGetApprovedProductMutation,
    useGetProductSpecDataMutation,
    useGetReportsMutation,
    usePostBulkSearchByIdMutation,
    usePostCreateApprovalRequestMutation,
    usePostCreateCalculationRequestMutation
} from '../../store/services/api';
import WarningIcon from '../../components/Icon/WarningIcon';
import IconPdf from '../../components/Icon/IconPdf';
import IconTrash from '../../components/Icon/IconTrash';
import IconStar from '../../components/Icon/IconStart';
import IconStarYellow from '../../components/Icon/IconStarYellow';
import IconSpandrel from '../../components/Icon/IconSpandrel';
import NewSingleExcelExporter from '../../components/Excel/NewSingleExcelExporter';
import IconGoBack from '../../components/Icon/IconGoBack';
import { DASHBOARD_PATH } from '../../routes';
import { getUser } from '../../store/slices/user';
import PdfDownloadModal from './PdfDownloadModal';
import NewExcelExporter from '../../components/Excel/NewExcelExporter';
import SpandrelModal from './SpandrelModal';
import EditFamilyModal from './EditFamilyModal';
import { useQuery } from '../../hooks/useQuery';
import EditProductDescriptionModal from './EditProductDescriptionModal';
import IconSpecGenerator from '../../components/Icon/IconSpecGenerator';
import SpecModal from './SpecModal';

const Report = () => {
    const query = useQuery();
    const extraResult = query.get('extra_result');
    const extraCustomer = query.get('extra_customer');
    const extraProject = query.get('extra_project');
    const navigate = useNavigate();
    const dataFetch = useRef(false);
    const location = useLocation();
    const requestData = location.state?.data;
    const [customerName, setCustomerName] = useState('');
    const [projectName, setProjectName] = useState('');
    const [tableData, setTableData] = useState([]);
    const [noTableData, setNoTableData] = useState([]);
    const [customPdfDiv, setCustomPdfDiv] = useState([]);
    const [loadingPdf, setLoadingPdf] = useState(false);
    const divRefs = useRef([]);
    const [pdfPageLayout, setPdfPageLayout] = useState([]);
    const [pageLoading, setPageLoading] = useState(true);
    const [approvalLoading, setApprovalLoading] = useState(false);
    const [isError, setIsError] = useState(false);

    const user = useSelector(getUser);
    const [requestFavTrigger] = usePostBulkSearchByIdMutation();
    const [requestApprovalTrigger] = usePostCreateApprovalRequestMutation();
    const [requestCalculationTrigger] = usePostCreateCalculationRequestMutation();
    const [trigger, result] = useGetReportsMutation();
    const [triggerProductId, resultProductId] = useGetApprovedProductMutation();
    const [triggerProductSpec] = useGetProductSpecDataMutation();

    const [isDownloadModalOpen, setDownloadModalOpen] = useState(false);
    const [isSpandrelModalOpen, setSpandrelModalOpen] = useState(false);
    const [isSpecModalOpen, setIsSpecModalOpen] = useState(false);
    const [specData, setSpecData] = useState({});
    const [spandrelIndex, setSpandrelIndex] = useState(-1);
    const [isEditFamilyTypeModalOpen, setEditFamilyTypeModalOpen] = useState(false);
    const [isEditProductDescriptionModal, setEditProductDescriptionModalOpen] = useState(false);
    const [editIndex, setEditIndex] = useState(-1);
    const [editDefaultValue, setEditDefaultValue] = useState('');
    const [isMetric, setIsMetric] = useState(false);

    const downloadModalOpenHandler = () => {
        setDownloadModalOpen(true);
    }

    function chunkArray(array, chunkSize) {
        return Array.from({ length: Math.ceil(array.length / chunkSize) }, (_, index) =>
            array.slice(index * chunkSize, (index + 1) * chunkSize)
        );
    }

    async function getFavProduct(payload) {
        const favResult = await requestFavTrigger(payload);
        return favResult;
    }

    useEffect(() => {
        const fetchFavProductData = async () => {
            if (result.data) {
                const savedCookieValue = Cookies.get('SavedResult');

                if (savedCookieValue === undefined) {
                    setTableData(result.data.existData);
                    setNoTableData(result.data.notExistData);
                } else {
                    const savedTableData = JSON.parse(savedCookieValue);
                    try {
                        const favResult = await getFavProduct(savedTableData);
                        // setTableData(result.data.existData);
                        setTableData(result.data.existData.concat(favResult.data));
                        setNoTableData(result.data.notExistData);
                    } catch (error) {
                        console.error('Error fetching favorite products:', error);
                    }
                }

                setPageLoading(false);
            }
        };

        fetchFavProductData();
    }, [result.isSuccess]);

    useEffect(() => {
        if (resultProductId.data) {
            setTableData([resultProductId.data]);
            setPageLoading(false);
        }
    }, [resultProductId.isSuccess]);


    useEffect(() => {
        if(result.status === 'rejected') {
            setTableData(result.error.data.message);
            setIsError(true);
            setPageLoading(false);
        }
    }, [result.isError]);

    const handleScreenshot = async () => {
        const refsArray = divRefs.current;

        const tmpImageList = [];

        // eslint-disable-next-line no-restricted-syntax
        for (const ref of refsArray) {
            // eslint-disable-next-line no-await-in-loop
            await html2canvas(ref, {scale: 3 }).then(canvas => {
                tmpImageList.push(canvas.toDataURL());
            });
        }

        const tmpPdfPageLayout = [];

        tmpImageList.forEach((item) => {
            tmpPdfPageLayout.push(
                <Page size="A4" wrap={false}>
                    {item && (
                        <Image
                            src={item}
                        />
                    )}
                </Page>
            )
        });
        setPdfPageLayout(tmpPdfPageLayout);
        downloadModalOpenHandler();
        setTimeout(() => {
            setLoadingPdf(false);
        }, 1000);
    };

    const detailedReportModalOpenHandler = async () => {
        await handleScreenshot();
    };

    useEffect(() => {
        if (!dataFetch.current) {
            if(extraResult !== null) {
                triggerProductId(extraResult)
            } else {
                trigger({requestData});
            }

            if(extraCustomer !== 'N/A') {
                setCustomerName(extraCustomer);
            }

            if(extraProject !== 'N/A') {
                setProjectName(extraProject);
            }
        }

        if(requestData !== undefined) {
            const dataObject = JSON.parse(requestData);
            setCustomerName(dataObject.customerName);
            setProjectName(dataObject.projectName);
        }
        dataFetch.current = true
    }, [])

    function getPdfPageHtml(data, index, topLineSpecData = []) {
        // eslint-disable-next-line no-return-assign
        return <div className="bg-red-600 h-[793px] w-[1122px] relative" ref={(ref) => (divRefs.current[index] = ref)} style={{ position: "fixed", left: "-9999px"}}>
            <img src="/images/viracon_pdf.png" alt="pdf" className="h-[793px] w-[1122px]"/>
            <div className="absolute h-[780px] w-[1122px] pt-28 top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 pl-6 pr-6">
                <div className="flex flex-row w-full text-white text-[10px]">
                    <div className="w-[5%] h-20 flex justify-center items-center bg-[#0d3857] border-solid border-r-2 border-white">
                        <h2 className="text-center">Glass Type</h2>
                    </div>
                    <div className="w-[22%] flex justify-center items-center bg-[#0d3857] border-solid border-r-2 border-white">
                        <h2 className="text-center">Product</h2>
                    </div>
                    <div className="flex flex-col w-[20%]">
                        <div className="h-10 flex flex-row justify-center items-center bg-[#0d3857] border-solid border-r-2 border-b-2 border-white">
                            <h2>Transmission</h2>
                        </div>
                        <div className="flex flex-row h-10">
                            <h2 className="w-1/3 text-center bg-[#3d6078] border-solid border-r-2 border-white flex flex-row justify-center items-center">Visible</h2>
                            <h2 className="w-1/3 text-center bg-[#557489] border-solid border-r-2 border-white flex flex-row justify-center items-center">Solar</h2>
                            <h2 className="w-1/3 text-center bg-[#6e889a] border-solid border-r-2 border-white flex flex-row justify-center items-center">UV</h2>
                        </div>
                    </div>
                    <div className="flex flex-col w-[15%]">
                        <div className=" h-10 flex flex-row justify-center items-center bg-[#3b6178] border-solid border-r-2 border-b-2 border-white">
                            <h2>Reflectance</h2>
                        </div>
                        <div className="flex flex-row h-10">
                            <h2 className="w-1/3 text-center bg-[#628193] border-solid border-r-2 border-white flex flex-row justify-center items-center">Vis-Out</h2>
                            <h2 className="w-1/3 text-center bg-[#7690a0] border-solid border-r-2 border-white flex flex-row justify-center items-center">Vis-In</h2>
                            <h2 className="w-1/3 text-center bg-[#89a0ae] border-solid border-r-2 border-white flex flex-row justify-center items-center">Solar</h2>
                        </div>
                    </div>
                    <div className="flex flex-col w-[10%]">
                        <div className="h-10 flex flex-row justify-center items-center bg-[#1f6e9e] border-solid border-r-2 border-b-2 border-white">
                            <h2>U-value</h2>
                        </div>
                        <div className="flex flex-row h-10">
                            <h2 className="w-1/2 text-center bg-[#4b8bb1] border-solid border-r-2 border-white flex flex-row justify-center items-center">Winter</h2>
                            <h2 className="w-1/2 text-center bg-[#6299bb] border-solid border-r-2 border-white flex flex-row justify-center items-center">Summer</h2>
                        </div>
                    </div>
                    <div className="w-[7%] h-20 flex justify-center items-center bg-[#0079c1] border-solid border-r-2 border-white">
                        <h2 className="text-center">Shading Coefficient</h2>
                    </div>
                    <div className="w-[7%] h-20 flex justify-center items-center bg-[#128acb] border-solid border-r-2 border-white">
                        <h2 className="text-center">Solar Heat Gain Coefficient</h2>
                    </div>
                    <div className="w-[7%] h-20 flex justify-center items-center bg-[#3795ce] border-solid border-r-2 border-white">
                        <h2 className="text-center">Relative Heat Gain</h2>
                    </div>
                    <div className="w-[7%] h-20 flex justify-center items-center bg-[#3795ce]">
                        <h2 className="text-center">Light to Solar Gain Ratio</h2>
                    </div>
                </div>
                {data.map((item, dataIndex) => (
                    <div className="flex flex-row w-full text-[10px]">
                        <div className="w-[5%] h-32 flex justify-center items-center bg-[#f3f6f7] border-solid border-r-2 border-b-2 border-white">
                            <h2 className="text-center">{item.family}</h2>
                        </div>
                        <div className="w-[22%] h-32 flex justify-center items-center bg-[#f3f6f7] border-solid border-r-2 border-b-2 border-white whitespace-pre-line">
                            {isMetric && <h2 className="text-center">{String.raw`${item.productMetric.split(';').join(';\n')}`}</h2>}
                            {!isMetric && <h2 className="text-center"> {`${topLineSpecData[dataIndex] ? topLineSpecData[dataIndex]?.top_line_imp.split(" as")[0] : ''}\n${topLineSpecData.length > 0 ? (item.product.replace(/^[^;]*;\s*/, '')).split(';').join(';\n') : item.product.split(';').join(';\n') }`}</h2>}
                        </div>
                        <div className="w-[20%] h-32 flex justify-center items-center bg-[#ebf2f6] border-solid border-r-2 border-b-2 border-white">
                            <h2 className="w-1/3 h-full flex justify-center items-center text-center border-solid border-r-2 border-white">{item.description_visible}{item.description_visible !== 'N/A' ? '%' : ''}</h2>
                            <h2 className="w-1/3 h-full flex justify-center items-center text-center border-solid border-r-2 border-white">{item.description_solar}{item.description_solar !== 'N/A' ? '%' : ''}</h2>
                            <h2 className="w-1/3 h-full flex justify-center items-center text-center">{item.description_uv}{item.description_uv !== 'N/A' ? '%' : ''}</h2>
                        </div>
                        <div className="w-[15%] h-32 flex justify-center items-center bg-[#ebf2f6] border-solid border-r-2 border-b-2 border-white">
                            <h2 className="w-1/3 h-full flex justify-center items-center text-center border-solid border-r-2 border-white">{item.reflectance_out}{item.reflectance_out !== 'N/A' ? '%' : ''}</h2>
                            <h2 className="w-1/3 h-full flex justify-center items-center text-center border-solid border-r-2 border-white">{item.reflectance_in}{item.reflectance_in !== 'N/A' ? '%' : ''}</h2>
                            <h2 className="w-1/3 h-full flex justify-center items-center text-center">{item.reflectance_solar}{item.reflectance_solar !== 'N/A' ? '%' : ''}</h2>
                        </div>
                        <div className="w-[10%] h-32 flex justify-center items-center bg-[#ebf2f6] border-solid border-r-2 border-b-2 border-white">
                            <h2 className="w-1/2 h-full flex justify-center items-center text-center border-solid border-r-2 border-white">{isMetric ? item.u_value_solar_metric : item.u_value_solar}</h2>
                            <h2 className="w-1/2 h-full flex justify-center items-center text-center">{isMetric ? item.u_value_summer_metric : item.u_value_summer}</h2>
                        </div>
                        <div className="w-[7%] h-32 flex justify-center items-center bg-[#ebf2f6] border-solid border-r-2 border-b-2 border-white">
                            <h2 className="text-center">{item.shading_coefficient}</h2>
                        </div>
                        <div className="w-[7%] h-32 flex justify-center items-center bg-[#ebf2f6] border-solid border-r-2 border-b-2 border-white">
                            <h2 className="text-center">{item.solar_heat_gain_coefficient}</h2>
                        </div>
                        <div className="w-[7%] h-32 flex justify-center items-center bg-[#ebf2f6] border-solid border-r-2 border-b-2 border-white">
                            <h2 className="text-center">{isMetric ? item.relative_heat_gain_metric : item.relative_heat_gain}</h2>
                        </div>
                        <div className="w-[7%] h-32 flex justify-center items-center bg-[#ebf2f6] border-solid border-r-2 border-b-2 border-white">
                            <h2 className="text-center">{item.light_solar_gain_ration}</h2>
                        </div>
                    </div>
                ))}
                <div className="absolute bottom-0 right-0 text-white text-[10px] bg-transparent h-20 mt-10 w-full flex justify-center items-center">
                    <div className="flex flex-row w-full justify-between">
                        <div className="pl-10 pt-6 flex flex-row w-1/2">
                            <div className="flex flex-col">
                                <h2>
                                    Created by: {`${user.firstName} ${user.lastName}`}
                                </h2>
                                <h2>
                                    Project Name: {projectName}
                                </h2>
                            </div>
                            <div className="pl-16 flex flex-col">
                                <h2>
                                    Customer Name: {customerName}
                                </h2>
                                <h2>
                                    Date: {new Date().toLocaleString()}
                                </h2>
                            </div>
                        </div>
                        <div className="pr-10 pt-6 flex flex-row w-1/2">
                            <h2>
                                Viracon&rsquo;s Performance Data is center of glass data based on National Fenestration Rating Council&rsquo;s (NFRC) measurement standards and is calculated using Lawrence Berkeley National Laboratory&rsquo;s (LBNL) WINDOW 7 software.Contact Viracon for product availability. <h2 className="font-bold">Note: UltraViolet defined as 300 to 380 nanometers(nm)</h2>
                            </h2>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    }

    function getSinglePdfPageHtml (item, index, topLineSpecData) {
        // eslint-disable-next-line no-return-assign
        return <div className="bg-red-600 h-[1122px] w-[793px] relative text-sm" ref={(ref) => (divRefs.current[index] = ref)} style={{ position: "fixed", left: "-9999px"}}>
            <img src="/images/viracon_single_pdf_empty_footer.png" alt="pdf" className="h-[1122px] w-[793px]"/>
            <div className="absolute h-[1122px] w-[780px] pt-44 top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 pl-6 pr-6">
                <div className="flex flex-row w-full">
                    <div className="w-1/2">
                        <h2 className="bg-[#0d3857] text-white font-bold p-2 border border-white">Project Name</h2>
                        <table className="w-full border-collapse">
                            <tbody>
                            <tr>
                                <td className="bg-[#cfd7dd] p-2 border border-white w-1/2">{projectName !== '' ? projectName : 'N/A'}</td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                    <div className="w-1/2">
                        <h2 className="bg-[#0d3857] text-white font-bold p-2 border border-white">Customer Name</h2>
                        <table className="w-full border-collapse">
                            <tbody>
                            <tr>
                                <td className="bg-[#cfd7dd] p-2 border border-white w-1/2">{customerName !== '' ? customerName : 'N/A'}</td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </div>

                <div className="flex flex-row w-full mt-4">
                    <div className="w-1/4 flex flex-col">
                        <h2 className="bg-[#0d3857] text-white font-bold p-2 border border-white">Glass Type</h2>
                        <table className="w-full border-collapse flex-grow">
                            <tbody>
                            <tr>
                                <td className="bg-[#cfd7dd] p-2 border border-white w-1/2">{item.family}</td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                    <div className="w-3/4 flex flex-col">
                        <h2 className="bg-[#0d3857] text-white font-bold p-2 border border-white">Make-Up</h2>
                        <table className="w-full border-collapse flex-grow">
                            <tbody>
                            <tr>
                                <td className="bg-[#cfd7dd] p-2 border border-white w-1/2 whitespace-pre-line">
                                    {isMetric && String.raw`${item.productMetric.split(';').join(';\n')}`}
                                    {!isMetric &&
                                        <h2 className="text-center">
                                            {`${Object.keys(topLineSpecData).length > 0 ? topLineSpecData?.top_line_imp.split(" as")[0] : ''}\n${Object.keys(topLineSpecData).length > 0 ? (item.product.replace(/^[^;]*;\s*/, '')).split(';').join(';\n') : item.product.split(';').join(';\n') }`}
                                        </h2>
                                    }
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </div>

                <div className="flex flex-row w-full mt-4">
                    <h2 className="bg-[#0d3857] text-white font-bold p-2 border border-white w-full">Performance Data Imperial</h2>
                </div>

                <div className="flex flex-row w-full">
                    <div className="w-1/2">
                        <table className="w-full border-collapse">
                            <thead className="bg-[#6eb1dc]">
                            <tr className="text-left border border-white">
                                <th className="p-2">Transmittance</th>
                                <th aria-label="Empty Title" />
                            </tr>
                            </thead>
                            <tbody>
                            <tr>
                                <td className="bg-[#cfd7dd] p-2 border border-white w-1/2">Visible Light:</td>
                                <td className="bg-[#edf1f3] border border-white p-2">{item.description_visible}{item.description_visible !== 'N/A' ? '%' : ''}</td>
                            </tr>
                            <tr>
                                <td className="bg-[#cfd7dd] p-2 border border-white">Solar Energy:</td>
                                <td className="bg-[#edf1f3] border border-white p-2">{item.description_solar}{item.description_solar !== 'N/A' ? '%' : ''}</td>
                            </tr>
                            <tr>
                                <td className="bg-[#cfd7dd] p-2 border border-white">Ultraviolet (UV):</td>
                                <td className="bg-[#edf1f3] border border-white p-2">{item.description_uv}{item.description_uv !== 'N/A' ? '%' : ''}</td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                    <div className="w-1/2">
                        <table className="w-full border-collapse">
                            <tbody>
                            <tr>
                                <td className="bg-[#cfd7dd] p-2 border border-white w-2/3">Shading Coefficient (SC)</td>
                                <td className="bg-[#edf1f3] border border-white p-2">{item.shading_coefficient}</td>
                            </tr>
                            <tr>
                                <td className="bg-[#cfd7dd] p-2 border border-white w-2/3">Relative Heat Gain</td>
                                {/* eslint-disable-next-line no-nested-ternary */}
                                <td className="bg-[#edf1f3] border border-white p-2">{isMetric ? item.relative_heat_gain_metric : item.relative_heat_gain}<span className="text-xs">{item.relative_heat_gain !== 'N/A' ? isMetric ? ' W/(M2 x °K)' : ' Btu/(hr x sqft)' : ''}</span></td>
                            </tr>
                            <tr>
                                <td className="bg-[#cfd7dd] p-2 border border-white w-2/3">Solar Heat Gain Coefficient (SHGC)</td>
                                <td className="bg-[#edf1f3] border border-white p-2">{item.solar_heat_gain_coefficient}</td>
                            </tr>
                            <tr>
                                <td className="bg-[#cfd7dd] p-2 border border-white w-2/3">Light to Solar Gain Ratio (LSG)</td>
                                <td className="bg-[#edf1f3] border border-white p-2">{item.light_solar_gain_ration}</td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </div>

                <div className="flex flex-row w-full">
                    <div className="w-1/2">
                        <table className="w-full border-collapse mt-4">
                            <thead className="bg-[#6eb1dc]">
                            <tr className="w-full text-left border border-white">
                                <th className="p-2">Reflectance</th>
                                <th aria-label="Empty Title" />
                            </tr>
                            </thead>
                            <tbody>
                            <tr>
                                <td className="bg-[#cfd7dd] p-2 border border-white w-1/2">Visible Light-Exterior:</td>
                                <td className="bg-[#edf1f3] border border-white p-2">{item.reflectance_out}{item.reflectance_out !== 'N/A' ? '%' : ''}</td>
                            </tr>
                            <tr>
                                <td className="bg-[#cfd7dd] p-2 border border-white">Visible Light-Interior:</td>
                                <td className="bg-[#edf1f3] border border-white p-2">{item.reflectance_in}{item.reflectance_in !== 'N/A' ? '%' : ''}</td>
                            </tr>
                            <tr>
                                <td className="bg-[#cfd7dd] p-2 border border-white">Solar Energy:</td>
                                <td className="bg-[#edf1f3] border border-white p-2">{item.reflectance_solar}{item.reflectance_solar !== 'N/A' ? '%' : ''}</td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </div>

                <div className="flex flex-row w-full">
                    <div className="w-1/2">
                        <table className="w-full border-collapse mt-4">
                            <thead className="bg-[#6eb1dc]">
                            <tr className="w-full text-left border border-white">
                                <th className="p-2">NFRC U-Value</th>
                                <th aria-label="Empty Title" />
                            </tr>
                            </thead>
                            <tbody>
                            <tr>
                                <td className="bg-[#cfd7dd] p-2 border border-white w-1/2">Winter:</td>
                                <td className="bg-[#edf1f3] border border-white p-2">{isMetric ? item.u_value_solar_metric : item.u_value_solar} <span className="text-xs"> {isMetric ? 'W/(M2 x °K)' : 'Btu/(hr x sqft)'}</span></td>
                            </tr>
                            <tr>
                                <td className="bg-[#cfd7dd] p-2 border border-white">Summer:</td>
                                <td className="bg-[#edf1f3] border border-white p-2">{isMetric ? item.u_value_summer_metric : item.u_value_summer} <span className="text-xs"> {isMetric ? 'W/(M2 x °K)' : 'Btu/(hr x sqft)'}</span></td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </div>

                <div className="absolute bottom-0 right-0 text-white text-[9px] bg-transparent h-20 mb-10 w-full flex justify-center items-center">
                    <div className="flex flex-row w-full justify-between">
                        <div className="pl-10 pt-16 flex flex-row w-1/2">
                            <div className="flex flex-col">
                                <h2>
                                    Created by: {`${user.firstName} ${user.lastName}`}
                                </h2>
                            </div>
                            <div className="pl-16 flex flex-col">
                                <h2>
                                    Date: {new Date().toLocaleString()}
                                </h2>
                            </div>
                        </div>
                        <div className="pr-10 pt-14 flex flex-row w-1/2 leading-tight">
                            <h2>
                                Viracon&rsquo;s Performance Data is center of glass data based on National Fenestration Rating Council&rsquo;s (NFRC) measurement standards and is calculated using Lawrence Berkeley National Laboratory&rsquo;s (LBNL) WINDOW 7 software.Contact Viracon for product availability. <h2 className="font-bold">Note: UltraViolet defined as 300 to 380 nanometers(nm)</h2>
                            </h2>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    }

    function deleteTableItem(index) {
        // eslint-disable-next-line no-case-declarations
        const tmpTableItem = [...tableData];

        tmpTableItem.splice(index, 1);
        setTableData(tmpTableItem);
        toast.success('Glazing Configuration Removed!');
    }

    function deleteNoTableItem(index) {
        // eslint-disable-next-line no-case-declarations
        const tmpNoTableItem = [...noTableData];

        tmpNoTableItem.splice(index, 1);
        setNoTableData(tmpNoTableItem);
        toast.success('Glazing Configuration Removed!');
    }

    /* isAll can be all(true) or single(false) and singlePDFIndex value is default 0 */
    const generatePdf = async (isAll, isSpecIncluded, singlePdfIndex = 0) => {

        setLoadingPdf(true);
        let newTableData = [];

        if (isAll === true) {
            newTableData = tableData.filter(obj => obj.exist && obj.isChecked);

            if (newTableData.length === 0) {
                setLoadingPdf(false);
                toast.error('Please select products that you want to export.');
                return;
            }
        } else {
            newTableData = [tableData[singlePdfIndex]];
        }

        // const newTableData = tableData.filter(obj => obj.exist);
        // eslint-disable-next-line no-case-declarations
        const originalArray = [...newTableData];
        const chunkedArray = chunkArray(originalArray, 4);
        const tmpCustomPdfDiv = [];
        let specDataArray;
        let specDataChunkedArray;

        if (isSpecIncluded) {
            try {
                const promises = originalArray.map((item) => triggerProductSpec(item.product_id).unwrap());
                specDataArray = await Promise.all(promises);
                specDataChunkedArray = chunkArray(specDataArray, 4);
                console.log(specDataChunkedArray);
            } catch (error) {
                console.error("Error fetching product spec data:", error);
            }
        }

        if (isAll === true) {
            chunkedArray.forEach((item, index) => {
                if(isSpecIncluded) {
                    tmpCustomPdfDiv.push(getPdfPageHtml(item, index, specDataChunkedArray[index]));
                } else {
                    tmpCustomPdfDiv.push(getPdfPageHtml(item, index));
                }
            })
        } else {
            chunkedArray.forEach((item, index) => {
                tmpCustomPdfDiv.push(getSinglePdfPageHtml(item[0], index, specDataChunkedArray[index][0]));
            })
        }

        setCustomPdfDiv(tmpCustomPdfDiv);
        setTimeout(() => {
            detailedReportModalOpenHandler();
        }, 2000); // You can adjust the delay as needed
    };

    const requestApproval = async (productId, index) => {
        setApprovalLoading(true);
        const payload = {
            productId,
            customerName,
            projectName
        }

        const approvalResult = await requestApprovalTrigger(payload);

        if(approvalResult.data) {
            deleteTableItem(index);
            setApprovalLoading(false);
            toast.success('Approval Request Created');
        } else {
            toast.error('Approved Request Failed!');
        }
    }

    const requestCalculation = async ( index) => {
        setApprovalLoading(true);
        const calculationRequestResult = await requestCalculationTrigger(noTableData[index]);

        if(calculationRequestResult.data) {
            deleteNoTableItem(index);
            setApprovalLoading(false);
            toast.success('Calculation Request Created');
        } else {
            toast.error('Calculation Request Failed!');
        }
    }

    const addCookie = (newData) => {
        const savedCookieValue = Cookies.get('SavedResult');
        const tmpDataTable = savedCookieValue === undefined ? [] : JSON.parse(savedCookieValue);
        tmpDataTable.push(newData.product_id);
        const jsonToSave = JSON.stringify(tmpDataTable);
        const expiration = new Date();
        expiration.setTime(expiration.getTime() + (30 * 60 * 1000));
        Cookies.set('SavedResult', jsonToSave, { expires: expiration });
    }

    const removeCookie = (productIdToRemove) => {
        const savedCookieTable = JSON.parse(Cookies.get('SavedResult'));
        const newCookieTable = savedCookieTable.filter(product => product !== productIdToRemove);
        const jsonToSave = JSON.stringify(newCookieTable);
        const expiration = new Date();
        expiration.setTime(expiration.getTime() + (30 * 60 * 1000));
        Cookies.set('SavedResult', jsonToSave, { expires: expiration });
    }

    const cleanCookie = () => {
        Cookies.remove('SavedResult');
        window.location.reload(false);
    }

    const saveResult = async (index) => {
        const tmpTableData = [...tableData];
        tmpTableData[index] = { ...tmpTableData[index], save: !tmpTableData[index].save };
        setTableData(tmpTableData);
        if( tableData[index].save ) {
            removeCookie(tmpTableData[index].product_id);
        } else {
            addCookie(tmpTableData[index]);
        }
    }

    const handleSelectionCheckbox = (index) => {
        const tmpTableData = [...tableData];
        tmpTableData[index] = { ...tmpTableData[index], isChecked: !tmpTableData[index].isChecked };
        setTableData(tmpTableData);
    }

    const createSpandrel = (index, color) => {
        const newTableData = [...tableData];

        const newSpandrelElement = {
            product_id: tableData[index].product_id,
            family: "N/A",
            product: `${tableData[index].product} ; Spandrel - ${color}`,
            description_visible: 'N/A',
            description_solar: 'N/A',
            description_uv: 'N/A',
            reflectance_out: 'N/A',
            reflectance_in: 'N/A',
            reflectance_solar: 'N/A',
            u_value_solar: tableData[index].u_value_solar,
            u_value_solar_metric: tableData[index].u_value_solar_metric,
            u_value_summer: tableData[index].u_value_summer,
            u_value_summer_metric: tableData[index].u_value_summer_metric,
            shading_coefficient: 'N/A',
            solar_heat_gain_coefficient: 'N/A',
            relative_heat_gain: 'N/A',
            relative_heat_gain_metric: 'N/A',
            light_solar_gain_ration: 'N/A',
            exist: true,
            save: false
        }

        newTableData.splice(index+1, 0, newSpandrelElement);
        setTableData(newTableData);
    }

    const editFamilyName = (index, name) => {
        const newElement = {
            family: name,
            product: tableData[index].product,
            description_visible: tableData[index].description_visible,
            description_solar: tableData[index].description_solar,
            description_uv: tableData[index].description_uv,
            reflectance_out: tableData[index].reflectance_out,
            reflectance_in: tableData[index].reflectance_in,
            reflectance_solar: tableData[index].reflectance_solar,
            u_value_solar: tableData[index].u_value_solar,
            u_value_solar_metric: tableData[index].u_value_solar_metric,
            u_value_summer: tableData[index].u_value_summer,
            u_value_summer_metric: tableData[index].u_value_summer_metric,
            shading_coefficient: tableData[index].shading_coefficient,
            solar_heat_gain_coefficient: tableData[index].solar_heat_gain_coefficient,
            relative_heat_gain: tableData[index].relative_heat_gain,
            relative_heat_gain_metric: tableData[index].relative_heat_gain_metric,
            light_solar_gain_ration: tableData[index].light_solar_gain_ration,
            exist: tableData[index].exist,
            save: tableData[index].save
        }

        setTableData(prevObjects => {
            const newObjects = [...prevObjects]; // Create a copy of the array
            newObjects[index] = { ...newObjects[index], ...newElement }; // Update the object at the given index
            return newObjects;
        });
    }

    const editProductDescription = (index, name) => {
        const newElement = {
            family: tableData[index].family,
            product: name,
            description_visible: tableData[index].description_visible,
            description_solar: tableData[index].description_solar,
            description_uv: tableData[index].description_uv,
            reflectance_out: tableData[index].reflectance_out,
            reflectance_in: tableData[index].reflectance_in,
            reflectance_solar: tableData[index].reflectance_solar,
            u_value_solar: tableData[index].u_value_solar,
            u_value_solar_metric: tableData[index].u_value_solar_metric,
            u_value_summer: tableData[index].u_value_summer,
            u_value_summer_metric: tableData[index].u_value_summer_metric,
            shading_coefficient: tableData[index].shading_coefficient,
            solar_heat_gain_coefficient: tableData[index].solar_heat_gain_coefficient,
            relative_heat_gain: tableData[index].relative_heat_gain,
            relative_heat_gain_metric: tableData[index].relative_heat_gain_metric,
            light_solar_gain_ration: tableData[index].light_solar_gain_ration,
            exist: tableData[index].exist,
            save: tableData[index].save
        }

        setTableData(prevObjects => {
            const newObjects = [...prevObjects]; // Create a copy of the array
            newObjects[index] = { ...newObjects[index], ...newElement }; // Update the object at the given index
            return newObjects;
        });
    }

    const spandrelModalSubmitFunction = (index, color) => {
        createSpandrel(index, color);
    }

    const spandrelModalOpenHandler = (index) => {
        setSpandrelModalOpen(true);
        setSpandrelIndex(index);
    }

    const specGenerator = (index) => {
        setSpecData(tableData[index]);
        // console.log(tableData[index]);
        setIsSpecModalOpen(true);
    }

    const editFamilyTypeModalOpenHandler = (index) => {
        setEditDefaultValue(tableData[index].family);
        setEditIndex(index);
        setEditFamilyTypeModalOpen(true);
    }

    const editProductDescriptionModalOpenHandler = (index) => {
        setEditDefaultValue(tableData[index].product);
        setEditIndex(index);
        setEditProductDescriptionModalOpen(true);
    }

    const conversionTrigger = () => {
        setIsMetric(!isMetric);
    };

    return (
        <section className="h-[calc(100vh_-_2rem)] w-full bg-white p-4 rounded shadow">
            {pageLoading &&
                <div className="fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-center">
                    <div className="inline-block animate-spin ease-linear rounded-full border-t-4 border-blue-500 border-solid h-12 w-12" />
                    <p className="mt-2 text-blue-500">Please wait until calculation end..</p>
                </div>}
            {approvalLoading &&
                <div className="fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-center">
                    <div className="inline-block animate-spin ease-linear rounded-full border-t-4 border-blue-500 border-solid h-12 w-12" />
                    <p className="mt-2 text-blue-500">Please wait requesting approval..</p>
                </div>}
            {loadingPdf &&
                <div className="fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-center">
                    <div className="inline-block animate-spin ease-linear rounded-full border-t-4 border-blue-500 border-solid h-12 w-12" />
                    <p className="mt-2 text-blue-500">Please wait until PDF is created...</p>
                </div>}
            {!pageLoading && !loadingPdf && !approvalLoading && (tableData.length > 0 ? tableData[0].relative_heat_gain === 'NaN' : false) && (tableData.length > 0 ? tableData[0].solar_heat_gain_coefficient === 'NaN' : false) &&
                <div className="flex flex-col w-full h-full justify-center items-center text-3xl font-semibold tracking-wide">
                    <img src="/images/not_found.png" alt="Not Found" className="w-auto h-1/2" />
                    <h2 className="p-24 text-center font-light">Performance data does not exist, and there is no component file available to generate performance values for that configuration. Please contact the administrator for assistance.</h2>
                </div>
            }
            {!pageLoading && !loadingPdf && !approvalLoading && (tableData.length > 0 ? tableData[0].relative_heat_gain !== 'NaN' : true) && (tableData.length > 0 ? tableData[0].solar_heat_gain_coefficient !== 'NaN' : true) &&
                <div className="flex flex-col h-full">
                    <div className="flex flex-row justify-between w-full">
                        <button
                            type="submit"
                            className="w-1/6"
                            onClick={() => navigate(`${DASHBOARD_PATH}`)}
                            aria-label="Return to Configurator"
                        >
                            <div className="flex flex-row">
                                <IconGoBack />
                                <h2 className="pl-2">Return to Configurator</h2>
                            </div>
                        </button>

                        <button
                            type="submit"
                            className="bg-[#047bc2] w-1/6 p-2 rounded text-white"
                            onClick={() => conversionTrigger()}
                        >
                            {isMetric ? 'Convert to Imperial' : 'Convert to Metric'}
                        </button>
                    </div>

                    {tableData.length > 0 &&
                        <>
                            <div className="flex flex-row w-full text-white text-sm pt-12">
                                <div className="w-[3%] h-20 flex justify-center items-center bg-[#0d3857] border-solid border-r-2 border-white group relative cursor-pointer">
                                <span className="opacity-0 group-hover:opacity-100 transition-opacity duration-200 absolute bottom-full left-1/2 -translate-x-1/2 bg-gray-800 text-white text-xs py-1 px-2 rounded-md">
                              Tooltip for Checkbox
                            </span>
                                </div>
                                <div className="w-[5%] h-20 flex justify-center items-center bg-[#0d3857] border-solid border-r-2 border-white group relative cursor-pointer">
                                    <h2 className="text-center">Glass Type</h2>
                                    <span className="opacity-0 group-hover:opacity-100 transition-opacity duration-200 absolute bottom-full left-1/2 -translate-x-1/2 bg-gray-800 text-white text-xs py-1 px-2 rounded-md">
                              Tooltip for Family
                            </span>
                                </div>
                                <div className="w-[17%] flex justify-center items-center bg-[#0d3857] border-solid border-r-2 border-white group relative cursor-pointer">
                                    <h2 className="text-center">Product</h2>
                                    <span className="opacity-0 group-hover:opacity-100 transition-opacity duration-200 absolute bottom-full left-1/2 -translate-x-1/2 bg-gray-800 text-white text-xs py-1 px-2 rounded-md">
                              Tooltip for Product
                            </span>
                                </div>
                                <div className="flex flex-col w-[15%]">
                                    <div className="h-10 flex flex-row justify-center items-center bg-[#0d3857] border-solid border-r-2 border-b-2 border-white group relative cursor-pointer">
                                        <h2>Transmission</h2>
                                        <span className="opacity-0 group-hover:opacity-100 transition-opacity duration-200 absolute bottom-full left-1/2 -translate-x-1/2 bg-gray-800 text-white text-xs py-1 px-2 rounded-md">
                                  Tooltip for Transmission
                                </span>
                                    </div>
                                    <div className="flex flex-row h-10">
                                        <h2 className="w-1/3 text-center bg-[#3d6078] border-solid border-r-2 border-white flex flex-row justify-center items-center">Visible</h2>
                                        <h2 className="w-1/3 text-center bg-[#557489] border-solid border-r-2 border-white flex flex-row justify-center items-center">Solar</h2>
                                        <h2 className="w-1/3 text-center bg-[#6e889a] border-solid border-r-2 border-white flex flex-row justify-center items-center">UV</h2>
                                    </div>
                                </div>
                                <div className="flex flex-col w-[15%]">
                                    <div className=" h-10 flex flex-row justify-center items-center bg-[#3b6178] border-solid border-r-2 border-b-2 border-white group relative cursor-pointer">
                                        <h2>Reflectance</h2>
                                        <span className="opacity-0 group-hover:opacity-100 transition-opacity duration-200 absolute bottom-full left-1/2 -translate-x-1/2 bg-gray-800 text-white text-xs py-1 px-2 rounded-md">
                                  Tooltip for Reflectance
                                </span>
                                    </div>
                                    <div className="flex flex-row h-10">
                                        <h2 className="w-1/3 text-center bg-[#628193] border-solid border-r-2 border-white flex flex-row justify-center items-center">Vis-Out</h2>
                                        <h2 className="w-1/3 text-center bg-[#7690a0] border-solid border-r-2 border-white flex flex-row justify-center items-center">Vis-In</h2>
                                        <h2 className="w-1/3 text-center bg-[#89a0ae] border-solid border-r-2 border-white flex flex-row justify-center items-center">Solar</h2>
                                    </div>
                                </div>
                                <div className="flex flex-col w-[10%]">
                                    <div className="h-10 flex flex-row justify-center items-center bg-[#1f6e9e] border-solid border-r-2 border-b-2 border-white group relative cursor-pointer">
                                        <h2>U-value</h2>
                                        <span className="opacity-0 group-hover:opacity-100 transition-opacity duration-200 absolute bottom-full left-1/2 -translate-x-1/2 bg-gray-800 text-white text-xs py-1 px-2 rounded-md">
                                  Tooltip for U-value
                                </span>
                                    </div>
                                    <div className="flex flex-row h-10">
                                        <h2 className="w-1/2 text-center bg-[#4b8bb1] border-solid border-r-2 border-white flex flex-row justify-center items-center">Winter</h2>
                                        <h2 className="w-1/2 text-center bg-[#6299bb] border-solid border-r-2 border-white flex flex-row justify-center items-center">Summer</h2>
                                    </div>
                                </div>
                                <div className="w-[5%] h-20 flex justify-center items-center bg-[#0079c1] border-solid border-r-2 border-white group relative cursor-pointer">
                                    <h2 className="text-center">SC</h2>
                                    <span className="opacity-0 group-hover:opacity-100 transition-opacity duration-200 absolute bottom-full left-1/2 -translate-x-1/2 bg-gray-800 text-white text-xs py-1 px-2 rounded-md">
                              Tooltip for SC
                            </span>
                                </div>
                                <div className="w-[5%] h-20 flex justify-center items-center bg-[#128acb] border-solid border-r-2 border-white group relative cursor-pointer">
                                    <h2 className="text-center">SHGC</h2>
                                    <span className="opacity-0 group-hover:opacity-100 transition-opacity duration-200 absolute bottom-full left-1/2 -translate-x-1/2 bg-gray-800 text-white text-xs py-1 px-2 rounded-md">
                              Tooltip for SHGC
                            </span>
                                </div>
                                <div className="w-[5%] h-20 flex justify-center items-center bg-[#3795ce] border-solid border-r-2 border-white group relative cursor-pointer">
                                    <h2 className="text-center">RHG</h2>
                                    <span className="opacity-0 group-hover:opacity-100 transition-opacity duration-200 absolute bottom-full left-1/2 -translate-x-1/2 bg-gray-800 text-white text-xs py-1 px-2 rounded-md">
                              Tooltip for RHG
                            </span>
                                </div>
                                <div className="w-[5%] h-20 flex justify-center items-center bg-[#3795ce] border-solid border-r-2 border-white group relative cursor-pointer">
                                    <h2 className="text-center">LSG</h2>
                                    <span className="opacity-0 group-hover:opacity-100 transition-opacity duration-200 absolute bottom-full left-1/2 -translate-x-1/2 bg-gray-800 text-white text-xs py-1 px-2 rounded-md">
                              Tooltip for LSG
                            </span>
                                </div>
                                <div className="w-[15%] h-20 flex justify-center items-center bg-[#3795ce]">
                                    <h2 className="text-center">Actions</h2>
                                </div>
                            </div>
                            {isError && <div className="flex flex-row w-full items-center justify-center mt-8"><h2>{tableData}</h2></div>}
                            {!isError && tableData.filter(element => element.relative_heat_gain !== 'NaN').map((item, index) => (
                                <div className="flex flex-row w-full h-auto text-xs">
                                    <div className="w-[3%] flex justify-center items-center bg-[#f3f6f7] border-solid border-r-2 border-b-2 border-white whitespace-pre-line">
                                        <h2 className="text-center">
                                            <input
                                                id="remember-me"
                                                name="selectCheckbox"
                                                type="checkbox"
                                                className="h-4 w-4 text-highlight-primary focus:ring-sky-500 border-gray-300 rounded mr-2"
                                                checked={item.isChecked}
                                                onChange={() => handleSelectionCheckbox(index)}
                                            />
                                        </h2>
                                    </div>
                                    <div className="w-[5%] flex justify-center items-center bg-[#f3f6f7] border-solid border-r-2 border-b-2 border-white">
                                        <h2 className="text-center">
                                            <button
                                                type="submit"
                                                onClick={() => editFamilyTypeModalOpenHandler(index)}
                                            >
                                                {item.family}
                                            </button>
                                        </h2>
                                    </div>
                                    <div className="w-[17%] flex justify-center items-center bg-[#f3f6f7] border-solid border-r-2 border-b-2 border-white whitespace-pre-line">
                                        <h2 className="text-center">
                                            <button
                                                type="submit"
                                                onClick={() => editProductDescriptionModalOpenHandler(index)}
                                            >
                                                {isMetric && String.raw`${item.productMetric.split(';').join(';\n')}`}
                                                {!isMetric && String.raw`${item.product.split(';').join(';\n')}`}
                                            </button>
                                        </h2>
                                    </div>
                                    <div className="w-[15%] flex justify-center items-center bg-[#ebf2f6] border-solid border-r-2 border-b-2 border-white">
                                        <h2 className="w-1/3 h-full flex justify-center items-center text-center border-solid border-r-2 border-white">{item.exist ? item.description_visible : 'TBD'}{item.description_visible !== 'N/A' && item.exist ? '%' : ''}</h2>
                                        <h2 className="w-1/3 h-full flex justify-center items-center text-center border-solid border-r-2 border-white">{item.exist ? item.description_solar : 'TBD'}{item.description_solar !== 'N/A' && item.exist ? '%' : ''}</h2>
                                        <h2 className="w-1/3 h-full flex justify-center items-center text-center">{item.exist ? item.description_uv : 'TBD'}{item.description_uv !== 'N/A' && item.exist ? '%' : ''}</h2>
                                    </div>
                                    <div className="w-[15%] flex justify-center items-center bg-[#ebf2f6] border-solid border-r-2 border-b-2 border-white">
                                        <h2 className="w-1/3 h-full flex justify-center items-center text-center border-solid border-r-2 border-white">{item.exist ? item.reflectance_out : 'TBD'}{item.reflectance_out !== 'N/A' && item.exist ? '%' : ''}</h2>
                                        <h2 className="w-1/3 h-full flex justify-center items-center text-center border-solid border-r-2 border-white">{item.exist ? item.reflectance_in : 'TBD'}{item.reflectance_in !== 'N/A' && item.exist ? '%' : ''}</h2>
                                        <h2 className="w-1/3 h-full flex justify-center items-center text-center">{item.exist ? item.reflectance_solar : 'TBD'}{item.reflectance_solar !== 'N/A' && item.exist ? '%' : ''}</h2>
                                    </div>
                                    <div className="w-[10%] flex justify-center items-center bg-[#ebf2f6] border-solid border-r-2 border-b-2 border-white">
                                        {/* eslint-disable-next-line no-nested-ternary */}
                                        <h2 className="w-1/2 h-full flex justify-center items-center text-center border-solid border-r-2 border-white">{item.exist ? isMetric ? item.u_value_solar_metric : item.u_value_solar : 'TBD'}</h2>
                                        {/* eslint-disable-next-line no-nested-ternary */}
                                        <h2 className="w-1/2 h-full flex justify-center items-center text-center">{item.exist ? isMetric ? item.u_value_summer_metric : item.u_value_summer : 'TBD'}</h2>
                                    </div>
                                    <div className="w-[5%] flex justify-center items-center bg-[#ebf2f6] border-solid border-r-2 border-b-2 border-white">
                                        <h2 className="text-center">{item.exist ? item.shading_coefficient : 'TBD'}</h2>
                                    </div>
                                    <div className="w-[5%] flex justify-center items-center bg-[#ebf2f6] border-solid border-r-2 border-b-2 border-white">
                                        <h2 className="text-center">{item.exist ? item.solar_heat_gain_coefficient : 'TBD'}</h2>
                                    </div>
                                    <div className="w-[5%] flex justify-center items-center bg-[#ebf2f6] border-solid border-r-2 border-b-2 border-white">
                                        {/* eslint-disable-next-line no-nested-ternary */}
                                        <h2 className="text-center">{item.exist ? isMetric ? item.relative_heat_gain_metric : item.relative_heat_gain : 'TBD'}</h2>
                                    </div>
                                    <div className="w-[5%] flex justify-center items-center bg-[#ebf2f6] border-solid border-r-2 border-b-2 border-white">
                                        <h2 className="text-center">{item.exist ? item.light_solar_gain_ration : 'TBD'}</h2>
                                    </div>
                                    <div className="w-[15%] flex justify-center items-center bg-[#ebf2f6] border-solid border-r-2 border-b-2 border-white">
                                        {item.exist &&
                                            <div className="flex flex-row w-full justify-evenly text-sm text-[#0d3857]">
                                                <button
                                                    type="submit"
                                                    onClick={() => spandrelModalOpenHandler(index)}
                                                    className="p-2 group relative cursor-pointer"
                                                    aria-label="Spandrel Creation"
                                                >
                                                    <IconSpandrel />
                                                    <span className="opacity-0 group-hover:opacity-100 transition-opacity duration-100 absolute bottom-full left-1/2 -translate-x-1/2 bg-gray-800 text-white text-xs py-1 px-1 rounded-md">
                                                Spandrel
                                                </span>
                                                </button>
                                                <button
                                                    type="submit"
                                                    onClick={() => specGenerator(index)}
                                                    className="p-2 group relative cursor-pointer"
                                                    aria-label="Spec Generator"
                                                >
                                                    <IconSpecGenerator />
                                                    <span className="opacity-0 group-hover:opacity-100 transition-opacity duration-100 absolute bottom-full left-1/2 -translate-x-1/2 bg-gray-800 text-white text-xs py-1 px-1 rounded-md">
                                                    Spec Generation
                                                    </span>
                                                </button>
                                                <button
                                                    type="submit"
                                                    onClick={() => saveResult(index)}
                                                    className="p-2 group relative cursor-pointer"
                                                    aria-label="Single PDF Export"
                                                >
                                                    {item.save ? <IconStarYellow /> : <IconStar />}
                                                    <span className="opacity-0 group-hover:opacity-100 transition-opacity duration-100 absolute bottom-full left-1/2 -translate-x-1/2 bg-gray-800 text-white text-xs py-1 px-1 rounded-md">
                                                Favourites
                                                </span>
                                                </button>
                                                <button
                                                    type="submit"
                                                    onClick={() => generatePdf(false, true, index)}
                                                    className="p-2 group relative cursor-pointer"
                                                    aria-label="Single PDF Export"
                                                >
                                                    <IconPdf />
                                                    <span className="opacity-0 group-hover:opacity-100 transition-opacity duration-100 absolute bottom-full left-1/2 -translate-x-1/2 bg-gray-800 text-white text-xs py-1 px-1 rounded-md">
                                                PDF
                                                </span>
                                                </button>
                                                <NewSingleExcelExporter excelData={tableData[index]} customerName={customerName} projectName={projectName} isMetric={isMetric} />
                                                <button
                                                    type="submit"
                                                    onClick={() => deleteTableItem(index)}
                                                    className="p-2 group relative cursor-pointer"
                                                    aria-label="Delete"
                                                >
                                                    <IconTrash />
                                                    <span className="opacity-0 group-hover:opacity-100 transition-opacity duration-100 absolute bottom-full left-1/2 -translate-x-1/2 bg-gray-800 text-white text-xs py-1 px-1 rounded-md">
                                                Delete
                                                </span>
                                                </button>
                                            </div>
                                        }
                                        {!item.exist &&
                                            <div className="flex flex-row w-full justify-evenly items-center">
                                                <div className="text-red-500">
                                                    <WarningIcon />
                                                </div>
                                                <button
                                                    type="submit"
                                                    aria-label="Request Approval"
                                                    className="bg-[#047bc2] text-white pl-4 pr-4 pt-2 pb-2 text-sm rounded"
                                                    onClick={() => requestApproval(item.product_id, index)}
                                                >
                                                    Request Approval
                                                </button>
                                            </div>
                                        }
                                    </div>
                                    {/* Designer Function Part End */}
                                </div>
                            ))}
                            <div className="pt-8 flex flex-row justify-center pb-4">
                                <div>
                                    <button type="submit" onClick={() => generatePdf(true, true)} className="bg-[#047bc2] text-white pl-4 pr-4 pt-2 pb-2 rounded">
                                        <div className="flex flex-row">
                                            <IconPdf />
                                            <div className="w-2" />
                                            <span>Perf Data w/Product Name</span>
                                        </div>
                                    </button>
                                </div>
                                <div className="w-8" />
                                <div>
                                    <button type="submit" onClick={() => generatePdf(true, false)} className="bg-[#047bc2] text-white pl-4 pr-4 pt-2 pb-2 rounded">
                                        <div className="flex flex-row">
                                            <IconPdf />
                                            <div className="w-2" />
                                            <span>Download PDF</span>
                                        </div>
                                    </button>
                                </div>
                                <div className="w-8" />
                                <NewExcelExporter excelData={tableData.filter(obj => obj.exist && obj.isChecked)} isMetric={isMetric} />
                                <div className="w-8" />
                                <div>
                                    <button type="submit" onClick={() => cleanCookie()} className="bg-[#047bc2] text-white pl-4 pr-4 pt-2 pb-2 rounded">
                                        <div className="flex flex-row">
                                            <IconTrash />
                                            <div className="w-2" />
                                            <span>Clear Favourites</span>
                                        </div>
                                    </button>
                                </div>
                            </div>
                        </>
                    }
                    {noTableData.length > 0 &&
                        <>
                            <div className="flex flex-row w-full text-white text-sm pt-12">
                                <div className="w-[85%] h-20 flex justify-center items-center bg-[#0d3857] border-solid border-r-2 border-white group relative cursor-pointer">
                                    <h2 className="text-center">Looking for something else?</h2>
                                    <span className="opacity-0 group-hover:opacity-100 transition-opacity duration-200 absolute bottom-full left-1/2 -translate-x-1/2 bg-gray-800 text-white text-xs py-1 px-2 rounded-md">
                                      Looking for something else?
                                    </span>
                                </div>
                                <div className="w-[15%] h-20 flex justify-center items-center bg-[#3795ce]">
                                    <h2 className="text-center">Actions</h2>
                                </div>
                            </div>
                            {!isError && noTableData.map((item, index) => (
                                <div className="flex flex-row w-full h-auto text-xs">
                                    <div className="w-[85%] flex justify-center items-center bg-[#ebf2f6] border-solid border-r-2 border-b-2 border-white">
                                        <h2 className="text-center">{item.description}</h2>
                                    </div>
                                    <div className="w-[15%] flex justify-center items-center bg-[#ebf2f6] border-solid border-r-2 border-b-2 border-white">
                                        <div className="flex flex-row w-full justify-evenly items-center">
                                            <div className="text-red-500">
                                                <WarningIcon />
                                            </div>
                                            <button
                                                type="submit"
                                                aria-label="Request Approval"
                                                className="bg-[#047bc2] text-white pl-4 pr-4 pt-2 pb-2 text-sm rounded m-1"
                                                onClick={() => requestCalculation(index)}
                                            >
                                                Notify Tech Services
                                            </button>
                                        </div>
                                    </div>
                                    {/* Designer Function Part End */}
                                </div>
                            ))}
                        </>
                    }
                </div>
            }
            <PdfDownloadModal
                open={isDownloadModalOpen}
                pdfPageLayout={pdfPageLayout}
                onClose={() => setDownloadModalOpen(false)}
            />

            <SpandrelModal
                open={isSpandrelModalOpen}
                submitFunction ={spandrelModalSubmitFunction}
                spandrelIndex = {spandrelIndex}
                onClose={() => setSpandrelModalOpen(false)}
            />

            {isSpecModalOpen &&
                <SpecModal
                    open={isSpecModalOpen}
                    onClose={() => setIsSpecModalOpen(false)}
                    data={specData}
                />
            }

            <EditFamilyModal
                open={isEditFamilyTypeModalOpen}
                editFunction={editFamilyName}
                editIndex={editIndex}
                defaultValue={editDefaultValue}
                onClose={() => setEditFamilyTypeModalOpen(false)}
            />

            <EditProductDescriptionModal
                open={isEditProductDescriptionModal}
                editFunction={editProductDescription}
                editIndex={editIndex}
                defaultValue={editDefaultValue}
                onClose={() => setEditProductDescriptionModalOpen(false)}
            />

            {/* PDF Start */}
            <div>
                {customPdfDiv}
            </div>
            {/* PDF End */}
        </section>
    );
}


export default Report;