import { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Tab } from '@headlessui/react';
import LargeModal from '../../components/Modal/LargeModal';
import ShipmentDetailForm from '../SampleReport/ShipmentDetailForm';

const EditOrderDetailModal = ({
                              open, onClose, data, updateFunction, orderId
                          }) => {
    const onCloseButtonClickHandler = () => {
        onClose();
    };

    const onSubmitHandler = async (sampleData, formData) => {
        updateFunction(formData, orderId);
        onCloseButtonClickHandler();
    };

    return (
        <LargeModal open={open} setOpen={onCloseButtonClickHandler}>
            <div className="px-2">
                <Tab.Group>
                    <Tab.Panels>
                        <ShipmentDetailForm data={[]}  prevFormData={data} submitFunction={onSubmitHandler} buttonLabel="Update Order"/>
                    </Tab.Panels>
                </Tab.Group>
            </div>
        </LargeModal>
    );
};

EditOrderDetailModal.propTypes = {
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    data: PropTypes.object.isRequired,
    updateFunction: PropTypes.func.isRequired,
    orderId: PropTypes.string.isRequired,
};

export default EditOrderDetailModal;
