import { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Tab } from '@headlessui/react';
import toast from 'react-hot-toast';
import Modal from '../../components/Modal/Modal';
import { useGetProductSpecDataMutation } from '../../store/services/api';

const SpecModal = ({
                           open, onClose, data
                       }) => {
    const [triggerProductSpec, resultProductSpec] = useGetProductSpecDataMutation();
    const [pageLoading, setPageLoading] = useState(true);
    const [specData, setSpecData] = useState({});
    const onCloseButtonClickHandler = () => {
        onClose();
    };

    useEffect(() => {
        if (resultProductSpec.data) {
            console.log(resultProductSpec.data);
            setSpecData(resultProductSpec.data);
            setPageLoading(false);
        }
    }, [resultProductSpec.isSuccess]);

    useEffect(() => {
        if(data) {
            triggerProductSpec(data.product_id);
            // console.log(data);
        }
    }, [data]);

    const divRef = useRef(null);

    const copyText = () => {
        if (divRef.current) {
            const textToCopy = divRef.current.innerText;
            navigator.clipboard.writeText(textToCopy)
                .then(() => toast.success('Product spec copied successfully!'))
                .catch((err) => toast.error('Product spec copy failed!'));
        }
    };

    return (
        <Modal open={open} setOpen={onCloseButtonClickHandler}>
            <div className="px-2">
                <Tab.Group>
                    <Tab.Panels>
                        {pageLoading &&
                            <div className="text-center">
                                <div className="inline-block animate-spin ease-linear rounded-full border-t-4 border-blue-500 border-solid h-12 w-12" />
                                <p className="mt-2 text-blue-500">Please wait spec data generation..</p>
                            </div>
                        }
                        {!pageLoading &&
                            <>
                                <div ref={divRef} className="p-2 text-sm">
                                    <h1 className="text-lg font-bold">A. Glass Make-ups</h1>
                                    <h1 className="mt-2">1. {specData.top_line_imp ? specData.top_line_imp : 'N/A'}</h1>
                                    <div className="pl-4">
                                        {specData.spec_imp ? specData.spec_imp.map((spec, index) => (
                                            <h2>{index+1}. {spec}</h2>
                                        )) : 'N/A'}
                                    </div>
                                    <h2 className="text-lg font-bold mt-2">2. Performance Requirements</h2>
                                    <div className="pl-4">
                                        <h2>1. Visible Light Transmittance: {data.description_visible}%</h2>
                                        <h2>2. Exterior Reflectance: {data.reflectance_out}%</h2>
                                        <h2>3. Winter U-Value: {data.u_value_solar}</h2>
                                        <h2>4. Summer U-Value: {data.u_value_summer}</h2>
                                        <h2>5. Shading Coefficient: {data.shading_coefficient}</h2>
                                        <h2>6. Solar Heat Gain Coefficient: {data.solar_heat_gain_coefficient}</h2>
                                        <h2>7. Light to Solar Gain Ratio: {data.light_solar_gain_ration}</h2>
                                    </div>
                                    <p className="mt-4 font-bold">*fully tempered, 100% heat soak recommended unless code or
                                        glazing conditions allow otherwise</p>
                                </div>
                                <button type="submit" onClick={copyText} className="flex flex-row w-full items-end justify-end font-bold">Copy to Clipboard</button>
                            </>
                        }
                    </Tab.Panels>
                </Tab.Group>
            </div>
        </Modal>
    );
};

SpecModal.propTypes = {
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    data: PropTypes.object.isRequired,
};

export default SpecModal;
