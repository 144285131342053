export const PRODUCT_TYPE_CONSTANTS = [
    {
        id: 1,
        name: "Qtr/Annually Run Coatings",
        leadtime: 20,
    },
    {
        id: 2,
        name: "DigitalDistinctions",
        leadtime: 20,
    },
    {
        id: 3,
        name: "Special Fab - All Products",
        leadtime: 10,
    },
    {
        id: 4,
        name: "TLI, Mono, Lami or IG - Custom Print or Paint",
        leadtime: 10,
    },
    {
        id: 5,
        name: "TLI, Mono, Lami or IG - Standard Print or Paint",
        leadtime: 7,
    },
    {
        id: 6,
        name: "TLI - Standard Coatings",
        leadtime: 7,
    },
    {
        id: 7,
        name: "Mono, Lami or IG - Standard Coatings",
        leadtime: 4,
    },
    {
        id: 8,
        name: "Kits",
        leadtime: 2,
    },
]

export const SALE_TERRITORY_TYPE_CONSTANTS = [
    {
        id: 7,
        name: "Hoffman",
        email: ""
    },
    {
        id: 8,
        name: "Hoffman",
        email: ""
    },
    {
        id: 11,
        name: "Davidson",
        email: ""
    },
    {
        id: 13,
        name: "Sawyer",
        email: ""
    },
    {
        id: 14,
        name: "Martineau & Co.",
        email: ""
    },
    {
        id: 15,
        name: "OPEN",
        email: ""
    },
    {
        id: 17,
        name: "Hawkins",
        email: ""
    },
    {
        id: 18,
        name: "Sweet",
        email: ""
    },
    {
        id: 22,
        name: "Urich",
        email: ""
    },
    {
        id: 23,
        name: "Carlson",
        email: ""
    },
    {
        id: 24,
        name: "Krier",
        email: ""
    },
    {
        id: 25,
        name: "Honaman",
        email: ""
    },
    {
        id: 32,
        name: "Winkler",
        email: ""
    },
    {
        id: 33,
        name: "Johnson",
        email: ""
    },
    {
        id: 35,
        name: "PGP",
        email: ""
    },
    {
        id: 37,
        name: "Welker",
        email: ""
    },
    {
        id: 38,
        name: "Rigot",
        email: ""
    },
    {
        id: 40,
        name: "OPEN",
        email: ""
    },
    {
        id: 41,
        name: "Bauman",
        email: ""
    },
    {
        id: 42,
        name: "O'Neill",
        email: ""
    },
    {
        id: 43,
        name: "JPaal",
        email: ""
    },
    {
        id: 44,
        name: "BPaal",
        email: ""
    },
    {
        id: 45,
        name: "Stephens",
        email: ""
    },
    {
        id: 46,
        name: "OPEN",
        email: ""
    },
    {
        id: 50,
        name: "C. Am./Trin. & Tob.",
        email: ""
    },
    {
        id: 50,
        name: "South America",
        email: ""
    },
    {
        id: 51,
        name: "Mexico",
        email: ""
    },
    {
        id: 58,
        name: "Costa Rica",
        email: ""
    },
    {
        id: 60,
        name: "S. Korea",
        email: ""
    },
    {
        id: 61,
        name: "Vietnam",
        email: ""
    },
    {
        id: 64,
        name: "New Zealand",
        email: ""
    },
    {
        id: 66,
        name: "Philippines",
        email: ""
    },
    {
        id: 67,
        name: "Singapore - F Xuan",
        email: ""
    },
    {
        id: 68,
        name: "S.E. Asia",
        email: ""
    },
    {
        id: 68,
        name: "S.E. Asia - Taiwan",
        email: ""
    },
    {
        id: 73,
        name: "Middle East",
        email: ""
    },
    {
        id: 77,
        name: "S. Africa",
        email: ""
    }
]

export const PROJECT_TYPE_CONSTANTS = [
    {
        id: 10,
        name: "Entertainment"
    },
    {
        id: 20,
        name: "Government"
    },
    {
        id: 30,
        name: "Educational"
    },
    {
        id: 40,
        name: "Medical"
    },
    {
        id: 50,
        name: "Hotels/Motels"
    },
    {
        id: 60,
        name: "Manufacturing"
    },
    {
        id: 70,
        name: "Multifamily Housing"
    },
    {
        id: 80,
        name: "Comm. Office Space"
    },
    {
        id: 90,
        name: "Airport/Transportation"
    },
    {
        id: 100,
        name: "Detention"
    },
    {
        id: 110,
        name: "Religious"
    },
    {
        id: 120,
        name: "Store/Shopping Center"
    },
    {
        id: 130,
        name: "Warehouse"
    },
    {
        id: 140,
        name: "Misc"
    }
]

export const DESTINATION_CONSTANTS = [
    {
        id: 1,
        name: 'Domestic',
    },
    {
        id: 2,
        name: 'International',
    }
]

export const COUNTRY_CONSTANT = [
    {
        "id": 1,
        "name": "Canada",
        "leadtime": 4
    },
    {
        "id": 2,
        "name": "Argentina",
        "leadtime": 10
    },
    {
        "id": 3,
        "name": "Australia",
        "leadtime": 10
    },
    {
        "id": 4,
        "name": "Bahamas",
        "leadtime": 10
    },
    {
        "id": 5,
        "name": "Cambodia",
        "leadtime": 10
    },
    {
        "id": 6,
        "name": "Cape Verde",
        "leadtime": 10
    },
    {
        "id": 7,
        "name": "Chile",
        "leadtime": 10
    },
    {
        "id": 8,
        "name": "China",
        "leadtime": 10
    },
    {
        "id": 9,
        "name": "Costa Rica",
        "leadtime": 10
    },
    {
        "id": 10,
        "name": "Dominican Republic",
        "leadtime": 10
    },
    {
        "id": 11,
        "name": "Guatemala",
        "leadtime": 10
    },
    {
        "id": 12,
        "name": "Hong Kong",
        "leadtime": 10
    },
    {
        "id": 13,
        "name": "Indonesia",
        "leadtime": 10
    },
    {
        "id": 14,
        "name": "Korea, Republic of",
        "leadtime": 10
    },
    {
        "id": 15,
        "name": "Kuwait",
        "leadtime": 10
    },
    {
        "id": 16,
        "name": "Macao",
        "leadtime": 10
    },
    {
        "id": 17,
        "name": "Mexico",
        "leadtime": 10
    },
    {
        "id": 18,
        "name": "New Zealand",
        "leadtime": 10
    },
    {
        "id": 19,
        "name": "Nicaragua",
        "leadtime": 10
    },
    {
        "id": 20,
        "name": "Oman",
        "leadtime": 10
    },
    {
        "id": 21,
        "name": "Panama",
        "leadtime": 10
    },
    {
        "id": 22,
        "name": "Puerto Rico",
        "leadtime": 10
    },
    {
        "id": 23,
        "name": "Qatar",
        "leadtime": 10
    },
    {
        "id": 24,
        "name": "Singapore",
        "leadtime": 10
    },
    {
        "id": 25,
        "name": "Taiwan, Province of China",
        "leadtime": 10
    },
    {
        "id": 26,
        "name": "Thailand",
        "leadtime": 10
    },
    {
        "id": 27,
        "name": "Trinidad and Tobago",
        "leadtime": 10
    },
    {
        "id": 28,
        "name": "Turks and Caicos Islands",
        "leadtime": 10
    },
    {
        "id": 29,
        "name": "United Arab Emirates",
        "leadtime": 10
    },
    {
        "id": 30,
        "name": "United Kingdom",
        "leadtime": 10
    },
    {
        "id": 31,
        "name": "Viet Nam",
        "leadtime": 10
    },
    {
        "id": 32,
        "name": "Virgin Islands, U.S.",
        "leadtime": 10
    },
    {
        "id": 33,
        "name": "Brazil",
        "leadtime": 10
    },
    {
        "id": 34,
        "name": "Germany",
        "leadtime": 10
    },
    {
        "id": 35,
        "name": "Israel",
        "leadtime": 10
    },
    {
        "id": 36,
        "name": "Italy",
        "leadtime": 10
    },
    {
        "id": 37,
        "name": "Turkey",
        "leadtime": 10
    }
]

export const STATE_CONSTANTS = [
    {
        id: 2,
        name: "AK",
        leadtime: 2
    },
    {
        id: 3,
        name: "AL",
        leadtime: 3
    },
    {
        id: 4,
        name: "AR",
        leadtime: 3
    },
    {
        id: 5,
        name: "AZ",
        leadtime: 4
    },
    {
        id: 6,
        name: "CA",
        leadtime: 4
    },
    {
        id: 7,
        name: "CO",
        leadtime: 3
    },
    {
        id: 8,
        name: "CT",
        leadtime: 4
    },
    {
        id: 9,
        name: "DC",
        leadtime: 3
    },
    {
        id: 10,
        name: "DE",
        leadtime: 3
    },
    {
        id: 11,
        name: "FL",
        leadtime: 4
    },
    {
        id: 12,
        name: "GA",
        leadtime: 4
    },
    {
        id: 13,
        name: "HI",
        leadtime: 2
    },
    {
        id: 14,
        name: "IA",
        leadtime: 2
    },
    {
        id: 15,
        name: "ID",
        leadtime: 4
    },
    {
        id: 16,
        name: "IL",
        leadtime: 2
    },
    {
        id: 17,
        name: "IN",
        leadtime: 2
    },
    {
        id: 18,
        name: "KS",
        leadtime: 2
    },
    {
        id: 19,
        name: "KY",
        leadtime: 2
    },
    {
        id: 20,
        name: "LA",
        leadtime: 3
    },
    {
        id: 21,
        name: "MA",
        leadtime: 4
    },
    {
        id: 22,
        name: "MD",
        leadtime: 3
    },
    {
        id: 23,
        name: "ME",
        leadtime: 4
    },
    {
        id: 24,
        name: "MI",
        leadtime: 2
    },
    {
        id: 25,
        name: "MN",
        leadtime: 1
    },
    {
        id: 26,
        name: "MO",
        leadtime: 2
    },
    {
        id: 27,
        name: "MS",
        leadtime: 3
    },
    {
        id: 28,
        name: "MT",
        leadtime: 3
    },
    {
        id: 29,
        name: "NC",
        leadtime: 3
    },
    {
        id: 30,
        name: "ND",
        leadtime: 2
    },
    {
        id: 31,
        name: "NE",
        leadtime: 2
    },
    {
        id: 32,
        name: "NH",
        leadtime: 4
    },
    {
        id: 33,
        name: "NJ",
        leadtime: 4
    },
    {
        id: 34,
        name: "NM",
        leadtime: 3
    },
    {
        id: 35,
        name: "NV",
        leadtime: 4
    },
    {
        id: 36,
        name: "NY",
        leadtime: 4
    },
    {
        id: 37,
        name: "OH",
        leadtime: 2
    },
    {
        id: 38,
        name: "OK",
        leadtime: 3
    },
    {
        id: 39,
        name: "OR",
        leadtime: 4
    },
    {
        id: 40,
        name: "PA",
        leadtime: 4
    },
    {
        id: 41,
        name: "RI",
        leadtime: 4
    },
    {
        id: 42,
        name: "SC",
        leadtime: 3
    },
    {
        id: 43,
        name: "SD",
        leadtime: 2
    },
    {
        id: 44,
        name: "TN",
        leadtime: 3
    },
    {
        id: 45,
        name: "TX",
        leadtime: 3
    },
    {
        id: 46,
        name: "UT",
        leadtime: 4
    },
    {
        id: 47,
        name: "VA",
        leadtime: 3
    },
    {
        id: 48,
        name: "VT",
        leadtime: 4
    },
    {
        id: 49,
        name: "WA",
        leadtime: 4
    },
    {
        id: 50,
        name: "WI",
        leadtime: 2
    },
    {
        id: 51,
        name: "WV",
        leadtime: 3
    },
    {
        id: 52,
        name: "WY",
        leadtime: 3
    }
]

export const QUANTITY_CONSTANTS = [
    {
        id: 1,
        value: 1,
    },
    {
        id: 2,
        value: 2,
    },
    {
        id: 3,
        value: 3,
    },
    {
        id: 4,
        value: 4,
    },
    {
        id: 5,
        value: 5,
    },
]

export const SAMPLE_SIZE_CONSTANTS = [
    {
        id: 1,
        value: '12x12',
    },
    {
        id: 2,
        value: '5x7',
    },
    {
        id: 3,
        value: '4x6',
    },
]

export const ORDER_STATUS_CONSTANTS = [
    {
        id: 1,
        value: 'Pending',
    },
    {
        id: 2,
        value: 'In Production',
    },
    {
        id: 3,
        value: 'Ready For Shipment',
    },
    {
        id: 4,
        value: 'Shipping',
    },
    {
        id: 5,
        value: 'Completed',
    },
]