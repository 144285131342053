import { ROLE_ADMIN, ROLE_GLASS_DESIGNER, ROLE_SALES } from './constants';


export const DASHBOARD_PATH = '/dashboard';
export const REPORT_PATH = '/report';
export const LOGIN_PATH = '/login';
export const APPROVAL_DASHBOARD_PATH = '/admin_approval';
export const CONFIGURATION_DATABASE_PATH = '/configuration_database';
export const COMPONENT_DATABASE_PATH = '/component_database';
export const COMPONENT_REQUEST_PATH = '/component_request';
export const SEARCH_SAMPLE_PATH = '/search_sample';
export const SAMPLE_REPORT_PATH = '/sample_report';
export const ORDER_DASHBOARD_PATH = '/order_dashboard';

export const ALLOWED_ROLES = {
    [DASHBOARD_PATH]: [ROLE_ADMIN, ROLE_GLASS_DESIGNER],
    [REPORT_PATH]: [ROLE_ADMIN, ROLE_GLASS_DESIGNER],
    [APPROVAL_DASHBOARD_PATH]: [ROLE_ADMIN],
    [CONFIGURATION_DATABASE_PATH]: [ROLE_ADMIN],
    [COMPONENT_DATABASE_PATH]: [ROLE_ADMIN],
    [COMPONENT_REQUEST_PATH]: [ROLE_ADMIN],
    [SEARCH_SAMPLE_PATH]: [ROLE_SALES],
    [SAMPLE_REPORT_PATH]: [ROLE_SALES],
    [ORDER_DASHBOARD_PATH]: [ROLE_SALES],
};