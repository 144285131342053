import toast from 'react-hot-toast';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useLogoutMutation } from '../../store/services/logout';
import { getUser } from '../../store/slices/user';
import DropdownMenu from '../DropdownMenu/DropdownMenu';
import DropdownMenuItem from '../DropdownMenu/DropdownMenuItem';
import IconThreeDot from '../Icon/IconThreeDot';
import IconShieldCheck from '../Icon/IconShieldCheck';
import IconDatabase from '../Icon/DatabaseIcon';
import {
    APPROVAL_DASHBOARD_PATH,
    COMPONENT_DATABASE_PATH,
    COMPONENT_REQUEST_PATH,
    CONFIGURATION_DATABASE_PATH,
    DASHBOARD_PATH, ORDER_DASHBOARD_PATH, SEARCH_SAMPLE_PATH
} from '../../routes';
import IconHome from '../Icon/IconHome';
import IconComponent from '../Icon/IconComponent';
import IconDocumentCheck from '../Icon/IconDocumentCheck';

const Sidebar = () => {
    const [logout] = useLogoutMutation();
    const user = useSelector(getUser);
    const navigate = useNavigate();

    const onSignOutClickHandler = async () => {
        try {
            await logout();
            toast.success('Log out success');
        } catch (error) {
            toast.error('Log out fail');
        }
    };

    return (
        <div>
            <div
                className="flex flex-col fixed bg-login-image bg-no-repeat h-full text-white text-base w-[263px]">
                <div className="h-1/6 lg:w-36 p-2 m-16 object-cover bg-login-logo bg-no-repeat bg-contain" />
                <div className="h-4/6 flex flex-col justify-end">
                    {user.role === 'sale' &&
                        <>
                            <button
                                onClick={() => navigate(`${ORDER_DASHBOARD_PATH}`)}
                                type="submit"
                            >
                                <div className="flex flex-wrap">
                                    <div className='mt-4 ml-2 text-white'>
                                        <IconHome />
                                    </div>
                                    <nav className="mt-4 flex flex-col px-4 mb-2 text-white">
                                        Order Dashboard
                                    </nav>
                                </div>
                            </button>
                            <button
                                onClick={() => navigate(`${SEARCH_SAMPLE_PATH}`)}
                                type="submit"
                            >
                                <div className="flex flex-wrap">
                                    <div className='mt-4 ml-2 text-white'>
                                        <IconHome />
                                    </div>
                                    <nav className="mt-4 flex flex-col px-4 mb-2 text-white">
                                        Sample Configuration
                                    </nav>
                                </div>
                            </button>
                        </>
                    }
                    {user.role === 'admin' &&
                        <>
                            <button
                                onClick={() => navigate(`${DASHBOARD_PATH}`)}
                                type="submit"
                            >
                                <div className="flex flex-wrap">
                                    <div className='mt-4 ml-2 text-white'>
                                        <IconHome />
                                    </div>
                                    <nav className="mt-4 flex flex-col px-4 mb-2 text-white">
                                        Configuration Page
                                    </nav>
                                </div>
                            </button>
                            <button
                                onClick={() => navigate(`${APPROVAL_DASHBOARD_PATH}`)}
                                type="submit"
                            >
                                <div className="flex flex-wrap">
                                    <div className='mt-4 ml-2 text-white'>
                                        <IconShieldCheck />
                                    </div>
                                    <nav className="mt-4 flex flex-col px-4 mb-2 text-white">
                                        Configuration Approval
                                    </nav>
                                </div>
                            </button>
                            <button
                                onClick={() => navigate(`${CONFIGURATION_DATABASE_PATH}`)}
                                type="submit"
                            >
                                <div className="flex flex-wrap">
                                    <div className='mt-4 ml-2 text-white'>
                                        <IconDatabase />
                                    </div>
                                    <nav className="mt-4 flex flex-col px-4 mb-2 text-white">
                                        Product Database
                                    </nav>
                                </div>
                            </button>
                            <button
                                onClick={() => navigate(`${COMPONENT_DATABASE_PATH}`)}
                                type="submit"
                            >
                                <div className="flex flex-wrap">
                                    <div className='mt-4 ml-2 text-white'>
                                        <IconComponent />
                                    </div>
                                    <nav className="mt-4 flex flex-col px-4 mb-2 text-white">
                                        Component Database
                                    </nav>
                                </div>
                            </button>
                            <button
                                onClick={() => navigate(`${COMPONENT_REQUEST_PATH}`)}
                                type="submit"
                            >
                                <div className="flex flex-wrap mb-4">
                                    <div className='mt-4 ml-2 text-white'>
                                        <IconDocumentCheck />
                                    </div>
                                    <nav className="mt-4 flex flex-col px-4 mb-2 text-white">
                                        Component Request
                                    </nav>
                                </div>
                            </button>
                        </>
                    }
                </div>
                <div className="flex-shrink-0 flex border-t border-gray-200 p-4 bg-transparent">
                    {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                    <a href="#" className="flex-shrink-0 w-full group block">
                        <div className="flex items-center">
                            <div className="ml-3 flex-grow">
                                <p className="text-sm font-medium text-white">
                                    {user.firstName + user.lastName}
                                </p>
                                <p className="text-xs font-medium text-base-secondary text-white capitalize">
                                    {user.role}
                                </p>
                            </div>
                            <div>
                                <DropdownMenu
                                    position="above"
                                    menuButton={(
                                        <>
                                            <span className="sr-only">Open options</span>
                                            <IconThreeDot />
                                        </>
                                    )}
                                >
                                    <DropdownMenuItem onItemClick={onSignOutClickHandler}>Sign Out</DropdownMenuItem>
                                </DropdownMenu>
                            </div>
                        </div>
                    </a>
                </div>
            </div>
        </div>
    );
}

export default Sidebar