const DashboardTableTop = () => (
    <div className="flex flex-row w-full text-white text-sm pt-4 h-16">
        <div className="w-[15%] flex justify-center items-center bg-[#0d3857] border-solid border-r-2 border-white group relative cursor-pointer">
            <h2 className="text-center">Order ID</h2>
            <span className="opacity-0 group-hover:opacity-100 transition-opacity duration-200 absolute bottom-full left-1/2 -translate-x-1/2 bg-gray-800 text-white text-xs py-1 px-2 rounded-md">
              Order ID
            </span>
        </div>
        <div className="w-[30%] flex justify-center items-center bg-[#0d3857] border-solid border-r-2 border-white group relative cursor-pointer">
            <h2 className="text-center">Order Summary</h2>
            <span className="opacity-0 group-hover:opacity-100 transition-opacity duration-200 absolute bottom-full left-1/2 -translate-x-1/2 bg-gray-800 text-white text-xs py-1 px-2 rounded-md">
                Order Summary
            </span>
        </div>
        <div className="w-[15%] flex justify-center items-center bg-[#0d3857] border-solid border-r-2 border-white group relative cursor-pointer">
            <h2 className="text-center">Created At</h2>
            <span className="opacity-0 group-hover:opacity-100 transition-opacity duration-200 absolute bottom-full left-1/2 -translate-x-1/2 bg-gray-800 text-white text-xs py-1 px-2 rounded-md">
                Created At
            </span>
        </div>
        <div className="w-[15%] flex justify-center items-center bg-[#0d3857] border-solid border-r-2 border-white group relative cursor-pointer">
            <h2 className="text-center">Status</h2>
            <span className="opacity-0 group-hover:opacity-100 transition-opacity duration-200 absolute bottom-full left-1/2 -translate-x-1/2 bg-gray-800 text-white text-xs py-1 px-2 rounded-md">
                  Status
            </span>
        </div>
        <div className="w-[25%] flex justify-center items-center bg-[#0d3857] border-solid border-r-2 border-white group relative cursor-pointer">
            <h2 className="text-center">Action</h2>
            <span className="opacity-0 group-hover:opacity-100 transition-opacity duration-200 absolute bottom-full left-1/2 -translate-x-1/2 bg-gray-800 text-white text-xs py-1 px-2 rounded-md">
                  Action
            </span>
        </div>
    </div>
);

export default DashboardTableTop;