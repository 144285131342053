import { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import IconDownArrow from '../../components/Icon/IconDownArrow';
import {
    usePostOrderDeleteMutation,
    usePostOrderByStatusMutation,
    usePostOrderSampleDeleteMutation,
    usePostOrderDetailEditMutation,
    usePostOrderStatusEditMutation
} from '../../store/services/api';
import DashboardTableTop from './DashboardTableTop';
import OrderDetailModal from './OrderDetailModal';
import DashboardTableData from './DashboardTableData';
import EditOrderDetailModal from './EditOrderDetailModal';

const OrderDashboard = () => {
    const [isOrderPendingCollapse, setIsOrderPendingCollapse] = useState(false);
    const [isOrderInProductionCollapse, setIsOrderInProductionCollapse] = useState(false);
    const [isOrderReadyShippingCollapse, setIsOrderReadyShippingCollapse] = useState(false);
    const [isOrderShippingCollapse, setIsOrderShippingCollapse] = useState(false);
    const [isOrderCompleteCollapse, setIsOrderCompleteCollapse] = useState(false);

    const [pendingOrderPaginationIndex, setPendingOrderPaginationIndex] = useState(1);
    const [inProductionOrderPaginationIndex, setInProductionOrderPaginationIndex] = useState(1);
    const [readyShippingOrderPaginationIndex, setReadyShippingOrderPaginationIndex] = useState(1);
    const [shippingOrderPaginationIndex, setShippingOrderPaginationIndex] = useState(1);
    const [completedOrderPaginationIndex, setCompletedOrderPaginationIndex] = useState(1);

    const [maxPendingOrder, setMaxPendingOrder] = useState(0);
    const [maxInProductionOrder, setMaxInProductionOrder] = useState(0);
    const [maxReadyShippingOrder, setMaxReadyShippingOrder] = useState(0);
    const [maxShippingOrder, setMaxShippingOrder] = useState(0);
    const [maxCompletedOrder, setMaxCompletedOrder] = useState(0);

    const [pendingItems, setPendingItems] = useState([]);
    const [inProductionItems, setInProductionItems] = useState([]);
    const [readyShipmentItems, setReadyShipmentItems] = useState([]);
    const [shippingItems, setShippingItems] = useState([]);
    const [completeItems, setCompleteItems] = useState([]);

    const [isPendingLoading, setIsPendingLoading] = useState(true);
    const [isInProductionLoading, setIsInProductionLoading] = useState(false);
    const [isShippingReadyLoading, setIsShippingReadyLoading] = useState(false);
    const [isShippingLoading, setIsShippingLoading] = useState(false);
    const [isCompleteLoading, setIsCompleteLoading] = useState(false);

    const [isOrderDetailModalOpen, setIsOrderDetailModalOpen] = useState(false);
    const [orderDetailData, setOrderDetailData] = useState([]);
    const [orderDetailIndex, setOrderDetailIndex] = useState(-1);
    const [existingOrderStatus, setExistingOrderStatus] = useState('');

    const [isEditOrderDetailModalOpen, setIsEditOrderDetailModalOpen] = useState(false);
    const [orderEditData, setOrderEditData] = useState([]);
    const [orderEditId, setOrderEditId] = useState('');

    const [orderTrigger] = usePostOrderByStatusMutation();
    const [orderDeleteTrigger] = usePostOrderDeleteMutation();
    const [sampleDeleteTrigger] = usePostOrderSampleDeleteMutation();
    const [editOrderDetailTrigger] = usePostOrderDetailEditMutation();
    const [editOrderStatusTrigger] = usePostOrderStatusEditMutation();

    const getOrderByStatus = async(status) => {
        let result;
        switch (status) {
            case 'Pending':
                setIsPendingLoading(true);
                result = await orderTrigger({
                    orderStatus: status,
                    querySetting: {index: pendingOrderPaginationIndex}
                });
                setPendingItems(result.data.orderData);
                setMaxPendingOrder(result.data.totalItem);
                setIsPendingLoading(false);
                break;
            case 'In Production':
                setIsInProductionLoading(true);
                result = await orderTrigger({
                    orderStatus: status,
                    querySetting: {index: inProductionOrderPaginationIndex}
                });
                setInProductionItems(result.data.orderData);
                setMaxInProductionOrder(result.data.totalItem);
                setIsInProductionLoading(false);
                break;
            case 'Ready For Shipment':
                setIsShippingReadyLoading(true);
                result = await orderTrigger({
                    orderStatus: status,
                    querySetting: {index: readyShippingOrderPaginationIndex}
                });
                setReadyShipmentItems(result.data.orderData);
                setMaxReadyShippingOrder(result.data.totalItem);
                setIsShippingReadyLoading(false);
                break;
            case 'Shipping':
                setIsShippingLoading(true);
                result = await orderTrigger({
                    orderStatus: status,
                    querySetting: {index: shippingOrderPaginationIndex}
                });
                setShippingItems(result.data.orderData);
                setMaxShippingOrder(result.data.totalItem);
                setIsShippingLoading(false);
                break;
            case 'Completed':
                setIsCompleteLoading(true);
                result = await orderTrigger({
                    orderStatus: status,
                    querySetting: {index: completedOrderPaginationIndex}
                });
                setCompleteItems(result.data.orderData);
                setMaxCompletedOrder(result.data.totalItem);
                setIsCompleteLoading(false);
                break;
            default:
                break;
        }
    }

    const getDataWithPagination = async (status, newPaginationIndex) => {
        switch (status) {
            case 'Pending':
                await setPendingOrderPaginationIndex(pendingOrderPaginationIndex + newPaginationIndex);
                break;
            case 'In Production':
                setInProductionOrderPaginationIndex(inProductionOrderPaginationIndex + newPaginationIndex);
                break;
            case 'Ready For Shipment':
                setReadyShippingOrderPaginationIndex(readyShippingOrderPaginationIndex + newPaginationIndex)
                break;
            case 'Shipping':
                setShippingOrderPaginationIndex(shippingOrderPaginationIndex + newPaginationIndex)
                break;
            case 'Completed':
                setCompletedOrderPaginationIndex(completedOrderPaginationIndex + newPaginationIndex)
                break;
            default:
                break;
        }
    }

    useEffect( () => {
        if(isOrderPendingCollapse) {
            getOrderByStatus('Pending');
        }
    }, [isOrderPendingCollapse, pendingOrderPaginationIndex]);

    useEffect( () => {
        if(isOrderInProductionCollapse){
            getOrderByStatus('In Production');
        }
    }, [isOrderInProductionCollapse, inProductionOrderPaginationIndex]);

    useEffect( () => {
        if(isOrderReadyShippingCollapse){
            getOrderByStatus('Ready For Shipment');
        }
    }, [isOrderReadyShippingCollapse, readyShippingOrderPaginationIndex]);

    useEffect( () => {
        if(isOrderShippingCollapse){
            getOrderByStatus('Shipping');
        }
    }, [isOrderShippingCollapse, shippingOrderPaginationIndex]);

    useEffect( () => {
        if (isOrderCompleteCollapse) {
            getOrderByStatus('Completed');
        }
    }, [isOrderCompleteCollapse, completedOrderPaginationIndex]);

    const handleOrderStatus = async (currentStatus, event, orderIndex, orderId) => {

        function orderStatusAssign(newData) {
            switch (event.target.value) {
                case 'Pending':
                    setPendingItems([{...newData, status: 'Pending'}, ...pendingItems]);
                    break;
                case 'In Production':
                    setInProductionItems([{...newData, status: 'In Production'}, ...inProductionItems]);
                    break;
                case 'Ready For Shipment':
                    setReadyShipmentItems([{...newData, status: 'Ready For Shipment'}, ...readyShipmentItems]);
                    break;
                case 'Shipping':
                    // TODO this should be automated with ups
                    break;
                case 'Completed':
                    // TODO this should be automated with ups
                    break;
                default:
                    break;
            }
        }

        /* const result = await editOrderStatusTrigger({id: orderId, credentials: {newStatus: event.target.value}});

        if (result.data) {
            toast.success('Status Changed Successful!')
        } else {
            toast.error('Status Change Failed!')
            return;
        } */

        switch (currentStatus) {
            case 'Pending':
                setPendingItems((prevData) => prevData.filter((item) => item.id !== pendingItems[orderIndex].id));
                orderStatusAssign(pendingItems[orderIndex]);
                break;
            case 'In Production':
                setInProductionItems((prevData) => prevData.filter((item) => item.id !== inProductionItems[orderIndex].id));
                orderStatusAssign(inProductionItems[orderIndex]);
                break;
            case 'Ready For Shipment':
                setReadyShipmentItems((prevData) => prevData.filter((item) => item.id !== readyShipmentItems[orderIndex].id));
                orderStatusAssign(readyShipmentItems[orderIndex]);
                break;
            case 'Shipping':
                // TODO this should be automated with ups
                break;
            case 'Completed':
                // TODO this should be automated with ups
                break;
            default:
                break;
        }
    }

    const orderDetailOpenHandler = (currentStatus, orderIndex) => {
        switch (currentStatus) {
            case 'Pending':
                setOrderDetailData(pendingItems[orderIndex].sampleData);
                setExistingOrderStatus('Pending');
                break;
            case 'In Production':
                setOrderDetailData(inProductionItems[orderIndex].sampleData);
                setExistingOrderStatus('In Production');
                break;
            case 'Ready For Shipment':
                setOrderDetailData(readyShipmentItems[orderIndex].sampleData);
                setExistingOrderStatus('Ready For Shipment');
                break;
            case 'Shipping':
                setOrderDetailData(shippingItems[orderIndex].sampleData);
                setExistingOrderStatus('Shipping');
                break;
            case 'Completed':
                setOrderDetailData(completeItems[orderIndex].sampleData);
                setExistingOrderStatus('Completed');
                break;
            default:
                break;
        }

        setOrderDetailIndex(orderIndex);
        setIsOrderDetailModalOpen(true);
    }

    const deleteSampleHandle = async (currentStatus, orderIndex, sampleIndex, sampleId) => {
        if (currentStatus === 'Pending') {
            console.log(pendingItems[orderIndex].id);
            console.log(sampleId);
            const result = await sampleDeleteTrigger([pendingItems[orderIndex].id, sampleId]);
            setPendingItems((prevData) =>
                prevData.map(order =>
                    order.id === pendingItems[orderIndex].id
                        ? { ...order, sampleData: order.sampleData.filter(sample => sample.product_id !== sampleId) }
                        : order
                )
            );
            result.data ? toast.success('Sample Deleted Successfully!') : toast.error('Sample Delete Failed!');
        }
    }

    const handleOrderDelete = async (currentStatus, orderIndex) => {
        // Only pending orders can be deleted
        if (currentStatus === 'Pending') {
            const result = await orderDeleteTrigger(pendingItems[orderIndex].id);
            setPendingItems((prevData) => prevData.filter((item) => item.id !== pendingItems[orderIndex].id));
            result.data ? toast.success('Order Deleted Successfully!') : toast.error('Order Delete Failed!');
        }
    }

    const editOrderModalOpenHandler = (currentStatus, index) => {
        // TODO need to assign data to display
        if(currentStatus === 'Pending') {
            setOrderEditData(pendingItems[index].formData);
            setOrderEditId(pendingItems[index].id)
            setIsEditOrderDetailModalOpen(true);
        }
    }

    const orderEditUpdateFunction = async (updateData, updateId) => {
        const result = await editOrderDetailTrigger({ id: updateId, credentials: updateData });
        if(result.data) {
            toast.success('Edit Successful!')
            setPendingItems((prevData) =>
                prevData.map(order =>
                    order.id === updateId
                        ? { ...order, formData: updateData }
                        : order
                )
            );
        } else {
            toast.error('Edit Failed!')
        }
    }

    useEffect( () => {
        // TODO get all order by status
    }, []);

    return (
        <section className="h-[calc(100vh_-_2rem)] w-full bg-transparent p-4 rounded">
            <div className="flex flex-col h-full w-full">

                {/* Order Pending Starts */}
                <button
                    type="button"
                    className="flex flex-row p-2 font-normal bg-[#137cc0] rounded"
                    onClick={() => setIsOrderPendingCollapse(!isOrderPendingCollapse)}
                >
                    <div className="flex flex-row justify-between w-full text-white">
                        <h2 className="font-bold">Orders Pending</h2>
                        <IconDownArrow />
                    </div>
                </button>

                <div  className={isOrderPendingCollapse ? 'h-auto' : 'h-0 invisible'}>
                    <DashboardTableTop />
                    {pendingItems.length === 0 && <div className="flex flex-row w-full items-center justify-center mt-8"><h2>There is no Pending Item</h2></div>}
                    {isPendingLoading &&
                        <div className="flex flex-col w-full text-center items-center justify-center p-4">
                            <div className="animate-spin ease-linear rounded-full border-t-4 border-blue-500 border-solid h-12 w-12" />
                            <p className="mt-2 text-blue-500">Please wait loading...</p>
                        </div>
                    }
                    {!isPendingLoading &&
                        <DashboardTableData
                            data={pendingItems}
                            orderDeleteHandleFunction={handleOrderDelete}
                            orderStatusHandleFunction={handleOrderStatus}
                            orderDetailHandleFunction={orderDetailOpenHandler}
                            orderEditHandleFunction={editOrderModalOpenHandler}
                            currentStatus="Pending"
                            currentPage={pendingOrderPaginationIndex}
                            dataSize={maxPendingOrder}
                            requestDataFunction={getDataWithPagination}
                        />
                    }
                </div>
                {/* Order Pending Ends */}

                {/* In production Starts */}
                <button
                    type="button"
                    className="flex flex-row p-2 font-normal bg-[#fc9f70] rounded mt-4"
                    onClick={() => setIsOrderInProductionCollapse(!isOrderInProductionCollapse)}
                >
                    <div className="flex flex-row justify-between w-full text-white">
                        <h2 className="font-bold">In Production</h2>
                        <IconDownArrow />
                    </div>
                </button>

                <div  className={isOrderInProductionCollapse ? 'h-auto' : 'h-0 invisible'}>
                    <DashboardTableTop />
                    {inProductionItems.length === 0 && <div className="flex flex-row w-full items-center justify-center mt-8"><h2>There is no In Production Item</h2></div>}
                    {isInProductionLoading &&
                        <div className="flex flex-col w-full text-center items-center justify-center p-4">
                            <div className="animate-spin ease-linear rounded-full border-t-4 border-blue-500 border-solid h-12 w-12" />
                            <p className="mt-2 text-blue-500">Please wait loading...</p>
                        </div>
                    }
                    {!isInProductionLoading &&
                        <DashboardTableData
                            data={inProductionItems}
                            orderDeleteHandleFunction={handleOrderDelete}
                            orderStatusHandleFunction={handleOrderStatus}
                            orderDetailHandleFunction={orderDetailOpenHandler}
                            orderEditHandleFunction={editOrderModalOpenHandler}
                            currentStatus="In Production"
                            currentPage={inProductionOrderPaginationIndex}
                            dataSize={maxInProductionOrder}
                            requestDataFunction={getDataWithPagination}
                        />
                    }
                </div>
                {/* In production Ends */}

                {/* Ready For Shipment Starts */}
                <button
                    type="button"
                    className="flex flex-row p-2 font-normal bg-[#c96e37] rounded mt-4"
                    onClick={() => setIsOrderReadyShippingCollapse(!isOrderReadyShippingCollapse)}
                >
                    <div className="flex flex-row justify-between w-full text-white">
                        <h2 className="font-bold">Ready For Shipment</h2>
                        <IconDownArrow />
                    </div>
                </button>
                <div  className={isOrderReadyShippingCollapse ? 'h-auto' : 'h-0 invisible'}>
                    <DashboardTableTop />
                    {readyShipmentItems.length === 0 && <div className="flex flex-row w-full items-center justify-center mt-8"><h2>There is no Ready to Shipment Item</h2></div>}
                    {isShippingReadyLoading &&
                        <div className="flex flex-col w-full text-center items-center justify-center p-4">
                            <div className="animate-spin ease-linear rounded-full border-t-4 border-blue-500 border-solid h-12 w-12" />
                            <p className="mt-2 text-blue-500">Please wait loading...</p>
                        </div>
                    }
                    {!isShippingReadyLoading &&
                        <DashboardTableData
                            data={readyShipmentItems}
                            orderDeleteHandleFunction={handleOrderDelete}
                            orderStatusHandleFunction={handleOrderStatus}
                            orderDetailHandleFunction={orderDetailOpenHandler}
                            orderEditHandleFunction={editOrderModalOpenHandler}
                            currentStatus="Ready For Shipment"
                            currentPage={readyShippingOrderPaginationIndex}
                            dataSize={maxReadyShippingOrder}
                            requestDataFunction={getDataWithPagination}
                        />
                    }
                </div>
                {/* Ready For Shipment Ends */}

                {/* Shipping Starts */}
                <button
                    type="button"
                    className="flex flex-row p-2 font-normal bg-[#8a77a6] rounded mt-4"
                    onClick={() => setIsOrderShippingCollapse(!isOrderShippingCollapse)}
                >
                    <div className="flex flex-row justify-between w-full text-white">
                        <h2 className="font-bold">Shipping</h2>
                        <IconDownArrow />
                    </div>
                </button>
                <div  className={isOrderShippingCollapse ? 'h-auto' : 'h-0 invisible'}>
                    <DashboardTableTop />
                    {shippingItems.length === 0 && <div className="flex flex-row w-full items-center justify-center mt-8"><h2>There is no Shipping Item</h2></div>}
                    {isShippingLoading &&
                        <div className="flex flex-col w-full text-center items-center justify-center p-4">
                            <div className="animate-spin ease-linear rounded-full border-t-4 border-blue-500 border-solid h-12 w-12" />
                            <p className="mt-2 text-blue-500">Please wait loading...</p>
                        </div>
                    }
                    {!isShippingLoading &&
                        <DashboardTableData
                            data={shippingItems}
                            orderDeleteHandleFunction={handleOrderDelete}
                            orderStatusHandleFunction={handleOrderStatus}
                            orderDetailHandleFunction={orderDetailOpenHandler}
                            orderEditHandleFunction={editOrderModalOpenHandler}
                            currentStatus="Shipping"
                            currentPage={shippingOrderPaginationIndex}
                            dataSize={maxShippingOrder}
                            requestDataFunction={getDataWithPagination}
                        />
                    }
                </div>
                {/* Shipping Ends */}

                {/* Completed Starts */}
                <button
                    type="button"
                    className="flex flex-row p-2 font-normal bg-[#81b471] rounded mt-4"
                    onClick={() => setIsOrderCompleteCollapse(!isOrderCompleteCollapse)}
                >
                    <div className="flex flex-row justify-between w-full text-white">
                        <h2 className="font-bold">Completed</h2>
                        <IconDownArrow />
                    </div>
                </button>
                <div  className={isOrderCompleteCollapse ? 'h-auto' : 'h-0 invisible'}>
                    <DashboardTableTop />
                    {completeItems.length === 0 && <div className="flex flex-row w-full items-center justify-center mt-8"><h2>There is no Completed Item</h2></div>}
                    {isCompleteLoading &&
                        <div className="flex flex-col w-full text-center items-center justify-center p-4">
                            <div className="animate-spin ease-linear rounded-full border-t-4 border-blue-500 border-solid h-12 w-12" />
                            <p className="mt-2 text-blue-500">Please wait loading...</p>
                        </div>
                    }
                    {!isCompleteLoading &&
                        <DashboardTableData
                            data={completeItems}
                            orderDeleteHandleFunction={handleOrderDelete}
                            orderStatusHandleFunction={handleOrderStatus}
                            orderDetailHandleFunction={orderDetailOpenHandler}
                            orderEditHandleFunction={editOrderModalOpenHandler}
                            currentStatus="Completed"
                            currentPage={completedOrderPaginationIndex}
                            dataSize={maxCompletedOrder}
                            requestDataFunction={getDataWithPagination}
                        />
                    }
                </div>
                {/* Completed Ends */}

            </div>

            {isOrderDetailModalOpen &&
                <OrderDetailModal
                    onClose={() => setIsOrderDetailModalOpen(false)}
                    open={isOrderDetailModalOpen}
                    data={orderDetailData}
                    orderIndex={orderDetailIndex}
                    existingStatus={existingOrderStatus}
                    deleteSampleHandler={deleteSampleHandle}
                />
            }

            {isEditOrderDetailModalOpen &&
                <EditOrderDetailModal
                    onClose={() => setIsEditOrderDetailModalOpen(false)}
                    open={isEditOrderDetailModalOpen}
                    data={orderEditData}
                    updateFunction={orderEditUpdateFunction}
                    orderId={orderEditId}
                />
            }
        </section>
    )
};

export default OrderDashboard;